import { Component, OnInit, OnDestroy, LOCALE_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';

import { GlobalSettings, ModuleName } from './settings/global-settings';
import { Components } from './settings/components'
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { LoggerMonitor } from './settings/logger-monitor'
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
             selector: 'app-root',
             templateUrl: './app.component.html',
             styleUrls: ['./app.component.less']
           })

export class AppComponent implements OnInit, OnDestroy {

  deviceInfo = null;
  loggerMonitor: LoggerMonitor;
  interval: any;

  constructor(
    private httpClient: HttpClient,
    @Inject(LOCALE_ID) protected localeId: string,
    private router: Router,
    private keycloakService: KeycloakService,
    private globalSettings: GlobalSettings,
    private logger: NGXLogger,
    private deviceService: DeviceDetectorService) {
      this.getDeviceInfo();
      // Создание и регистрация сборщика логов
      this.loggerMonitor = new LoggerMonitor(this.deviceInfo, httpClient, globalSettings);
      this.logger.registerMonitor(this.loggerMonitor);
  }

  time = new Date();
  timezone = '';
  timer;
  partnerLogo;
  notification;
  sidebarOpened = false;
  autorizationIsComplate = false;
  userDetails: KeycloakProfile;
  userRoles: string[] = [];
  // Компоненты системы
  components = new Components();
  // Доступыне модули для пользовательских ролей
  availableModuleForRole: any[] = [];
  moduleName = ModuleName;
  // Создание локального обьекта для хранения параметров токена,
  // так как набор отличается от базового, будут ошибки при компиляции,
  // необходимо определить собственную структуру
  localTokenParsed = new TokenParsed();
  tokenRefreshInterval: any;
  // Версия приложения из переменных окружения
  applicationVersion = environment.applicationVersion;
  // Время сборки приложения
  timeStamp = environment.timeStamp;
  production = environment.production;

  getDeviceInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  getLanguages() {
    return this.globalSettings.languages;
  }

  getCurrentLanguage() {
    return this.globalSettings.language;
  }

  getPartnerLogo() {
    this.partnerLogo = this.globalSettings.partnerLogo;
  }

  getTimezone() {
    this.timezone = this.globalSettings.timezone;
  }

  getUserLogin(): string {
    if (this.autorizationIsComplate) {
      return this.userDetails.username;
    } else {
      return '';
    }
  }

  getUserFirstName(): string {
    if (this.autorizationIsComplate) {
      return this.userDetails.firstName;
    } else {
      return '';
    }
  }

  getUserLastName(): string {
    if (this.autorizationIsComplate) {
      return this.userDetails.lastName;
    } else {
      return '';
    }
  }

  get isAdmin() {
    return this.globalSettings.getUserDetail().isAdmin;
  }

  get isSchedule() {
    return this.globalSettings.getUserDetail().isSchedule;
  }

  get isSlotCoordination() {
    return this.globalSettings.getUserDetail().isSlotCoordination;
  }

  get isWorkflow() {
    return this.globalSettings.getUserDetail().isWorkflow;
  }

  get isComModule() {
    return this.globalSettings.getUserDetail().isComModule;
  }

  get isMobileApron() {
    return this.globalSettings.getUserDetail().isMobileApron;
  }

  get isLogsReports() {
    return this.globalSettings.getUserDetail().isLogsReports;
  }

  get isMasterData() {
    return this.globalSettings.getUserDetail().isMasterData;
  }

  get isBoardingControl() {
    return this.globalSettings.getUserDetail().isBoardingControl;
  }

  get isTest() {
    return this.globalSettings.getUserDetail().isTest;
  }

  get isParking() {
    return this.globalSettings.getUserDetail().isParking;
  }

  get isUserTrace() {
    return this.globalSettings.getUserDetail().isUserTrace;
  }

  toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
  }

  async ngOnInit() {
    this.timer = setInterval(() => {
      this.time = new Date();
    }, 1000);

    // // Генератор фейковых сообщений
    // this.notification = setInterval(() => {
    //   // В приложении
    //   this.globalSettings.showNotification('Communication module:', 'New message!');
    //   // В браузере
    //   this.globalSettings.sendBrowserNotification('Communication module:', {body: 'New message!'});
    // }, 15000);


    if (await this.keycloakService.isLoggedIn()) {
      // Получение параметров токена после успешной авторизации
      this.userDetails = await this.keycloakService.loadUserProfile();
      this.globalSettings.keycloakSetUserProfile(this.userDetails);
      this.userRoles = this.keycloakService.getUserRoles();
      this.globalSettings.keycloakSetUserRoles(this.userRoles);
      this.parseComManFolders();
      this.autorizationIsComplate = true;
      await this.globalSettings.loadDefaultConfig();
      await this.globalSettings.getUserSettings(this.getUserLogin());
      // Обновление настроек системы логирования полученных с сервера настроек
      this.logger.updateConfig({
        level: this.globalSettings.loggerSettings.level,
        disableConsoleLogging: this.globalSettings.loggerSettings.disableConsoleLogging
      });
      // Если пользователь находится в группе, для которой ведется максимальный
      // сбор логов, то включается режим трассировки, иначе выставляется
      // режим сбора логов из глобальных настроек приложения
      if (this.globalSettings.getUserDetail().isUserTrace) {
        this.loggerMonitor.setServerLogLevel(NgxLoggerLevel.TRACE);
        // Активация настроек системы логирования с максимальной отладкой
        this.logger.updateConfig({
          level: NgxLoggerLevel.TRACE
        });
      } else {
        this.loggerMonitor.setServerLogLevel(this.globalSettings.loggerSettings.serverLogLevel);
      }
      this.loadCurrentLanguageInterface(this.globalSettings.language);
      this.getPartnerLogo();

      // Парсинг полного списка ролей пользователя, получение из них
      // списка модулей доступных для работы пользователя
      this.availableModuleForRole = this.components.getAvailableModulesForRoles(this.userRoles);
      // Получение текущего фаворитного модуля пользователя,
      // если такого нет, то назначение любого из списка доступныз
      // если есть, то его загрузка
      this.logger.debug(this.router.url);
      if (this.router.url === null ||
          this.router.url === '' ||
          this.router.url === '/') {
        if (this.globalSettings.loadableModule == 0) {
          if (this.getUserModules()[0].id == this.moduleName.ComMan) {
            this.router.navigate([this.getUserModules()[0].path,
                                  this.getComManFirstFolder()]);
          } else {
            this.router.navigate([this.getUserModules()[0].path]);
          }
        } else {
          if (this.globalSettings.loadableModule == this.moduleName.ComMan) {
            this.router.navigate([this.components.getModulePath(this.moduleName.ComMan),
                                this.getComManFirstFolder()]);
          } else {
            this.router.navigate([this.components.getModulePath(this.globalSettings.loadableModule)]);
          }
        }
      }
      this.getTimezone();

    }

    // Обновление токена Keycloak каждые 10 минту (600000)
    this.tokenRefreshInterval = setInterval(() => {
      this.keycloakService.updateToken(600).then((refreshed) => {
        if (refreshed) {
          this.logger.debug('Token was successfully refreshed');
        } else {
          this.logger.debug('Token is still valid');
        }
      });
    }, 600000);

    this.logger.log('Application version: ' + this.applicationVersion);
    this.logger.log('Last release timestamp is set to: ' + this.timeStamp);
  }

  // Получение списка модулей доступных пользователю для работы
  getUserModules() {
    return this.components.modules.filter(element => this.availableModuleForRole.indexOf(element.id) > -1 );
  }

  // Получение загружаемого модуля по умолчнию при входе в приложение
  getLoadableModule() {
    return this.globalSettings.loadableModule;
  }

  getModulePath(module: ModuleName) {
    return this.components.getModulePath(module);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  getComManFirstFolder(): number {
    if (this.globalSettings.getComManFolders().length > 0) {
      return +this.globalSettings.getComManFolders()[0].id;
    } else {
      return null;
    }
  }

  parseComManFolders() {
    const keycloakTokenParsed = this.keycloakService.getKeycloakInstance().tokenParsed;
    // Импорт ассоциативных значений
    Object.assign(this.localTokenParsed, keycloakTokenParsed);
    this.globalSettings.setComManFolders(this.localTokenParsed.parseFolders());
  }

  async logout() {
    await this.keycloakService.logout();
    this.autorizationIsComplate = false;
  }

  // Функция смены языка интерфейса
  async changeLanguage(language) {
    await this.globalSettings.setLanguage(language);
    this.loadCurrentLanguageInterface(language);
  }
  
  // Функция смены таймзоны
  async changeTimezone(timezone) {
    await this.globalSettings.setTimezone(timezone);
    this.timezone = timezone;
  }

  // Функция смены загружаемого модуля
  async changeLoadableModule(moduleId) {
    await this.globalSettings.setLoadableModule(moduleId);
  }

  // Функция загрузки интерфейса на необходимом языке
  loadCurrentLanguageInterface(language) {
    console.log('Change language, current: ' + this.getLocaleId() +
                ' new language ' + language);
    // Если язык перехода есть в списке поддерживаемых, то перезагрузка
    // сайта на нужном языке
    let findLang = false;
    for (const lang of this.globalSettings.languages) {
      if (lang.lang === this.getLocaleId()) {
        findLang = true;
        break;
      }
    }
    if (findLang) {
      if (this.getLocaleId() !== language) {
        const langURL = `/${language}${this.router.url}`;
        window.location.href = langURL;
      }
    }
  }

  getLocaleId(): string {
    return this.localeId;
  }
}

// Класс хранения данных из токена Keycloak
export class TokenParsed {
  folders: Array<string> = []; // Список папок модуля Сообщения

  parseFolders() {
    if (this.folders.length > 0) {
      let result = [];
      this.folders.forEach(folder => {
        let arr = folder.split('##');
        arr.forEach(item => {
          let data = item.split(';;;');
          result.push({id: data[0], name: data[1], rule: data[2]})
        });
      });
      return result;
    } else {
      return [];
    }
  }
}

export function initializer(keycloak: KeycloakService,
                            globalSettings: GlobalSettings): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise<void>(async (resolve, reject)  => {
      try {
        await globalSettings.loadJSONServerConfig();
        await globalSettings.loadKeycloakConfig();
        if (globalSettings.keycloakConfig) {
          console.log('Keycloak config: ' + globalSettings.keycloakConfig.issuer +
                       globalSettings.keycloakConfig.realm);
          await keycloak.init({
            config: {
              url: globalSettings.keycloakConfig.issuer,
              realm: globalSettings.keycloakConfig.realm,
              clientId: globalSettings.keycloakConfig.clientId
            },
            loadUserProfileAtStartUp: false,
            initOptions: {
              onLoad: 'login-required'
            },
            bearerExcludedUrls: []
          });
        } else {
          console.warn('Not load application config. Check JSON server URL in /asssets/cfg/appConfig.json');
        }
        resolve();
      } catch (error) {
        reject(error);
  }
    });
  };
}
