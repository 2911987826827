import { arrRu, arrLat } from '../core/transliteration';

export class Message {
  id: number;
  answered?: boolean;
  dtReceive: string;
  flight: string;
  dtFlight: Date;
  recipient: string;
  sender: string;
  subject: string;
  type: Array<string>;
  urgency: Array<string>;
  channelId: string;
  linked: boolean;
  flights: Array<MessageDetailFlights>;
  direction: number;
  readConfirmation: Array<MessageDetailRead>;
  noOneRead?: boolean;
  read?: boolean;
  toggle?: string;
  target?: string;
  mark?: string;
  errored?: boolean;
  hasIncoming?: boolean;
  dtMessage?: Date;
  dtInsert?: Date;
  message?: string;

  get urgencyEnglish(): string {
    if ((this.urgency) && (this.urgency.length === 2)) {
      return this.urgency[0];
    } else {
      return null;
    }
  }

  get urgencyNameLocal(): string {
    if ((this.urgency) && (this.urgency.length === 2)) {
      return this.urgency[1];
    } else {
      return null;
    }
  }

  get typeEnglish(): string {
    if ((this.type) && (this.type.length === 2)) {
      return this.type[0];
    } else {
      return null;
    }
  }

  get typeLocal(): string {
    if ((this.type) && (this.type.length === 2)) {
      return this.type[1];
    } else {
      return null;
    }
  }
}

export class MessageDetail {
  constructor() {
    this.id = null;
    this.dtMessage = new Date();
    this.dtInsert = new Date();
    this.sender = '';
    this.senders = {1: '', 2: '', 3: ''};
    this.recipentObjectList = {1: [], 2: [], 3: []};
    this.urgencyIds = {1: '', 2: '', 3: ''};
    this.recipient = '';
    this.uin = '';
    this.flightsId = [];
    this.showOthers = true;
    this.links = [];
    this.message = '';
    this.direction = 1;
  }

  id: number;
  dtMessage: any;
  dtInsert: Date;
  sender: string;
  senders: Object;
  recipient: string;
  recipentObjectList: Object;
  typeId: number;
  typeName?: string;
  subject?: string;
  airline?: string;
  flight?: string;
  flights: Array<MessageDetailFlights>;
  flightsId?: Array<number>;
  dtFlight?: Date;
  uin?: string;
  message?: string;
  original: string;
  channelId: number;
  channelName?: string;
  urgency: string;
  urgencyId: any;
  urgencyIds: Object;
  urgencyName?: Array<string>;
  lastupdate: Date;
  errored?: boolean;
  errorString?: string;
  folderId?: number;
  direction: number;
  incomingId: number;
  answered: boolean;
  showOthers: boolean;
  username?: string;
  messageId?: number;
  read: boolean;
  mark?: string;
  readConfirmations: Array<MessageDetailRead>;
  addressLocal?: string;
  links: Array<number>;

  get urgencyNameEnglish(): string {
    if ((this.urgencyName) && (this.urgencyName.length === 2)) {
      return this.urgencyName[0];
    } else {
      return null;
    }
  }

  get urgencyNameLocal(): string {
    if ((this.urgencyName) && (this.urgencyName.length === 2)) {
      return this.urgencyName[1];
    } else {
      return null;
    }
  }

  textLength(isAftn): number {
    let languageChangeSymbols = 0;
    if (isAftn && this.message) {
      let currentLanguageIsLat = true;
      // Разбиение сообщения на массив символов, сравнение текущего и предыдущего
      // символа, если язык отличается, то увеличивается счетчик смены языков
      const symbolArray = this.message.split('');
      symbolArray.forEach(symbol => {
        // TODO Зачем проверка на длинну элемента?
        if (symbol.length > 0) {
          if (currentLanguageIsLat) {
            if (arrRu.indexOf(symbol) !== -1) {
              languageChangeSymbols++;
              currentLanguageIsLat = false;
            }
          } else {
            if (arrLat.indexOf(symbol) !== -1) {
              languageChangeSymbols++;
              currentLanguageIsLat = true;
            }
          }
        }
      });

      // Первый знак смены языка на русский не учитывается
      for (const symbol of this.message) {
        // Если знак из русского алфавита, то счетчик уменьшается
        if (arrRu.indexOf(symbol) !== -1) {
          languageChangeSymbols--;
        }
        // Выполнение продолжается пока не будет встречен знак алфавита,
        // так как первыми могут быть цифры или спецсимволы
        if (arrRu.indexOf(symbol) !== -1 || arrLat.indexOf(symbol) !== -1) {
          break;
        }
      }
    }

    if (this.message) {
      return this.message.length + languageChangeSymbols;
    } else {
      return 0;
    }
  }
}

export class MessageTemplate {
  constructor() {
    this.id = undefined;
    this.name = '';
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
    this.sender = {1: '', 2: '', 3: ''};
    this.recipient = {1: '', 2: '', 3: ''};
    this.urgencyId = {1: '', 2: '', 3: ''};
  }

  id: number;
  name: string;
  sender: Object;
  recipient: Object;
  subject: string;
  typeId: number;
  typeName: Array<string>;
  channelId: number;
  channelName: string;
  urgencyId: Object;
  urgencyName: Array<string>;
  message: string;
  folderId: number;
  folderName: string;
  dtRange: Array<Date>;
  lastupdate: Date;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = new Date(value);
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = new Date(value);
  }

}

export class MessageDetailFlights {
  id: number;
  airlineIata: string;
  airlineIcao: string;
  airlineCode: string;
  airline: string;
  flight: string;
  route: Array<string>;
  times: Date;

  get flightCode(): string {
    if (this.airlineIata) {
      return this.airlineIata;
    } else if (this.airlineIcao) {
      return this.airlineIcao;
    } else if (this.airlineCode) {
      return this.airlineCode;
    } else {
      return '';
    }
  }
}

export class MessageDetailRead {
  folderId: number;
  folder: string;
  times: Date;
  time?: string;
}
