import { Component, OnInit } from '@angular/core';
import { Maintenance } from '../workflow-module-data/resource';
import { WorkflowModuleRestApiService } from '../workflow-module-data/workflow-module-rest-api.service';

@Component({
  selector: 'app-workflow-module',
  templateUrl: './workflow-module.component.html',
  styleUrls: ['./workflow-module.component.less']
})
export class WorkflowModuleComponent implements OnInit {

  maintenances: Maintenance[];
  maintenance: Maintenance;

  constructor(
    public restApi: WorkflowModuleRestApiService
  ) { }

  ngOnInit() {
    this.loadMaintenances();
    // this.loadProcesses();
  }

  loadMaintenances() {
    console.log('load resources');
    return this.restApi.getMaintenances().subscribe((data: Maintenance[]) => {
      this.maintenances = data;
      console.log(this.maintenances);
    });
  }

  loadMaintenance(id: number) {
    console.log('load processes');
    return this.restApi.getMaintenance(id).subscribe((data: Maintenance) => {
      this.maintenance = data;
    });
  }

  updateMaintenance(id: number) {
    this.restApi.updateMaintenance(id, this.maintenance).subscribe(data => {
      this.loadMaintenances();
    });
  }

  checkEmployment(item): boolean {
    if (item === '') {
      return false;
    } else {
      return true;
    }
  }
}
