import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GlobalSettings } from '../settings/global-settings';
import { Maintenance } from './resource';

@Injectable({
  providedIn: 'root'
})
export class WorkflowModuleRestApiService {

  constructor(private http: HttpClient, private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getMaintenances(): Observable<Maintenance[]> {
    return this.http.get<Maintenance[]>(this.globalSettings.apiWorkflowURL +
                                        '/maintenance')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getMaintenance(id): Observable<Maintenance> {
    return this.http.get<Maintenance>(this.globalSettings.apiWorkflowURL +
                                   '/maintenance/' +
                                   id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateMaintenance(id: number, data: Maintenance): Observable<Maintenance> {
    return this.http.put<Maintenance>(this.globalSettings.apiTechProcessesURL
                                          + '/maintenance/' + id,
                                          JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = 'Error Code: ${error.status}\nMessage: ${error.message}';
     }
     window.alert(errorMessage);
     return throwError(errorMessage);
  }
}
