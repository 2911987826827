<div class="row border-bottom">
  <div class="col-2 py-2">
    <!-- New message button -->
    <button type="button"class="btn btn-sm btn-blue w-100" data-toggle="modal" data-target=".bd-example-modal-xl"
      (click)="newMessage(mymodal)" [disabled]="!buttonAvailable">
      <i class="material-icons">create</i>
      <span i18n="cmn|Main buttons - new message@@cmn.main.button.newMessage">
        New message
      </span>
    </button>
    </div>
    <div class="col-10">
      <div class="row">
        <div class="col-5 py-2 px-0">
          <button type="button"
                  class="btn btn-xs mr-2 btn-ico btn-light-gray"
                  ngbTooltip="Reply"
                  (click)="replyMessage(messageId, mymodal)"
                  data-toggle="modal"
                  data-target=".bd-example-modal-xl"
                  [disabled]="!checkFolderRuleIsFull() || !messageIsSelected || selectedMessage.direction==1 || selectedFolder < 1"
                  i18n-ngbTooltip="cmn|Main buttons - reply@@cmn.main.button.reply">
            <i class="material-icons">reply</i>
          </button>
          <button type="button"
                  class="btn btn-xs btn-ico btn-light-gray mr-2"
                  ngbTooltip="Forward"
                  (click)="forwardMessage(messageId, mymodal)"
                  [disabled]="!checkFolderRuleIsFull() || !messageIsSelected || selectedFolder < 1"
                  i18n-ngbTooltip="cmn|Main buttons - forward@@cmn.main.button.forward">
            <i class="material-icons">forward</i>
            <!-- Forward -->
          </button>
          <button type="button"
                  class="btn btn-xs btn-ico btn-light-gray mr-2"
                  ngbTooltip="Mark as unread"
                  (click)="unreadMessage(messageId)"
                  [disabled]="!checkFolderRuleIsFull() || !messageIsSelected || selectedFolder < 1 || selectedMessage.direction == 1 || filterParams.depth == 'archive'"
                  i18n-ngbTooltip="cmn|Main buttons - mark unread@@cmn.main.button.markUnread">
            <i class="material-icons">markunread_mailbox</i>
          </button>
          <button type="button"
                  class="btn btn-xs btn-ico btn-light-gray mr-2"
                  ngbTooltip="Copy to folder"
                  (click)="selectAction('copy', messageId)"
                  [disabled]="!checkFolderRuleIsFull() || !messageIsSelected || selectedMessage.direction==1 || selectedFolder < 0"
                  i18n-ngbTooltip="cmn|Main buttons - mark unread@@cmn.main.button.copyToFolder">
            <i class="material-icons">how_to_vote</i>
          </button>
          <button type="button"
                  class="btn btn-xs mr-2 btn-ico btn-light-gray"
                  ngbTooltip="Save"
                  (click)="saveToFile(selectedMessage.id)"
                  [disabled]="!messageIsSelected || selectedFolder < 0"
                  i18n-ngbTooltip="cmn|Main buttons - save@@cmn.main.button.save">
            <i class="material-icons">save_alt</i>
          </button>
          <button type="button"
                  class="btn btn-xs mr-2 btn-ico btn-light-gray"
                  ngbTooltip="Print"
                  [useExistingCss]="true"
                  printSectionId="print-section"
                  ngxPrint
                  (click)="printMessage(messageId)"
                  [disabled]="!messageIsSelected || selectedFolder < 0"
                  i18n-ngbTooltip="cmn|Main buttons - print@@cmn.main.button.print">
            <i class="material-icons">print</i>
          </button>
          <div class="btn-group float-right">
            <div class="dropdown">
              <button class="btn btn-xs btn-ico btn-light-gray dropdown-toggle"
                      ngbTooltip="Filter by flag"
                      i18n-ngbTooltip="cmn|Main buttons - filte by flag@@cmn.main.button.filterByFlag"
                      type="button"
                      id="dropdownFlags"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      [disabled]="selectedFolder < 1">
                <i class="material-icons" [ngStyle]="{'color': filterParams.mark }">assistant_photo</i>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownFlags">
                <button *ngFor="let mark of marks"
                        class="dropdown-item"
                        (click)="filterMark(mark)">
                  <i class="material-icons" [ngStyle]="{'color': mark }">assistant_photo</i>
                  {{mark}}
                </button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item font-small"
                        (click)="filterMark(null)">
                  <i class="material-icons" style="color: #5c5c5c;">assistant_photo</i>
                  <span i18n="cmn|Main buttons - clear mark@@cmn.main.button.markClear">Clear filter</span>
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group float-right mr-2">
            <div class="dropdown">
              <button class="btn btn-xs btn-ico btn-light-gray dropdown-toggle"
                      type="button"
                      ngbTooltip="Change view"
                      i18n-ngbTooltip="cmn|Main buttons - changeView@@cmn.main.button.changeView"
                      id="dropdownMenuExportAirports"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons"
                   *ngIf="commVertical">view_column</i>
                <i class="material-icons"
                   *ngIf="!commVertical">view_stream</i>
              </button>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuExportAirports">
                <button class="dropdown-item"
                        type="button"
                        (click)="selectView('vertical')">
                  <i class="material-icons">view_column</i>
                  <span i18n="cmn|Main buttons - vertical view@@cmn.main.button.verticalView">Vertical view</span>
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="selectView('horizontal')">
                  <i class="material-icons">view_stream</i>
                  <span i18n="cmn|Main buttons - horizontal view@@cmn.main.button.horizontalView">Horizontal view</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-7 py-2 d-flex align-items-center justify-content-between">
          <div class="filter-block-wrap border mr-2" [ngClass]="{'disabled' : selectedFolder==-1}">
            <div class="input-group overflow-hidden" (click)="openFilter()">
              <div class="input-group-prepend">
                <span class="input-group-text border-0 rounded-0 py-1"
                      i18n="cmn|Main search - label find@@cmn.main.search.label.find">Find</span>
                <div class="filter-elements d-flex pl-2 text-nowrap" (click)="showFilter = !showFilter">
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.flight.length > 0">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('flight')">close</i>
                    <span i18n="cmn|Main search - tag flight@@cmn.main.search.tag.flight">Flight:</span>&nbsp;{{filterParams.flight}}
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.airlineValue.length > 0">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('airline')">close</i>
                    <span i18n="cmn|Main search - tag airline@@cmn.main.search.tag.airline">Airline:</span>&nbsp;<span [ngClass]="{'del': filterParams.airlineFlag == '!' }">{{getAirlinesName()}}</span>
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.typeValue.length > 0">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('type')">close</i>
                    <span i18n="cmn|Main search - tag type@@cmn.main.search.tag.type">Type:</span>&nbsp;<span [ngClass]="{'del': filterParams.typeFlag == '!' }">{{getTypesName()}}</span>
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.routeValue.length > 0">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('route')">close</i>
                    <span i18n="cmn|Main search - tag route@@cmn.main.search.tag.route">Route:</span>&nbsp;<span [ngClass]="{'del': filterParams.routeFlag == '!' }">{{getRoutesName()}}</span>
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.recipient.length > 0">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('recipient')">close</i>
                    <span i18n="cmn|Main search - tag recipient@@cmn.main.search.tag.recipient">Recipient:</span>&nbsp;<span [ngClass]="{'del': filterParams.recipientFlag == '!' }">{{filterParams.recipient ? filterParams.recipient.join(',') : ''}}</span>
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.sender.length > 0">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('sender')">close</i>
                    <span i18n="cmn|Main search - tag sender@@cmn.main.search.tag.sender">Sender:</span>&nbsp;<span [ngClass]="{'del': filterParams.senderFlag == '!' }">{{filterParams.sender ? filterParams.sender.join(',') : ''}}</span>
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.channelValue.length > 0">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('channel')">close</i>
                    <span i18n="cmn|Main search - tag channel@@cmn.main.search.tag.channel">Channel:</span>&nbsp;<span [ngClass]="{'del': filterParams.channelFlag == '!' }">{{getChannelsName()}}</span>
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.urgencyValue.length > 0">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('category')">close</i>
                    <span i18n="cmn|Main search - tag category@@cmn.main.search.tag.category">Category:</span>&nbsp;<span [ngClass]="{'del': filterParams.urgencyFlag == '!' }">{{getUrgenciesName()}}</span>
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.subject != ''">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('subject')">close</i>
                    <span i18n="cmn|Main search - tag subject@@cmn.main.search.tag.subject">Subject:</span>&nbsp;{{filterParams.subject}}
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.textValue.length > 0">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('text')">close</i>
                    <span i18n="cmn|Main search - tag text@@cmn.main.search.tag.text">Text:</span>&nbsp;<span [ngClass]="{'del': filterParams.textFlag == '!' }">{{filterParams.textValue}}</span>
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.userValue != ''">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('user')">close</i>
                    <span i18n="cmn|Main search - tag user@@cmn.main.search.tag.user">User:</span>&nbsp;<span [ngClass]="{'del': filterParams.userFlag == '!' }">{{filterParams.userValue}}</span>
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.aftnNumber.length > 0">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('aftnNumber')">close</i>
                    <span i18n="cmn|Main search - tag aftn number@@cmn.main.search.tag.aftnNumber">AFTN number:</span>&nbsp;{{filterParams.aftnNumber ? filterParams.aftnNumber.join(',') : ''}}
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.unread != false">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('unread')">close</i>
                    <span i18n="cmn|Main search - tag text@@cmn.main.search.tag.unread">Unread</span>
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.noFlight != false">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('noFlight')">close</i>
                    <span i18n="cmn|Main search - tag text@@cmn.main.search.tag.noFlight">No flight</span>
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.start != null">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('start')">close</i>
                    <span i18n="cmn|Main search - tag text@@cmn.main.search.tag.start">Date start</span>&nbsp;{{filterParams.start | date: 'dd.MM.yyyy HH:mm' : 'UTC+0'}}
                  </div>
                  <div class="filter-element mr-2 my-1" *ngIf="filterParams.finish != null">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('finish')">close</i>
                    <span i18n="cmn|Main search - tag text@@cmn.main.search.tag.finish">Date finish</span>&nbsp;{{filterParams.finish | date: 'dd.MM.yyyy HH:mm' : 'UTC+0'}}
                  </div>
                  <div class="filter-element mr-2 my-1"
                       *ngIf="filterParams.direction != null && filterParams.direction != -1">
                    <i class="material-icons mx-1" (click)="clearFilterParametr('direction')">close</i>
                    <span *ngIf="filterParams.direction == 0"
                      i18n="cmn|Main search - tag direction incomming@@cmn.main.search.tag.directionIncomming">
                      Incoming
                    </span>
                    <span *ngIf="filterParams.direction == 1"
                      i18n="cmn|Main search - tag direction outgoing@@cmn.main.search.tag.directionOutgoing">
                      Outgoing
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center text-dark-gray">
              <i class="material-icons mx-1" *ngIf="selectedFolder!=-1" (click)="showFilter = !showFilter">arrow_drop_down</i>
            </div>
            <div class="filter-block p-2 bg-white" *ngIf="showFilter">
              <div class="container">
                <div class="row">
                  <div class="col-3 px-1">
                    <label for="filter-flight"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - filter name@@cmn.main.search.filter.filterName">
                      Filters
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <select id="filter-name"
                            class="form-control form-control-sm mr-2 py-0"
                            name="filter-name"
                            [value]="selectedFilter.id"
                            (change)="selectFilter(+$event.target.value)">
                      <option [value]="-1">
                      </option>
                      <option *ngFor="let filter of userFilters" [value]="filter.id" [selected]="selectedFilter.id==filter.id">
                        {{filter.name}}
                      </option>

                    </select>
                  </div>
                  <div class="col-1 px-1">
                    <button class="btn btn-h-25 btn-ico btn-light-gray" (click)="deleteFilter(selectedFilter.id)">
                      <i class="material-icons">delete</i>
                    </button>
                  </div>
                </div>
                <hr />
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-aftn-number"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - aftn number@@cmn.main.search.filter.aftnNumber">
                      AFTN number
                    </label>
                  </div>
                  <div class="col-9 px-1" *ngIf="!filterLoadAnimation['aftnNumber']; else filterAFTNNumberLoad">
                    <ng-select class="custom-sm p-0"
                                [items]="filterParams.aftnNumber"
                                [addTag]="addTagFilterAFTNNumber"
                                [multiple]="true"
                                [(ngModel)]="filterParams.aftnNumber"
                                [isOpen]= "false"
                                (blur)="addTagFilterFromArray(selectEnteredData.aftnNumber, 'aftnNumber')"
                                (clear)="onClearFilterInput('aftnNumber')"
                                (remove)="onClearTagFilterInput($event, 'aftnNumber')"
                                (search)="onSearchFilterInput($event, 'aftnNumber')"
                                placeholder="Format DDHHMM XXXXXXXX or DDHHMM"
                                i18n-placeholder="cmn|Main search filter - aftn number field@@cmn.main.search.filter.field.aftnNumber">
                      <ng-template ng-tag-tmp let-search="searchTerm">
                        <b i18n="cmn|Main search filter - aftn number field add@@cmn.main.search.filter.field.aftnNumberAdd">Add</b>: {{search}}
                      </ng-template>
                    </ng-select>
                  </div>
                  <ng-template #filterAFTNNumberLoad>
                    <div class="col-9 px-1">
                      <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                        <div class="spinner-border spinner-border-sm float-left"
                             role="status"
                             aria-hidden="true"></div>
                        <div class="float-left pl-2"
                             i18n="cmn|Main search filter - aftn number pasing message@@cmn.main.search.filter.aftnNumberParsingMessage">
                          Parsing an AFTN numbers string...
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-flight"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - flight@@cmn.main.search.filter.flight">
                      Flight
                    </label>
                  </div>
                  <div class="col-9 px-1" *ngIf="!filterLoadAnimation['flight']; else filterFlightLoad">
                      <ng-select #filterFlightInputCatch
                                 class="custom-sm p-0"
                                 [items]="filterParams.flight"
                                 [addTag]="addTagFilterFlights"
                                 [multiple]="true"
                                 [isOpen]= "false"
                                 [(ngModel)]="filterParams.flight"
                                 (blur)="addTagFilterFromArray(selectEnteredData.flight, 'flight')"
                                 (clear)="onClearFilterInput('flight')"
                                 (remove)="onClearTagFilterInput($event, 'flight')"
                                 (search)="onSearchFilterInput($event, 'flight')"
                                 placeholder="Flight numbers separated by a space"
                                 i18n-placeholder="cmn|Main search filter - flight field@@cmn.main.search.filter.field.flight">
                        <ng-template ng-tag-tmp let-search="searchTerm">
                          <b i18n="cmn|Main search filter - flight field add@@cmn.main.search.filter.field.flightAdd">Add:</b>&nbsp;{{search}}
                        </ng-template>
                      </ng-select>
                  </div>
                  <ng-template #filterFlightLoad>
                    <div class="col-9 px-1">
                      <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                        <div class="spinner-border spinner-border-sm float-left"
                             role="status"
                             aria-hidden="true"></div>
                        <div class="float-left pl-2"
                             i18n="cmn|Main search filter - flight pasing message@@cmn.main.search.filter.flightParsingMessage">
                          Parsing an flight numbers string...
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-airline-eq"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - flight@@cmn.main.search.filter.airline">
                      Airline
                    </label>
                  </div>
                  <div class="col-3 px-1">
                    <select id="filter-airline-eq"
                            class="form-control form-control-sm mr-2 py-0"
                            name="filter-airline-eq"
                            [(ngModel)]="filterParams.airlineFlag">
                      <option value=""
                              [selected]="filterParams.airlineFlag==''"
                              i18n="cmn|Main search filter - airline equal@@cmn.main.search.filter.airlineEqual">
                        Equal
                      </option>
                      <option value="!"
                              [selected]="filterParams.airlineFlag=='!'"
                              i18n="cmn|Main search filter - airline not equal@@cmn.main.search.filter.airlineNotEqual">
                        Not equal
                      </option>
                    </select>
                  </div>
                  <div class="col-6 px-1">
                    <ng-select class="custom-sm p-0"
                               [items]="airlines"
                               [virtualScroll]="true"
                               bindLabel="iata"
                               bindValue="id"
                               [multiple]="true"
                               [hideSelected]="true"
                               [loading]="airlinesLoading"
                               (scroll)="onScroll($event)"
                               (scrollToEnd)="onScrollToEnd()"
                               [(ngModel)]="filterParams.airlineValue"
                               [searchFn]="customSelectSearchAirline"
                               placeholder="Enter airline code or name"
                               i18n-placeholder="cmn|Main search filter - airline field@@cmn.main.search.filter.field.airline">
                      <ng-template ng-option-tmp let-item="item">
                        IATA:&nbsp;<span class="font-weight-bold">{{item.iata}}</span>,&nbsp;ICAO:&nbsp;{{item.icao}}<br/>
                        <small>{{item.names}}</small>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-type"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - type@@cmn.main.search.filter.type">
                      Type
                    </label>
                  </div>
                  <div class="col-3 px-1">
                    <select id="filter-type-eq"
                            class="form-control form-control-sm mr-2 py-0"
                            name="filter-type-eq"
                            [(ngModel)]="filterParams.typeFlag">
                      <option value=""
                              [selected]="filterParams.typeFlag==''"
                              i18n="cmn|Main search filter - type equal@@cmn.main.search.filter.typeEqual">
                        Equal
                      </option>
                      <option value="!"
                              [selected]="filterParams.typeFlag=='!'"
                              i18n="cmn|Main search filter - type not equal@@cmn.main.search.filter.typeNotEqual">
                        Not equal
                      </option>
                    </select>
                  </div>
                  <div class="col-6 px-1">
                    <ng-select id="filter-type"
                               name="filter-type"
                               class="custom-sm p-0"
                               [items]="types"
                               [multiple]="true"
                               [hideSelected]="true"
                               bindValue="id"
                               [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                               [(ngModel)]="filterParams.typeValue">
                      <ng-template ng-option-tmp let-item="item">
                        {{globalSettings.language=='en' || item.nameLocal=='' ? item.nameEnglish : item.nameLocal}}
                      </ng-template>
                    </ng-select>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-route-eq"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - route@@cmn.main.search.filter.route">
                      Route
                    </label>
                  </div>
                  <div class="col-3 px-1">
                    <select id="filter-route-eq"
                            class="form-control form-control-sm mr-2 py-0"
                            name="filter-route-eq"
                            [(ngModel)]="filterParams.routeFlag">
                      <option value=""
                              [selected]="filterParams.routeFlag==''"
                              i18n="cmn|Main search filter - route equal@@cmn.main.search.filter.routeEqual">
                        Equal
                      </option>
                      <option value="!"
                              [selected]="filterParams.routeFlag=='!'"
                              i18n="cmn|Main search filter - route not equal@@cmn.main.search.filter.routeNotEqual">
                        Not equal
                      </option>
                    </select>
                  </div>
                  <div class="col-6 px-1">
                    <ng-select class="custom-sm p-0"
                               [items]="airport"
                               [virtualScroll]="true"
                               bindLabel="iata"
                               bindValue="id"
                               [multiple]="true"
                               [hideSelected]="true"
                               [loading]="airportLoading"
                               (scroll)="onScrollAirport($event)"
                               (scrollToEnd)="onScrollToEndAirport()"
                               [(ngModel)]="filterParams.routeValue"
                               [searchFn]="customSelectSearchAirport"
                               placeholder="Enter airport code or name"
                               i18n-placeholder="cmn|Main search filter - route field@@cmn.main.search.filter.field.route">
                      <ng-template ng-option-tmp let-item="item">
                        IATA:&nbsp;<span class="font-weight-bold">{{item.iata}}</span>,&nbsp;ICAO:&nbsp;{{item.icao}}<br/>
                        <small>{{item.names}}</small>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-recipient"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - recipient@@cmn.main.search.filter.recipient">
                      Recipient
                    </label>
                  </div>
                  <div class="col-3 px-1">
                    <select id="filter-recipient-eq"
                            class="form-control form-control-sm mr-2 py-0"
                            name="filter-recipient-eq"
                            [(ngModel)]="filterParams.recipientFlag">
                      <option value=""
                              [selected]="filterParams.recipientFlag==''"
                              i18n="cmn|Main search filter - recipient contain@@cmn.main.search.filter.recipientContain">
                        Contain
                      </option>
                      <option value="!"
                              [selected]="filterParams.recipientFlag=='!'"
                              i18n="cmn|Main search filter - recipient not contain@@cmn.main.search.filter.recipientNotContain">
                        Not contain
                      </option>
                    </select>
                  </div>

                  <div class="col-6 px-1" *ngIf="!filterLoadAnimation['recipient']; else filterRecipientLoad">
                    <ng-select class="custom-sm p-0"
                               [items]="filterParams.recipient"
                               [addTag]="addTagFilterRecipient"
                               [multiple]="true"
                               [isOpen]="false"
                               [(ngModel)]="filterParams.recipient"
                               (blur)="addTagFilterFromArray(selectEnteredData.recipient, 'recipient')"
                               (clear)="onClearFilterInput('recipient')"
                               (remove)="onClearTagFilterInput($event, 'recipient')"
                               (search)="onSearchFilterInput($event, 'recipient')"
                               placeholder="Addresses separated by a space"
                               i18n-placeholder="cmn|Main search filter - recipient field@@cmn.main.search.filter.field.recipient">
                      <ng-template ng-tag-tmp let-search="searchTerm">
                        <b i18n="cmn|Main search filter - recipient field add@@cmn.main.search.filter.field.recipientAdd">Add:</b>&nbsp;{{search}}
                      </ng-template>
                    </ng-select>
                  </div>
                  <ng-template #filterRecipientLoad>
                    <div class="col-6 px-1">
                      <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                        <div class="spinner-border spinner-border-sm float-left"
                            role="status"
                            aria-hidden="true"></div>
                        <div class="float-left pl-2"
                            i18n="cmn|Main search filter - recipient pasing message@@cmn.main.search.filter.recipientParsingMessage">
                          Parsing an addresses string...
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-sender"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - sender@@cmn.main.search.filter.sender">
                      Sender
                    </label>
                  </div>

                  <div class="col-3 px-1">
                    <select id="filter-sender-eq"
                            class="form-control form-control-sm mr-2 py-0"
                            name="filter-sender-eq"
                            [(ngModel)]="filterParams.senderFlag">
                      <option value=""
                              [selected]="filterParams.senderFlag==''"
                              i18n="cmn|Main search filter - sender contain@@cmn.main.search.filter.senderContain">
                        Contain
                      </option>
                      <option value="!"
                              [selected]="filterParams.senderFlag=='!'"
                              i18n="cmn|Main search filter - sender not contain@@cmn.main.search.filter.senderNotContain">
                        Not contain
                      </option>
                    </select>
                  </div>
                  <div class="col-6 px-1" *ngIf="!filterLoadAnimation['sender']; else filterSenderLoad">
                    <ng-select class="custom-sm p-0"
                               [items]="filterParams.sender"
                               [addTag]="addTagFilterSender"
                               [multiple]="true"
                               [isOpen]="false"
                               [(ngModel)]="filterParams.sender"
                               (blur)="addTagFilterFromArray(selectEnteredData.sender, 'sender')"
                               (clear)="onClearFilterInput('sender')"
                               (remove)="onClearTagFilterInput($event, 'sender')"
                               (search)="onSearchFilterInput($event, 'sender')"
                               placeholder="Addresses separated by a space"
                               i18n-placeholder="cmn|Main search filter - sender field@@cmn.main.search.filter.field.sender">
                      <ng-template ng-tag-tmp let-search="searchTerm">
                        <b i18n="cmn|Main search filter - sender field add@@cmn.main.search.filter.field.senderAdd">Add:</b>&nbsp;{{search}}
                      </ng-template>
                    </ng-select>
                  </div>
                  <ng-template #filterSenderLoad>
                    <div class="col-6 px-1">
                      <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                        <div class="spinner-border spinner-border-sm float-left"
                            role="status"
                            aria-hidden="true"></div>
                        <div class="float-left pl-2"
                            i18n="cmn|Main search filter - sender pasing message@@cmn.main.search.filter.senderParsingMessage">
                            Parsing an addresses string...
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-channel-flag"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - channel@@cmn.main.search.filter.channel">
                      Channel
                    </label>
                  </div>
                  <div class="col-3 px-1">
                    <select id="filter-channel-flag"
                            class="form-control form-control-sm mr-2 py-0"
                            name="filter-channel-flag"
                            [(ngModel)]="filterParams.channelFlag">
                      <option value=""
                              [selected]="filterParams.channelFlag==''"
                              i18n="cmn|Main search filter - channel equal@@cmn.main.search.filter.channelEqual">
                        Equal
                      </option>
                      <option value="!"
                              [selected]="filterParams.channelFlag=='!'"
                              i18n="cmn|Main search filter - channel not equal@@cmn.main.search.filter.channelNotEqual">
                        Not equal
                      </option>
                    </select>
                  </div>
                  <div class="col-6 px-1">
                    <ng-select class="form-control custom-sm p-0"
                               id="filter-channel-value"
                               [items]="channels"
                               bindLabel="name"
                               bindValue="id"
                               [multiple]="true"
                               [hideSelected]="true"
                               name="filter-channel-value"
                               [(ngModel)]="filterParams.channelValue">
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-category"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - category@@cmn.main.search.filter.category">
                      Category
                    </label>
                  </div>
                  <div class="col-3 px-1">
                    <select id="filter-urgency-flag"
                            class="form-control form-control-sm mr-2 py-0"
                            name="filter-urgency-flag"
                            [(ngModel)]="filterParams.urgencyFlag">
                      <option value=""
                              [selected]="filterParams.urgencyFlag==''"
                              i18n="cmn|Main search filter - urgency equal@@cmn.main.search.filter.urgencyEqual">
                        Equal
                      </option>
                      <option value="!"
                              [selected]="filterParams.urgencyFlag=='!'"
                              i18n="cmn|Main search filter - urgency not equal@@cmn.main.search.filter.urgencyNotEqual">
                        Not equal
                      </option>
                    </select>
                  </div>
                  <div class="col-6 px-1">
                    <ng-select id="filter-urgency-value"
                               name="filter-urgency-value"
                               class="form-control custom-sm p-0"
                               [items]="urgencies"
                               bindValue="id"
                               [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                               [multiple]="true"
                               [hideSelected]="true"
                               [(ngModel)]="filterParams.urgencyValue">
                      <ng-template ng-option-tmp let-item="item">
                        {{globalSettings.language=='en' || item.nameLocal=='' ? item.nameEnglish : item.nameLocal}}
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-subject"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - subject@@cmn.main.search.filter.subject">
                      Subject
                    </label>
                  </div>
                  <div class="col-9 px-1">
                    <input id="filter-subject"
                           class="form-control form-control-sm"
                           name="filter-subject"
                           [(ngModel)]="filterParams.subject" />
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-textFlag"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - text@@cmn.main.search.filter.text">
                      Text
                    </label>
                  </div>
                  <div class="col-3 px-1">
                    <select id="filter-textFlag"
                            class="form-control form-control-sm py-0"
                            name="filter-textFlag"
                            [(ngModel)]="filterParams.textFlag">
                      <option value=""
                              [selected]="filterParams.textFlag==''"
                              i18n="cmn|Main search filter - text contain@@cmn.main.search.filter.textContain">
                        Contain
                      </option>
                      <option value="!"
                              [selected]="filterParams.textFlag=='!'"
                              i18n="cmn|Main search filter - text not contain@@cmn.main.search.filter.textNotContain">
                        Not contain
                      </option>
                    </select>
                  </div>
                  <div class="col-6 px-1" *ngIf="!filterLoadAnimation['textValue']; else textValueFlightLoad">
                    <ng-select #textValueFlightInputCatch
                               class="custom-sm p-0"
                               [items]="filterParams.textValue"
                               [addTag]="addTagFiltertTextValue"
                               [multiple]="true"
                               [isOpen]= "false"
                               [(ngModel)]="filterParams.textValue"
                               (blur)="addTagFilterFromArray(selectEnteredData.textValue, 'textValue')"
                               (clear)="onClearFilterInput('textValue')"
                               (remove)="onClearTagFilterInput($event, 'textValue')"
                               (search)="onSearchFilterInput($event, 'textValue')"
                               placeholder="Text"
                               i18n-placeholder="cmn|Main search filter - textValue field@@cmn.main.search.filter.field.textValue">
                      <ng-template ng-tag-tmp let-search="searchTerm">
                        <b i18n="cmn|Main search filter - textValue field add@@cmn.main.search.filter.field.ftextValueAdd">Add:</b>&nbsp;{{search}}
                      </ng-template>
                    </ng-select>
                  </div>
                  <ng-template #textValueFlightLoad>
                    <div class="col-9 px-1">
                      <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                        <div class="spinner-border spinner-border-sm float-left"
                             role="status"
                             aria-hidden="true"></div>
                        <div class="float-left pl-2"
                             i18n="cmn|Main search filter - flight pasing message@@cmn.main.search.filter.flightParsingMessage">
                          Parsing an flight numbers string...
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <!--<div class="col-6 px-1">
                    <input id="filter-textValue"
                           class="form-control form-control-sm"
                           name="filter-textValue"
                           [(ngModel)]="filterParams.textValue" />
                  </div>-->
                </div>

                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-textFlag"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - user@@cmn.main.search.filter.user">
                      User
                    </label>
                  </div>
                  <div class="col-3 px-1">
                    <select id="filter-userFlag"
                            class="form-control form-control-sm py-0"
                            name="filter-userFlag"
                            [(ngModel)]="filterParams.userFlag">
                      <option value=""
                              [selected]="filterParams.userFlag==''"
                              i18n="cmn|Main search filter - user contain@@cmn.main.search.filter.userContain">
                        Contain
                      </option>
                      <option value="!"
                              [selected]="filterParams.userFlag=='!'"
                              i18n="cmn|Main search filter - user not contain@@cmn.main.search.filter.userNotContain">
                        Not contain
                      </option>
                    </select>
                  </div>
                  <div class="col-6 px-1">
                    <input id="filter-userValue"
                           class="form-control form-control-sm"
                           name="filter-userValue"
                           [(ngModel)]="filterParams.userValue" />
                  </div>
                </div>
                <hr />
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-date-from"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - date from@@cmn.main.search.filter.dateFrom">
                      Date from
                    </label>
                  </div>
                  <div class="col-3 px-1">
                    <input type="time"
                           class="form-control mr-1"
                           [ngModel]="filterParams.start | date: 'HH:mm' : 'UTC+0'"
                           (blur)="filterParams.start = parseDateTimeUtc(start_date.value, $event.target.value)"
                           name="filterParamsStartTime"
                           #start_time>
                  </div>
                  <div class="col-6 px-1">
                    <input type="date"
                           class="form-control"
                           [ngModel]="filterParams.start | date: 'yyyy-MM-dd' : 'UTC+0'"
                           (blur)="filterParams.start = parseDateTimeUtc($event.target.value, start_time.value || '00:00')"
                           name="filterParamsStartDate"
                           #start_date>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-date-from"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - date from@@cmn.main.search.filter.dateTo">
                      Date to
                    </label>
                  </div>
                  <div class="col-3 px-1">
                    <input type="time"
                           class="form-control mr-1"
                           [ngModel]="filterParams.finish | date: 'HH:mm' : 'UTC+0'"
                           (blur)="filterParams.finish = parseDateTimeUtc(finish_date.value, $event.target.value)"
                           name="filterParamsStartTime"
                           #finish_time>
                  </div>
                  <div class="col-6 px-1">
                    <input type="date"
                           class="form-control"
                           [ngModel]="filterParams.finish | date: 'yyyy-MM-dd' : 'UTC+0'"
                           (blur)="filterParams.finish = parseDateTimeUtc($event.target.value, finish_time.value || '23:59')"
                           name="filterParamsStartDate"
                           #finish_date>
                  </div>
                </div>
                <hr />
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-find"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - find@@cmn.main.search.filter.find">
                      Find
                    </label>
                  </div>
                  <div class="col-9 px-1">
                    <ng-select class="custom-sm p-0"
                               bindValue="id"
                               [items]="directionList"
                               [clearable]="false"
                               [(ngModel)]="filterParams.direction">
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-no-flight"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - no flight@@cmn.main.search.filter.noFlight">
                      No flight
                    </label>
                  </div>
                  <div class="col-9 px-1">
                    <input type="checkbox"
                           name="filter-no-flight"
                           id="filter-no-flight"
                           [(ngModel)]="filterParams.noFlight">
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-3 px-1">
                    <label for="filter-no-read"
                           class="mr-2 mb-0"
                           i18n="cmn|Main search filter - no read@@cmn.main.search.filter.noRead">
                      Unread
                    </label>
                  </div>
                  <div class="col-9 px-1">
                    <input type="checkbox"
                           name="filter-no-read"
                           id="filter-no-read"
                           [(ngModel)]="filterParams.unread">
                  </div>
                </div>
              </div>

              <button class="btn btn-gray-blue-dark btn-sm float-right ml-2"
                      (click)="showFilter = !showFilter"
                      i18n="cmn|Main search filter - button close@@cmn.main.search.filter.buttons.close">
                Close
              </button>
              <button class="btn btn-blue btn-sm float-right ml-2"
                      (click)="filterOn()"
                      i18n="cmn|Main search filter - button find@@cmn.main.search.filter.buttons.find">
                Find
              </button>
              <button class="btn btn-blue btn-sm float-right"
                      (click)="clearFilter(false)"
                      i18n="cmn|Main search filter - button clear all@@cmn.main.search.filter.buttons.clearAll">
                Clear all
              </button>
              <button class="btn btn-yellow btn-sm float-left"
                      *ngIf="!selectedFilter.id"
                      (click)="createFilter('saveFilter')"
                      i18n="cmn|Main search filter - button save filter@@cmn.main.search.filter.buttons.saveFilter">
                Save filter
              </button>
              <button class="btn btn-yellow btn-sm float-left"
                      *ngIf="selectedFilter.id"
                      (click)="createFilter('updateFilter')"
                      i18n="cmn|Main search filter - button save filter@@cmn.main.search.filter.buttons.saveFilter">
                Save filter
              </button>
            </div>
          </div>
          <button type="button"
                  class="btn btn-xs btn-ico btn-light-gray mr-2"
                  ngbTooltip="Filter on/off"
                  i18n-ngbTooltip="cmn|Main buttons - filter on/off@@cmn.main.button.filterOnOff"
                  [ngClass]="{'btn-active' : filterApply}"
                  (click)="filterApp()"
                  [disabled]="selectedFolder < 0">
            <i class="material-icons">filter_alt</i>
          </button>
          <button type="button"
                  class="btn btn-xs btn-ico btn-light-gray mr-2"
                  ngbTooltip="View archive"
                  i18n-ngbTooltip="cmn|Main buttons - view archive@@cmn.main.button.viewArchive"
                  [ngClass]="{'btn-active' : filterParams.depth=='archive'}"
                  (click)="filterArchive()"
                  [disabled]="selectedFolder < 0">
            <i class="material-icons">library_books</i>
          </button>
        </div>
    </div>
  </div>
</div>
<a href=""></a>
<div class="row comM-content" [ngClass]="{'folders-block-hide': !showFolderThree}">
  <div class="bg-light-gray border-right folders-block h-100 pb-5" [ngClass]="{'col-xl-2 col-sm-3 col-12 p-0': showFolderThree}">
    <div class="pt-2 h-100" *ngIf="showFolderThree; else miniFolders" style="overflow: auto;">

        <!-- Дерево папок -->
        <div data-toggle="collapse"
             data-target="#messagesMenu"
             class="d-flex align-items-center pointer pr-2 header-group">
          <i class="material-icons arrow-menu">keyboard_arrow_down</i>
          <i class="material-icons mr-2 text-gray-blue-dark">email</i>
          <span i18n="cmn|Main folder tree - group message@@cmn.main.folderTree.group.message">Messages</span>
          <span *ngIf="countAllMessages > 0"
            class="badge badge-dark badge-pill ml-auto">{{countAllMessages > 99 ? '99+' : countAllMessages}}</span>
        </div>
        <div class="collapse multi-collapse show" id="messagesMenu">
          <div *ngFor="let folder of folders" class="mp-0 left-menu">
            <div *ngIf="folder.id != -1"
                 [ngClass]="{'bg-gray': folder.id == selectedFolder}"
                 class="pointer pl-2 ty-1 mt-1 mb-1">
              <span *ngIf="folder['children']"
                    class="open-btn"
                    [ngClass]="{'collapsed': !checkOpen(folder.id)}"
                    data-toggle="collapse"
                    [attr.data-target]="'#subfolder' + folder.id">
                <i class="material-icons right" *ngIf="!checkOpen(folder.id) && !folder.opened">keyboard_arrow_right</i>
                <i class="material-icons down" *ngIf="checkOpen(folder.id) || folder.opened">keyboard_arrow_down</i>
              </span>
              <div class="pt-2 pr-2 pb-2 pl-5 m-0" (click)="changeActiveFolder(folder.id, folder.id)">
                <p class="m-0 d-flex align-items-center">
                  <i class="material-icons mr-2 text-gray-blue-dark">inbox</i>
                  {{folder.name}}
                  <span *ngIf="folder.unread > 0" class="badge badge-dark badge-pill ml-auto">
                    {{folder.unread > 99 ? '99+' : folder.unread}}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Черновики -->
        <div *ngIf="folderDraft!=null"
              class="d-flex align-items-center pointer pt-2 pr-2 pb-2 pl-4 mt-2"
              [ngClass]="{'bg-gray': selectedFolder == -1 && !selectedFilter.id}"
              (click)="changeActiveFolder(-1, -1)">
          <i class="material-icons mr-2 text-gray-blue-dark">description</i>
          <span i18n="cmn|Main folder tree - group draft@@cmn.main.folderTree.group.draft">Draft</span>
        </div>
        
        <!-- Фильтры -->
        <div class="mt-2 left-menu">
          <div data-toggle="collapse" data-target="#filterMenu"
               class="d-flex align-items-center pointer pr-2 header-group collapsed"
               [ngClass]="{'pl-4': userFilters.length == 0}">
            <i class="material-icons arrow-menu" *ngIf="userFilters.length!=0">keyboard_arrow_down</i>
            <i class="material-icons mr-2 text-gray-blue-dark">bookmarks</i>
            <span i18n="cmn|Main folder tree - group filter@@cmn.main.folderTree.group.filter">Filters</span>
            <span *ngIf="countAllFilters > 0"
              class="badge badge-dark badge-pill ml-auto">{{countAllFilters > 99 ? '99+' : countAllFilters}}</span>
          </div>
          <div class="collapse multi-collapse" id="filterMenu">
            <div *ngFor="let filter of userFilters; let i = index" class="left-menu">
              <ng-container *ngIf="i==0 || filter.folderId !== userFilters[i-1].folderId">
                <div data-toggle="collapse" data-target="#filter" class="d-flex align-items-center pl-5 ty-1 mt-3 mb-1">
                  <i class="material-icons mr-2 text-gray-blue-dark">insert_drive_file</i>
                  <span>{{filter.folderName}}</span>
                </div>
              </ng-container>
              <div class="pointer pl-5 py-1 pr-2 d-flex align-items-center"
                  [ngClass]="{'bg-gray': filter.id == selectedFilter.id}"
                  (click)="loadFilter(filter.id)">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{filter.name}} 
                <span *ngIf="filter.count > 0"
                  class="badge badge-dark badge-pill ml-auto">{{filter.count > 99 ? '99+' : filter.count}}</span>
              </div>
            </div>
          </div>
        </div>
    </div>

    <ng-template #miniFolders>
      <button class="mini-folder" [ngClass]="{'rotate-180': showFolderThree}"  (click)="updateShowFolder(!showFolderThree)">
        <i class="material-icons">email</i>
        <span class="mini-folder-count" *ngIf="countAllMessages">{{countAllMessages > 99 ? '99+' : countAllMessages}}</span>
      </button>
      <button class="mini-folder" [ngClass]="{'rotate-180': showFolderThree}"  (click)="updateShowFolder(!showFolderThree)">
        <i class="material-icons">bookmarks</i>
        <span class="mini-folder-count" *ngIf="countAllFilters">{{countAllFilters > 99 ? '99+' : countAllFilters}}</span>
      </button>
    </ng-template>
    
    <button class="folders-btn" [ngClass]="{'rotate-180': showFolderThree}"  (click)="updateShowFolder(!showFolderThree)">
      <i class="material-icons">arrow_right</i>
    </button>

  </div>

  <div class="cannotView" *ngIf="cannotView; else: massagesList"
    class="col-xl-10 p-4 col-sm-9 col-12 d-flex align-items-center justify-content-center h-100 bg-white">
    <span class="align-middle">
      <i class="material-icons md-48">visibility_off</i>
    </span>
    <span class="align-middle">
      <p class="h2 text-secondary" i18n="cmn|Main preview - cannot view message@@cmn.main.preview.notViewMessage">
        You cannot view messages in this folder
      </p>
    </span>
  </div>
  <ng-template #massagesList>
    <div class="col-xl-10 p-0 col-sm-9 col-12 h-100 bg-white massages-list"
      [ngClass]="{'col-xl-10 col-sm-9 col-12': showFolderThree}">
      <div class="d-flex flex-column h-100">
      <div class="font-small">
        <!-- Панель информации для текущей папки -->
        <div class="messages-list-panel bg-light-gray border-bottom py-2 px-3 d-flex justify-content-between">
          <div class="mr-3 d-flex align-items-center">
            <h6 class="pr-2 m-0" *ngIf="!checkFolderRuleIsFull()">
              <span class="badge badge-warning pb-1"
                    i18n="cmn|Main panel - read only@@cmn.main.panel.readOnly">
                READ-ONLY
              </span>
            </h6>
            <p class="m-0">
              <span i18n="cmn|Main panel - folder name@@cmn.main.panel.folderName">Folder:</span>&nbsp;<span
                    class="font-weight-bold">{{selectedFolderName}}</span>
            </p>
            &nbsp;&nbsp;
            <strong class="float-left">
              <span class="font-weight-normal text-gray-light">
                {{filterApply || this.filterParams.mark != null
                ? systemMessagesTexts['allOnFilter']
                : systemMessagesTexts['allOnFolder']}}
              </span>&nbsp;{{currentFolderInfo.folderAll}}
            </strong>&nbsp;
            <strong class="float-left" *ngIf="filterApply || this.filterParams.mark != null">
              <span class="font-weight-normal text-gray-light"
                i18n="cmn|Main panel - new on filter@@cmn.main.panel.newOnFilter">
                New on filter:
              </span>
              {{currentFolderInfo.folderUnreadFiltered}}
            </strong>
          </div>
          <div class="d-flex align-items-center">
            <div class="d-flex flex-nowrap mr-2 align-items-center per-page">
              <div class="text-nowrap line-height-1" i18n="cmn|Main panel - messages per page@@cmn.main.panel.messagesPerPage">
                  Messages per page
              </div>
              <select id="messagesPerPage"
                      class="form-control form-control-sm ml-2"
                      name="messagesPerPage"
                      [ngModel]="messagesInPage"
                      (change)="changePerPage(+$event.target.value)">
                  <option *ngFor="let count of countsPerPage" [value]="count">
                    {{count}}
                  </option>
              </select>
            </div>
            <pagination-controls (pageChange)="loadMessages($event)"
                                 maxSize="7"
                                 class="d-flex"
                                 directionLinks="true"
                                 autoHide="true"
                                 previousLabel=""
                                 nextLabel=""
                                 i18n-previousLabel="cmn|Horizontal table - pagination previos@@cmn.main.horizontal.table.pagination.previousLabel"
                                 i18n-nextLabel="cmn|Horizontal table -  pagination next@@cmn.main.horizontal.table.pagination.nextLabel">
            </pagination-controls>
          </div>
        </div>
      </div>
      <div class="row messages-list-wrap font-small m-0 flex-grow-1">
        <div class="border-right p-0  messages-list-wrap font-small"
          [ngClass]="{ 'col-12': !commVertical,
                        'col-5': commVertical,
                        'h-50': !commVertical,
                        'h-100': commVertical }">
          <!-- Список сообщений -->
          <div class="loading" *ngIf="loadingMessagesList">
            <div class="cssload-spin-box"></div>
          </div>
          <div class="messages-list">
            <!-- Вертикальное отображение списка сообщений-->
            <div *ngIf="commVertical; else: messageHorigontal" class="messageVertical">
              <div *ngFor="let mess of messages
                          | paginate: {
                            itemsPerPage: messagesInPage,
                            currentPage: messagesPageActive,
                            totalItems: messagesCount
                          }" #messageElement class="border-bottom pt-2 pr-2 pb-2 pl-0 messages-list-element d-flex"
                (dblclick)="openMessage(mymodal, mess.id, mess.folderId)"
                tabindex="1"
                (focus)="messageDetail(mess.id, mess.read, mess.folderId, $event)"
                [ngClass]="{'bg-light-gray': selectedMessage.id == mess.id}"
                [ngStyle]="{'font-weight': !mess.read ? 'bold' : ''}">
                <div class="messages-list-element-icons">
                  <i class="material-icons text-blue" *ngIf="!mess.read">fiber_manual_record</i>
                  <i class="material-icons text-blue"
                      *ngIf="!mess.linked && globalSettings.typeEnterprise == this.typeEnterprise.Airport">
                      link_off
                  </i>
                  <i class="material-icons text-red"
                    *ngIf="(['SS', 'DD', 'СС','ДД']).includes(mess.urgencyEnglish)">error</i>
                  <i class="material-icons text-blue" [ngStyle]="{'color': mess.mark }"
                    *ngIf="mess.mark">assistant_photo</i>
                  <i class="material-icons text-red" *ngIf="mess.errored">warning</i>
                </div>
                <div class="messages-list-element-info">
                  <p class="m-0 text-nowrap overflow-hidden">
                    <span class="text-gray-blue-dark"
                          i18n="cmn|Horizontal list - type@@cmn.main.horizontal.list.type">Type:</span>&nbsp;{{mess.typeEnglish}}
                    <span *ngFor="let flight of mess.flights; let i = index">
                      <span class="text-gray-blue-dark"
                            i18n="cmn|Horizontal list - flight@@cmn.main.horizontal.list.flight">Flight:</span>&nbsp;
                      {{flight.airlineIata}}&nbsp;{{flight.flight}}&nbsp;{{mess.flights.length!=i+1 ? ', ' : ''}}
                    </span>
                    <span class="float-right">
                      <span *ngIf="checkToday(mess.dtMessage)">{{ mess.dtMessage | date: 'HH:mm' : 'UTC+0'}} (UTC)</span>
                      <span *ngIf="!checkToday(mess.dtMessage)">{{mess.dtMessage | date: 'HH:mm dd.MM.yyyy' : 'UTC+0'}} (UTC)</span>
                      <i class="material-icons"
                        [ngClass]="{'text-green incoming': mess.direction==0, 'text-blue outgoing': mess.direction==1}">
                        transit_enterexit
                      </i>
                    </span>
                  </p>
                  <p class="m-0 text-nowrap overflow-hidden">
                    <span class="text-gray-blue-dark"
                      i18n="cmn|Horizontal list - route@@cmn.main.horizontal.list.route">Route:</span>&nbsp;
                    {{mess.flights && mess.flights.length && mess.flights[0].route ? mess.flights[0].route.join('-') : '-'}}
                    <span class="float-right">
                      <span class="text-gray-blue-dark"
                        i18n="cmn|Horizontal list - user@@cmn.main.horizontal.list.user">User:</span>&nbsp;
                      {{mess.username ? mess.username : ''}}
                    </span>
                  </p>
                  <p class="m-0 text-nowrap overflow-hidden">
                    <span class="text-gray-blue-dark"
                      i18n="cmn|Horizontal list - sender@@cmn.main.horizontal.list.sender">Sender:</span>&nbsp;
                      {{ mess.channelId !== channelIds.emailId ? (mess.dtMessage | date: 'ddHHmm' : 'UTC+0') : ''}} {{mess.sender}}
                    <span class="text-gray-blue-dark"
                      i18n="cmn|Horizontal list - recipient@@cmn.main.horizontal.list.recipient">Recipient:</span>&nbsp;
                    {{sliseText(mess.recipient, 17)}}
                  </p>
                  <p class="m-0 after-shadow position-relative" style="max-height: 20px; overflow: hidden;">
                    <span class="text-gray-blue-dark"
                      i18n="cmn|Horizontal list - text@@cmn.main.horizontal.list.text">Text:</span>&nbsp;
                    {{mess.message ? sliseText(mess.message, 60) : ''}}
                  </p>
                </div>
              </div>
            </div>
            <!-- Горизонтальное отображение списка сообщений -->
            <ng-template #messageHorigontal>
              <div class="messageHorigontal">
                <table class="w-100">
                  <thead>
                    <tr class="border-bottom">
                      <th class="p-1"></th>
                      <th class="p-1" i18n="cmn|Horizontal table - column type@@cmn.main.horizontal.table.col.type">
                        Type
                      </th>
                      <th class="p-1" i18n="cmn|Horizontal table - column sent received@@cmn.main.horizontal.table.col.sentReceived">
                        Sent / Received
                      </th>
                      <th class="p-1" i18n="cmn|Horizontal table - column sender@@cmn.main.horizontal.table.col.sender">
                        Sender
                      </th>
                      <th class="p-1"
                        i18n="cmn|Horizontal table - column recipient@@cmn.main.horizontal.table.col.recipient">
                        Recipient
                      </th>
                      <th class="p-1" i18n="cmn|Horizontal table - column flight@@cmn.main.horizontal.table.col.flight">
                        Flight
                      </th>
                      <th class="p-1" i18n="cmn|Horizontal table - column route@@cmn.main.horizontal.table.col.route">
                        Route
                      </th>
                      <th class="p-1"
                        i18n="cmn|Horizontal table - column message@@cmn.main.horizontal.table.col.message">
                        Message
                      </th>
                      <th class="p-1" i18n="cmn|Horizontal table - column user@@cmn.main.horizontal.table.col.user">
                        User
                      </th>
                      <!-- <th class="p-1" i18n="cmn|Horizontal table - column answer@@cmn.main.horizontal.table.col.answer">
                        Answer
                      </th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let mess of messages 
                                | paginate: {
                                  itemsPerPage: messagesInPage,
                                  currentPage: messagesPageActive,
                                  totalItems: messagesCount
                                }" #messageElement class="border-bottom pt-2 pr-2 pb-2 pl-0 messages-list-element"
                      (dblclick)="openMessage(mymodal, mess.id, mess.folderId)"
                      tabindex="1"
                      (focus)="messageDetail(mess.id, mess.read, mess.folderId, $event)"
                      [ngClass]="{'bg-light-gray': selectedMessage.id == mess.id}"
                      [ngStyle]="{'font-weight': !mess.read ? 'bold' : 'normal'}">
                      <td nowrap class="p-1 messages-table-icons">
                        <span>
                          <i class="material-icons text-blue"
                              *ngIf="!mess.linked && globalSettings.typeEnterprise == this.typeEnterprise.Airport">
                            link_off
                          </i>
                        </span>
                        <span>
                          <i class="material-icons text-red"
                            *ngIf="(['SS', 'DD', 'СС','ДД']).includes(mess.urgencyEnglish)">error</i>
                        </span>
                        <span>
                          <i class="material-icons text-blue" [ngStyle]="{'color': mess.mark }"
                            *ngIf="mess.mark">assistant_photo</i>
                        </span>
                        <span>
                          <i *ngIf="mess.errored" class="material-icons text-red">warning</i>
                        </span>
                        <span>
                          <i class="material-icons text-gray-blue-dark"
                            *ngIf="mess.answered || mess.hasIncoming">call_missed_outgoing</i>
                        </span>
                        <span>
                          <i class="material-icons text-green incoming" *ngIf="mess.direction==0">transit_enterexit</i>
                          <i class="material-icons text-blue outgoing" *ngIf="mess.direction==1">transit_enterexit</i>
                        </span>
                      </td>
                      <td nowrap class="p-1">{{mess.typeEnglish}}</td>
                      <td nowrap class="p-1">
                        <span *ngIf="checkToday(mess.dtMessage)">{{mess.dtMessage | date: 'HH:mm' : 'UTC+0'}} (UTC)</span>
                        <span *ngIf="!checkToday(mess.dtMessage)">{{mess.dtMessage | date: 'HH:mm dd.MM.yyyy' : 'UTC+0'}} (UTC)</span>
                      </td>
                      <td nowrap class="p-1">
                        {{ mess.channelId !== channelIds.emailId ? (mess.dtMessage | date: 'ddHHmm' : 'UTC+0') : ''}} {{sliseText(mess.sender, 20)}}
                      </td>
                      <td nowrap class="p-1">{{sliseText(mess.recipient, 20)}}</td>
                      <td nowrap class="p-1">
                        <ng-container *ngIf="mess.flights && mess.flights.length">
                          {{mess.flights[0].airlineIata}}&nbsp;{{mess.flights[0].flight}}
                          <span *ngIf="mess.flights.length > 1">...</span>
                        </ng-container>
                      </td>
                      <td nowrap class="p-1 text-uppercase">
                        {{mess.flights && mess.flights.length && mess.flights[0].route ? mess.flights[0].route.join('-') : ''}}
                        </td>
                      <td nowrap class="p-1 text-uppercase">{{sliseText(mess.message, 30)}}</td>
                      <td nowrap class="p-1">{{mess.username ? mess.username : ''}}</td>
                      <!-- <td nowrap class="p-1"></td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </div>
        </div>
        <!-- Превью сообщения в боковой или нижней часть экрана -->
        <div class="bg-gray p-0"
          [ngClass]="{'col-12 h-50 border-top': !commVertical, 'h-100 col-7': commVertical}">
          <div class="loading" *ngIf="loadingMessagesDetail">
            <div class="cssload-spin-box"></div>
          </div>
          <div *ngIf="messageGroup.length == 0 && !selectedMessage.id; else showMessage"
            class="d-flex align-items-center justify-content-center h-100">
            <span class="align-middle">
              <i class="material-icons md-48">mail_outline</i>
            </span>
            <span class="align-middle">
              <p class="h2 text-secondary"
                i18n="cmn|Preview - message not selected@@cmn.main.preview.messageNotSelected">
                Message not selected
              </p>
            </span>
          </div>
          <ng-template #showMessage>
            <div class="message-container" 
                 [ngClass]="{'horizontal': !commVertical,
                             'vertical': commVertical,
                             'showDetail': showDetail,
                             'notShowDetail': !showDetail}">
              
              <ng-container *ngIf="messageView == 'one'; else: messageChain">
                <div class="bg-white mb-3 font-medium selectedMessage">
                  <!-- TODO не используемый блок -->
                  <div *ngIf="(!selectedMessage.message && !selectedMessage.showOthers); else showOthers">
                    <div class="p-2 bg-light-gray d-flex">
                      <i class="material-icons text-red mr-2" *ngIf="message.errored">warning</i>
                      <span class="mr-2"
                        i18n="cmn|Horizontal preview private - header outgoing@@cmn.main.horizontal.previewPrivate.header.outgoing">
                        Outgoing
                      </span>
                      <span class="mr-2">
                        {{selectedMessage.dtMessage | date: 'HH:mm'}}
                      </span>
                      <span class="mr-2"
                            i18n="cmn|Horizontal preview private - header sender@@cmn.main.horizontal.previewPrivate.header.sender">
                        Sender:
                      </span>
                      &nbsp;{{selectedMessage.sender}}
                      &nbsp;{{selectedMessage.direction}}
                      <span *ngIf="selectedMessage.direction==1 outgoing">
                        <i class="material-icons text-blue float-right">transit_enterexit</i>
                      </span>
                    </div>
                  </div>
                  <ng-template #showOthers>
                    <div>
                      <div class="p-2 bg-light-gray d-flex align-items-center">
                        <i class="material-icons text-green incoming" *ngIf="selectedMessage.direction==0 || !selectedMessage.direction">transit_enterexit</i>
                        <i class="material-icons text-blue outgoing" *ngIf="selectedMessage.direction==1">transit_enterexit</i>
                        <i class="material-icons text-red mr-2 align-middle float-left"
                          *ngIf="selectedMessage.errored">warning</i>
                        <span *ngIf="selectedMessage.channelId != channelIds.aftnId" class="mr-1">
                          {{selectedMessage.dtMessage | date: 'HH:mm' : 'UTC+0'}}&nbsp;{{selectedMessage.sender}}
                        </span>
                        <span *ngIf="selectedMessage.channelId == channelIds.aftnId && selectedFolder != -1" class="mr-1">
                          {{selectedMessage.dtMessage | date: 'ddHHmm' : 'UTC+0'}}&nbsp;{{selectedMessage.sender}}
                        </span>
                        <span *ngIf="!selectedMessage.errored && selectedMessage.dtInsert" class="mr-1">
                          <span *ngIf="selectedMessage.direction == 0"
                                class="text-gray-blue-dark"
                                i18n="cmn|Horizontal preview - header received@@cmn.main.horizontal.preview.header.received">
                            Received
                          </span>
                          <span *ngIf="selectedMessage.direction == 1"
                                class="text-gray-blue-dark"
                                i18n="cmn|Horizontal preview - header sent@@cmn.main.horizontal.preview.header.sent">
                            Sent
                          </span>
                          {{selectedMessage.dtInsert | date: 'HH:mm' : 'UTC+0'}}
                        </span>
                        <span *ngIf="!selectedMessage.errored && selectedMessage.readConfirmations && processedTime" class="mr-1">
                          <span class="text-gray-blue-dark"
                            i18n="cmn|Horizontal preview - header processed@@cmn.main.horizontal.preview.header.processed">
                            processed</span>&nbsp;{{processedTime | date: 'HH:mm' : 'UTC+0'}}
                        </span>
                      </div>
                      <!-- Кнопки управления сообщением -->
                      <div class="p-2">
                        <div class="border-bottom">
                          <div class="font-medium row">
                            <div class="col-12 py-1">
                              <button type="button"
                                      class="btn btn-xs mb-2 btn-light-gray"
                                      [ngClass]="{'border': !commVertical}"
                                      (click)="openMessage(mymodal, selectedMessage.id)"
                                      data-toggle="modal"
                                      data-target=".bd-example-modal-xl">
                                <i class="material-icons">sort</i>
                                <span
                                  i18n="cmn|Horizontal preview body - button open message@@cmn.main.horizontal.preview.body.buttons.openMessage">
                                  Open message
                                </span>
                              </button>
                              <div *ngIf="globalSettings.typeEnterprise == this.typeEnterprise.Airport"
                                  class="btn-group ml-2 mb-2 rounded">
                                <button type="button"
                                        class="btn btn-xs btn-light-gray"
                                        [ngClass]="{'border': !commVertical}"
                                        (click)="attachToFlight(selectedMessage.id)"
                                        [disabled]="!checkFolderRuleIsFull() || selectedFolder==0">
                                  <i class="material-icons">flight</i>
                                  <span
                                    i18n="cmn|Horizontal preview body - button attach to flight@@cmn.main.horizontal.preview.body.buttons.attachToFlight">
                                    Attach to flight
                                  </span>
                                </button>
                                <button type="button"
                                        class="btn btn-xxs dropdown-toggle dropdown-toggle-split pl-0 btn-light-gray"
                                        [ngClass]="{'border': !commVertical}"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        [disabled]="!checkFolderRuleIsFull() || selectedFolder==0">
                                  <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu p-0">
                                  <button class="btn btn-xs dropdown-item"
                                          type="button"
                                          (click)="attachToAirline(selectedMessage.id)"
                                          [disabled]="!checkFolderRuleIsFull() || selectedFolder==0">
                                    <i class="material-icons">domain</i>
                                    <span
                                      i18n="cmn|Horizontal preview body - button attach to airline@@cmn.main.horizontal.preview.body.buttons.attachToAirline">
                                      Attach to airline
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div class="btn-group ml-2 mb-2">
                                <div class="dropdown">
                                  <button class="btn btn-xs dropdown-toggle btn-light-gray"
                                          [ngClass]="{'border': !commVertical}" type="button"
                                          id="dropdownFlagsDetail"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                          [disabled]="!checkFolderRuleIsFull() || selectedFolder < 1">
                                    <i class="material-icons" [ngStyle]="{'color': selectedMessage.mark }">assistant_photo</i>
                                    <span i18n="cmn|Horizontal preview body - button mark@@cmn.main.horizontal.preview.body.buttons.markSelect">Mark</span>
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="dropdownFlagsDetail">
                                    <button *ngFor="let mark of marks"
                                            class="dropdown-item"
                                            (click)="setMark(selectedMessage.id, mark)"
                                            [disabled]="!checkFolderRuleIsFull() || selectedFolder < 1">
                                      <i class="material-icons" [ngStyle]="{'color': mark }">assistant_photo</i>
                                      <span>{{mark}}</span>
                                    </button>
                                    <div class="dropdown-divider"></div>
                                    <button class="dropdown-item font-small"
                                            (click)="unsetMark(selectedMessage.id)"
                                            [disabled]="!checkFolderRuleIsFull() || selectedFolder < 1">
                                      <i class="material-icons" style="color: #5c5c5c;">assistant_photo</i>
                                      <span i18n="cmn|Horizontal preview body - button mark clear@@cmn.main.horizontal.preview.body.buttons.markClear">Clear</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="btn-group ml-2 mb-2" *ngIf="filterParams.depth=='current'">
                                <button type="button"
                                        class="btn btn-xs btn-light-gray"
                                        [ngClass]="{'border': !commVertical}"
                                        (click)="sendToArchive(selectedMessage.id)"
                                        [disabled]="!checkFolderRuleIsFull() || selectedFolder < 1 || selectedMessage.errored">
                                  <i class="material-icons">archive</i>
                                  <span
                                    i18n="cmn|Horizontal preview body - button send to archive@@cmn.main.horizontal.preview.body.buttons.sendToArchive">
                                    Send to archive
                                  </span>
                                </button>
                                <button type="button"
                                        class="btn btn-xxs dropdown-toggle dropdown-toggle-split pl-0 btn-light-gray"
                                        [ngClass]="{'border': !commVertical}"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        [disabled]="!checkFolderRuleIsFull() || selectedFolder < 1 || selectedMessage.errored">
                                  <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu p-0">
                                  <button class="btn btn-xs dropdown-item" type="button"
                                          (click)="notSendToArchive(selectedMessage.id)"
                                          [disabled]="!checkFolderRuleIsFull() || selectedFolder < 1">
                                    <i class="material-icons">unarchive</i>
                                    <span
                                      i18n="cmn|Horizontal preview body - button do not send to archive@@cmn.main.horizontal.preview.body.buttons.doNotSendToArchive">
                                      Do not send to archive</span>
                                  </button>
                                </div>
                              </div>
                              <button type="button"
                                      *ngIf="filterParams.depth=='archive'"
                                      class="btn btn-xs ml-2 mb-2 btn-light-gray"
                                      [ngClass]="{'border': !commVertical}"
                                      (click)="restoreFromArchive(selectedMessage.id)"
                                      [disabled]="!checkFolderRuleIsFull()">
                                <i class="material-icons">unarchive</i>
                                <span
                                  i18n="cmn|Horizontal preview body - button restore from archive@@cmn.main.horizontal.preview.body.buttons.restoreFromArchive">
                                  Restore from archive
                                </span>
                              </button>
                              <button type="button"
                                      class="btn btn-xs btn-ico btn-light-gray mr-2 float-right"
                                      [ngClass]="{'active': textSize==availableTextSizes.Large}"
                                      style="font-size: 18px;"
                                      (click)="setTextSize(availableTextSizes.Large)">
                                L
                              </button>
                              <button type="button"
                                      class="btn btn-xs btn-ico btn-light-gray mr-2 float-right"
                                      [ngClass]="{'active': textSize==availableTextSizes.Medium}"
                                      style="font-size: 14px;"
                                      (click)="setTextSize(availableTextSizes.Medium)">
                                M
                              </button>
                              <button type="button"
                                      class="btn btn-xs btn-ico btn-light-gray mr-2 float-right"
                                      [ngClass]="{'active': textSize==availableTextSizes.Small}"
                                      style="font-size: 10px;"
                                      (click)="setTextSize(availableTextSizes.Small)">
                                S
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="message-text-wrapping p-2 font-medium text-monospace"
                             [ngClass]="{'p-3': !commVertical}"
                             [ngStyle]="{'font-size.px': textSize}">{{selectedMessage.message}}
                        </div>
                        <div class="position-relative mt-3">
                          <button type="button"
                                  *ngIf="messageGroup.length>1"
                                  class="btn btn-gray-blue-dark btn-xs before-line m-auto d-block"
                                  (click)="messageView='chain'">
                            <i class="material-icons">arrow_drop_down</i>
                            <span i18n="cmn|Horizontal preview body - button thread@@cmn.main.horizontal.preview.body.buttons.thread">
                              Show thread
                            </span>
                          </button>
                        </div>
                        <div id="print-section" class="d-none">
                          <span class="message-text-wrapping text-monospace p-0 m-0">{{urgencyNameSelectedMessage}}&nbsp;{{selectedMessage.recipient}}<br>{{selectedMessage.dtMessage | date: 'ddHHmm' :
                            'UTC+0'}}&nbsp;{{selectedMessage.sender}}<br>{{selectedMessage.message}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
              <ng-template #messageChain>
              <div *ngFor="let oneMessage of messageGroup" class="bg-white mb-3 font-medium"
                [ngClass]="{'selectedMessage': message.id == oneMessage.id }">
                <div *ngIf="(!oneMessage.message && oneMessage.direction===1); else showOthers">
                  <div class="p-2 bg-light-gray d-flex">
                    <i class="material-icons text-red mr-2" *ngIf="message.errored">warning</i>
                    <span class="mr-2"
                      i18n="cmn|Horizontal preview private - header outgoing@@cmn.main.horizontal.previewPrivate.header.outgoing">
                      Outgoing
                    </span>
                    <span class="mr-2">
                      {{oneMessage.dtMessage | date: 'HH:mm' : 'UTC+0'}}
                    </span>
                    <span class="mr-2"
                      i18n="cmn|Horizontal preview private - header sender@@cmn.main.horizontal.previewPrivate.header.sender">
                      Sender:
                    </span>
                    &nbsp;{{oneMessage.sender}}
                    &nbsp;{{oneMessage.direction}}
                    <span *ngIf="oneMessage.direction==1 outgoing">
                      <i class="material-icons text-blue float-right">transit_enterexit</i>
                    </span>
                  </div>
                </div>
                <ng-template #showOthers>
                  <div (click)="selectMessage(oneMessage.id)">
                    <div class="p-2 bg-light-gray d-flex align-items-center">
                      <i class="material-icons text-green incoming" *ngIf="oneMessage.direction==0 || !oneMessage.direction">transit_enterexit</i>
                      <i class="material-icons text-blue outgoing" *ngIf="oneMessage.direction==1">transit_enterexit</i>
                      <i class="material-icons text-red mr-2 align-middle float-left"
                        *ngIf="oneMessage.errored">warning</i>
                      <span class="mr-1">
                        {{oneMessage.dtMessage | date: 'ddHHmm' : 'UTC+0'}}&nbsp;{{oneMessage.sender}}
                      </span>
                      <span *ngIf="oneMessage.dtInsert" class="mr-1">
                        <span *ngIf="oneMessage.direction == 0"
                          class="text-gray-blue-dark"
                          i18n="cmn|Horizontal preview - header received@@cmn.main.horizontal.preview.header.received">
                          Received
                        </span>
                        <span *ngIf="oneMessage.direction == 1"
                              class="text-gray-blue-dark"
                              i18n="cmn|Horizontal preview - header sent@@cmn.main.horizontal.preview.header.sent">
                          Sent
                        </span>
                        {{oneMessage.dtInsert | date: 'HH:mm' : 'UTC+0'}}
                      </span>
                      <span *ngIf="oneMessage.readConfirmations && processedTime" class="mr-1">
                        <span class="text-gray-blue-dark"
                          i18n="cmn|Horizontal preview - header processed@@cmn.main.horizontal.preview.header.processed">
                          processed
                        </span>&nbsp;{{processedTime | date: 'HH:mm' : 'UTC+0'}}
                      </span>
                    </div>

                    <div class="p-2">
                      <div class="border-bottom">
                        <div class="font-medium row">
                          <div class="col-12 py-1">
                            <button type="button"
                                    class="btn btn-xs mb-2 btn-light-gray"
                                    [ngClass]="{'border': !commVertical}"
                                    (click)="openMessage(mymodal, oneMessage.id)"
                                    data-toggle="modal"
                                    data-target=".bd-example-modal-xl">
                              <i class="material-icons">sort</i>
                              <span
                                i18n="cmn|Horizontal preview body - button open message@@cmn.main.horizontal.preview.body.buttons.openMessage">
                                Open message
                              </span>
                            </button>
                            <div *ngIf="globalSettings.typeEnterprise == this.typeEnterprise.Airport"
                                 class="btn-group ml-2 mb-2 rounded">
                              <button type="button" class="btn btn-xs btn-light-gray"
                                [ngClass]="{'border': !commVertical}" (click)="attachToFlight(oneMessage.id)"
                                [disabled]="selectedFolder==0">
                                <i class="material-icons">flight</i>
                                <span
                                  i18n="cmn|Horizontal preview body - button attach to flight@@cmn.main.horizontal.preview.body.buttons.attachToFlight">
                                  Attach to flight
                                </span>
                              </button>
                              <button type="button"
                                class="btn btn-xxs dropdown-toggle dropdown-toggle-split pl-0 btn-light-gray"
                                [ngClass]="{'border': !commVertical}" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false" [disabled]="selectedFolder==0">
                                <span class="sr-only">Toggle Dropdown</span>
                              </button>
                              <div class="dropdown-menu p-0">
                                <button class="btn btn-xs dropdown-item" type="button"
                                  (click)="attachToAirline(oneMessage.id)">
                                  <i class="material-icons">domain</i>
                                  <span
                                    i18n="cmn|Horizontal preview body - button attach to airline@@cmn.main.horizontal.preview.body.buttons.attachToAirline">
                                    Attach to airline
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div class="btn-group ml-2 mb-2">
                              <div class="dropdown">
                                <button class="btn btn-xs dropdown-toggle btn-light-gray"
                                        [ngClass]="{'border': !commVertical}"
                                        type="button"
                                        id="dropdownFlagsDetail"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        [disabled]="selectedFolder==0">
                                  <i class="material-icons" [ngStyle]="{'color': oneMessage.mark }">assistant_photo</i>
                                  <span i18n="cmn|Horizontal preview body - button mark@@cmn.main.horizontal.preview.body.buttons.markOneMessage">Mark</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownFlagsDetail">
                                  <button *ngFor="let mark of marks" class="dropdown-item"
                                    (click)="setMark(oneMessage.id, mark)">
                                    <i class="material-icons" [ngStyle]="{'color': mark }">assistant_photo</i>
                                    <span>{{mark}}</span>
                                  </button>
                                  <div class="dropdown-divider"></div>
                                  <button class="dropdown-item font-small"
                                          (click)="unsetMark(oneMessage.id)">
                                    <i class="material-icons" style="color: #5c5c5c;">assistant_photo</i>
                                    <span i18n="cmn|Horizontal preview body - button mark clear chain@@cmn.main.horizontal.preview.body.buttons.markClearChain">Clear</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="btn-group ml-2 mb-2" *ngIf="filterParams.depth=='current'">
                              <button type="button" class="btn btn-xs btn-light-gray"
                                [ngClass]="{'border': !commVertical}"
                                (click)="sendToArchive(oneMessage.id)"
                                [disabled]="selectedFolder==0 || !checkFolderRuleIsFull() || selectedFolder < 1 || oneMessage.errored">
                                <i class="material-icons">archive</i>
                                <span
                                  i18n="cmn|Horizontal preview body - button send to archive@@cmn.main.horizontal.preview.body.buttons.sendToArchive">
                                  Send to archive
                                </span>
                              </button>
                              <button type="button"
                                class="btn btn-xxs dropdown-toggle dropdown-toggle-split pl-0 btn-light-gray"
                                [ngClass]="{'border': !commVertical}" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"
                                [disabled]="selectedFolder==0 || !checkFolderRuleIsFull() || selectedFolder < 1 || oneMessage.errored">
                                <span class="sr-only">Toggle Dropdown</span>
                              </button>
                              <div class="dropdown-menu p-0">
                                <button class="btn btn-xs dropdown-item" type="button"
                                  (click)="notSendToArchive(oneMessage.id)">
                                  <i class="material-icons">unarchive</i>
                                  <span
                                    i18n="cmn|Horizontal preview body - button do not send to archive@@cmn.main.horizontal.preview.body.buttons.doNotSendToArchive">
                                    Do not send to archive</span>
                                </button>
                              </div>
                            </div>
                            <button type="button" *ngIf="filterParams.depth=='archive'"
                              class="btn btn-xs ml-2 mb-2 btn-light-gray" [ngClass]="{'border': !commVertical}"
                              (click)="restoreFromArchive(oneMessage.id)">
                              <i class="material-icons">unarchive</i>
                              <span
                                i18n="cmn|Horizontal preview body - button restore from archive@@cmn.main.horizontal.preview.body.buttons.restoreFromArchive">
                                Restore from archive
                              </span>
                            </button>
                            <button type="button"
                                    class="btn btn-xs btn-ico btn-light-gray mr-2 float-right"
                                    [ngClass]="{'active': textSize==availableTextSizes.Large}"
                                    style="font-size: 18px;"
                                    (click)="setTextSize(availableTextSizes.Large)">
                              L
                            </button>
                            <button type="button"
                                    class="btn btn-xs btn-ico btn-light-gray mr-2 float-right"
                                    [ngClass]="{'active': textSize==availableTextSizes.Medium}"
                                    style="font-size: 14px;"
                                    (click)="setTextSize(availableTextSizes.Medium)">
                              M
                            </button>
                            <button type="button"
                                    class="btn btn-xs btn-ico btn-light-gray mr-2 float-right"
                                    [ngClass]="{'active': textSize==availableTextSizes.Small}"
                                    style="font-size: 10px;"
                                    (click)="setTextSize(availableTextSizes.Small)">
                              S
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="message-text-wrapping text-uppercase p-2 font-medium text-monospace"
                           [ngClass]="{'p-3': !commVertical}"
                           [ngStyle]="{'font-size.px': textSize}">{{oneMessage.message}}
                      </div>
                      <div id="print-section" class="d-none">
                        <span class="message-text-wrapping text-monospace p-0 m-0">{{urgencyNameSelectedMessage}}&nbsp;{{selectedMessage.recipient}}<br>{{selectedMessage.dtMessage | date: 'ddHHmm' :
                          'UTC+0'}}&nbsp;{{selectedMessage.sender}}<br>{{selectedMessage.message}}
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="show-thead-block position-relative">
                <button type="button" class="btn btn-gray-blue-dark btn-xs before-line m-auto d-block" (click)="messageView='one'">
                  <i class="material-icons">arrow_drop_up</i>
                  <span i18n="cmn|Horizontal preview body - button hide thread@@cmn.main.horizontal.preview.body.buttons.hideThread">
                    Hide thread
                  </span>
                </button>
              </div>
              </ng-template>

              <div class="message-info">
                <div class="message-info-header">
                  <p placement="{{commVertical ? 'top' : 'left'}}"
                     [ngbPopover]="popContent"
                     container="body"
                     class="mr-auto"
                     popoverTitle="Service information"
                     i18n="cmn|Horizontal preview body - detail top@@cmn.main.horizontal.preview.body.detailTop"
                     i18n-popoverTitle="cmn|Horizontal preview body - detail top service info@@cmn.main.horizontal.preview.body.detailTopServiceInfo">
                    Detail
                  </p>
                  <ng-template #popContent>
                    <span i18n="cmn|Horizontal preview body - service message id@@cmn.main.horizontal.preview.body.serviceMessageId">Mesage ID:</span>&nbsp;
                    <a [routerLink]="['/reports']" target="_blank" [queryParams]="{'id': selectedMessage.id, 'report_id': 26}">{{selectedMessage.id}}</a>
                  </ng-template>
                  <div class="mr-2 fs-6 d-none"
                        id="detailCopied"
                        i18n="cmn|Horizontal preview body - copied@@cmn.main.horizontal.preview.body.copied">
                    Сopied!
                  </div>
                  <button class="message-info-btn mr-2"
                          (click)="copyToBuffer()"
                          ngbTooltip="Copy recipient list"
                          placement="left"
                          i18n-ngbTooltip="cmn|Horizontal preview body - copy recipient list@@cmn.main.horizontal.preview.body.сopyRecipientList">
                    <i class="material-icons">content_copy</i>
                  </button>
                  <button class="message-info-btn" (click)="showDetail = !showDetail">
                    <i class="material-icons">arrow_right</i>
                  </button>
                </div>
                <table class="w-100">
                  <tr>
                    <th i18n="cmn|Horizontal preview body - channel@@cmn.main.horizontal.preview.body.channel">Channel
                    </th>
                    <td>
                      {{selectedMessage.channelName}}
                      {{selectedMessage.dtInsert | date: 'HH:mm dd.MM.yyyy' : 'UTC+0'}}
                    </td>
                  </tr>
                  <tr>
                    <th i18n="cmn|Horizontal preview body - category@@cmn.main.horizontal.preview.body.category">
                      Category</th>
                    <td>{{selectedMessage.urgencyNameEnglish}}</td>
                  </tr>
                  <tr>
                    <th i18n="cmn|Horizontal preview body - type@@cmn.main.horizontal.preview.body.type">Type</th>
                    <td>
                      <ng-container *ngIf="selectedMessage.typeId; else noType">
                        {{getById(types, selectedMessage.typeId).nameEnglish}}
                      </ng-container>
                      <ng-template #noType> - </ng-template>
                    </td>
                  </tr>
                  <tr>
                    <th i18n="cmn|Horizontal preview body - flight@@cmn.main.horizontal.preview.body.flight">Flight</th>
                    <td>
                      <ng-container *ngIf="selectedMessage.flights !=undefined; else oneMessageNoFlight">
                        <ng-container *ngFor="let flight of selectedMessage.flights; let i = index"
                          class="text-truncate">
                          <strong>{{flight.flight}} </strong>
                          <ng-container *ngIf="flight.times!=null">
                            <span i18n="cmn|Horizontal preview body - flight for@@cmn.main.horizontal.preview.body.flightFor">for</span>&nbsp;
                            <strong>{{flight.times[0] | date: 'dd.MM.yyyy'}}</strong>
                          </ng-container>
                          <span *ngIf="selectedMessage.flights.length!=i+1">; </span>
                        </ng-container>
                      </ng-container>
                      <ng-template #oneMessageNoFlight> - </ng-template>
                    </td>
                  </tr>
                  <tr>
                    <th i18n="cmn|Horizontal preview body - created@@cmn.main.horizontal.preview.body.created">Created</th>
                    <td>{{selectedMessage.channelId === channelIds.aftnId
                      ? (selectedMessage.dtMessage | date: 'ddHHmm' : 'UTC+0')
                      : (selectedMessage.dtMessage | date: 'HH:mm' : 'UTC+0')}}</td>
                  </tr>
                  <tr>
                    <th i18n="cmn|Horizontal preview body - sender@@cmn.main.horizontal.preview.body.sender">Sender</th>
                    <td>{{selectedMessage.sender}}</td>
                  </tr>
                  <tr>
                    <th i18n="cmn|Horizontal preview body - recipient@@cmn.main.horizontal.preview.body.recipient">
                      Recipient
                    </th>
                    <td id="detailRecipients">{{selectedMessage.recipient ? selectedMessage.recipient.split(',').join(' ') : '-'}}</td>
                  </tr>
                  <tr>
                    <th i18n="cmn|Horizontal preview body - processed@@cmn.main.horizontal.preview.body.processed">
                      Processed
                    </th>
                    <td>
                      <span *ngFor="let reader of selectedMessage.readConfirmations; let i = index"
                            [ngClass]="{'font-weight-bold': !checkRead(reader.time)}">{{reader.folder}}<span *ngIf="checkRead(reader.time)">&nbsp;<span
                                i18n="cmn|Horizontal preview body - processed at@@cmn.main.horizontal.preview.body.processedAt">at</span>&nbsp;
                        <ng-container *ngIf="checkToday(reader.time)">{{reader.time | date: 'HH:mm' : 'UTC+0'}}</ng-container>
                        <ng-container *ngIf="!checkToday(reader.time)">{{reader.time | date: 'HH:mm dd.MM.yyyy' : 'UTC+0'}}</ng-container>
                        </span><span *ngIf="selectedMessage.readConfirmations.length!=i+1">; </span>
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    </div>
  </ng-template>

  <!-- Modal - message detail -->
  <ng-template #mymodal let-modal>
    <form #modalMessage="ngForm">
      <div class="loading" *ngIf="loading">
        <div class="cssload-spin-box"></div>
      </div>
      <div class="wrapper-answer wrapper-answer-check" *ngIf="messageSended">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" stroke="green" stroke-width="3"
          stroke-dasharray="100">
          <path id="check" d="M 12,22 L 22,31 L 36,13" stroke-dashoffset="0">
            <animate attributeName="stroke-dashoffset" from="100" to="0" repeatCount="indefinite" dur="3s"></animate>
          </path>
        </svg> {{userAction === 'close' ? systemMessagesTexts['messageSave'] : systemMessagesTexts['messageSent']}}
      </div>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalScrollableTitle" *ngIf="message.channelId == channelIds.aftnId; else: normalTime">
            {{message.dtMessage | date: 'ddHHmm' : 'UTC+0'}}&nbsp;{{message.sender}}
          </h5>
          <ng-template #normalTime>
            <h5 class="modal-title" id="exampleModalScrollableTitle" *ngIf="!isNewMessage">
              {{message.dtMessage | date: 'HH:mm' : 'UTC+0'}}&nbsp;{{message.sender}}
            </h5>
            <!-- <h5 class="modal-title" id="exampleModalScrollableTitle" *ngIf="isNewMessage">
              {{message.subject}}
            </h5> -->
          </ng-template>
          <button type="button" class="close mp-0" data-dismiss="modal" aria-label="Close"
            (click)="closeModalMessage(modal.dismiss)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="bg-white modal-body border-bottom-2px" *ngIf="!isNewMessage && message.direction!=2">
          <button type="button"
                  class="btn btn-xs btn-light-gray mr-2"
                  (click)="replyMessage(messageId)"
                  [disabled]="!checkFolderRuleIsFull() || message.direction==1 || selectedFolder==0">
            <i class="material-icons">reply</i>
            <span i18n="cmn|Modal message detail - button reply@@cmn.modalMessage.buttons.reply">
              Reply
            </span>
          </button>
          <button type="button"
                  class="btn btn-xs btn-light-gray mr-2"
                  (click)="forwardMessage(messageId)"
                  [disabled]="!checkFolderRuleIsFull() || selectedFolder==0">
            <i class="material-icons">forward</i>
            <span i18n="cmn|Modal message detail - button forward@@cmn.modalMessage.buttons.forward">
              Forward
            </span>
          </button>
          <button type="button"
                  class="btn btn-xs btn-light-gray mr-2"
                  (click)="unreadMessage(message.id, modal.dismiss)"
                  [disabled]="!checkFolderRuleIsFull() || selectedFolder==0 || message.direction == 1 || filterParams.depth == 'archive'">
            <i class="material-icons">markunread_mailbox</i>
            <span i18n="cmn|Modal message detail - button unread@@cmn.modalMessage.buttons.unread">
              Unread
            </span>
          </button>
          <button type="button"
                  class="btn btn-xs btn-light-gray mr-2"
                  (click)="selectAction('copy', messageId)"
                  [disabled]="!checkFolderRuleIsFull() || message.direction==1 || selectedFolder==0">
            <i class="material-icons">how_to_vote</i>
            <span i18n="cmn|Modal message detail - button copy to folder@@cmn.modalMessage.buttons.copyToFolder">
              Copy To Folder
            </span>
          </button>
          <div class="btn-group mr-2">
            <div class="dropdown">
              <button class="btn btn-xs dropdown-toggle btn-light-gray"
                      [ngClass]="{'border': !commVertical}"
                      type="button"
                      id="dropdownFlagsDetail"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      [disabled]="!checkFolderRuleIsFull() || selectedFolder==0">
                <i class="material-icons" [ngStyle]="{'color': message.mark }">assistant_photo</i>
                <span i18n="cmn|Modal message detail - button mark@@cmn.modalMessage.buttons.mark">Mark</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownFlagsDetail">
                <button *ngFor="let mark of marks" class="dropdown-item"
                        (click)="setMark(message.id, mark)">
                  <i class="material-icons" [ngStyle]="{'color': mark }">assistant_photo</i>
                  {{mark}}
                </button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item font-small"
                        (click)="unsetMark(message.id)">
                  <i class="material-icons" style="color: #5c5c5c;">assistant_photo</i>
                  <span i18n="cmn|Modal message detail - button mark clear@@cmn.modalMessage.buttons.markClear">Clear</span>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="filterParams.depth=='current'" class="btn-group mr-2">
            <button type="button"
                    class="btn btn-xs btn-light-gray"
                    [ngClass]="{'border': !commVertical}"
                    (click)="sendToArchive(message.id)"
                    [disabled]="!checkFolderRuleIsFull() || selectedFolder==0 || message.errored">
              <i class="material-icons">archive</i>
              <span i18n="cmn|Modal message detail - button send to archive@@cmn.modalMessage.buttons.sendToArchive">
                Send to archive
              </span>
            </button>
            <button type="button"
                    class="btn btn-xxs dropdown-toggle dropdown-toggle-split pl-0 btn-light-gray"
                    [ngClass]="{'border': !commVertical}"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    [disabled]="!checkFolderRuleIsFull() || message.errored">
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <div class="dropdown-menu p-0">
              <button class="btn btn-xs dropdown-item"
                      type="button"
                      (click)="notSendToArchive(message.id)">
                <i class="material-icons">unarchive</i>
                <span i18n="cmn|Modal message detail - button do not send to archive@@cmn.modalMessage.buttons.doNotSendToArchive">
                  Do not send to archive
                </span>
              </button>
            </div>
          </div>
          <button type="button"
                  *ngIf="filterParams.depth=='archive'"
                  class="btn btn-xs btn-light-gray"
                  [ngClass]="{'border': !commVertical}"
                  (click)="restoreFromArchive(message.id)">
            <i class="material-icons">unarchive</i>
            <span i18n="cmn|Modal message detail - button restore from archive@@cmn.modalMessage.buttons.restoreFromArchive">
              Restore from archive
            </span>
          </button>
          <button type="button"
                  [useExistingCss]="true"
                  printSectionId="print-section"
                  (click)="printMessage(messageId)"
                  ngxPrint
                  class="btn btn-xs btn-light-gray mr-2 float-right">
            <i class="material-icons">print</i>
            <span i18n="cmn|Modal message detail - button print@@cmn.modalMessage.buttons.print">
              Print
            </span>
          </button>
          <button type="button" class="btn btn-xs btn-light-gray mr-2 float-right" (click)="saveToFile(messageId)">
            <i class="material-icons">save_alt</i>
            <span i18n="cmn|Modal message detail - button download@@cmn.modalMessage.buttons.download">
              Download
            </span>
          </button>
        </div>
        <div class="modal-body bg-light-gray border-bottom-2px pb-2">
          <div class="row" *ngIf="isNewMessage; else: notNewMessage">
            <div class="col-auto pr-0 mb-2" *ngIf="messagePanel === 'small'">
              <div class="list-group channels">
                <button type="button"
                        class="list-group-item list-group-item-action d-flex align-items-center justify-content-between"
                        [ngClass]="{'active': activeChannel === channelIds.aftnId && sendersForChannels[channelIds.aftnId].length != 0}"
                        (click)="activeChannel = channelIds.aftnId"
                        [disabled]="sendersForChannels[channelIds.aftnId].length == 0">
                  AFTN
                  <span *ngIf="message.recipentObjectList[channelIds.aftnId].length > 0"
                        class="material-icons">
                    how_to_reg
                  </span>
                </button>
                <button type="button"
                        class="list-group-item list-group-item-action d-flex align-items-center justify-content-between"
                        [ngClass]="{'active': activeChannel === channelIds.sitatexId && sendersForChannels[channelIds.sitatexId].length != 0}"
                        (click)="activeChannel = channelIds.sitatexId"
                        [disabled]="sendersForChannels[channelIds.sitatexId].length == 0">
                  SITA
                  <span *ngIf="message.recipentObjectList[channelIds.sitatexId].length > 0"
                        class="material-icons">
                    how_to_reg
                  </span>
                </button>
                <button type="button"
                        class="list-group-item list-group-item-action d-flex align-items-center justify-content-between"
                        [ngClass]="{'active': activeChannel === channelIds.emailId && sendersForChannels[channelIds.emailId].length != 0}"
                        (click)="activeChannel = channelIds.emailId"
                        [disabled]="sendersForChannels[channelIds.emailId].length == 0">
                  Email
                  <span *ngIf="message.recipentObjectList[channelIds.emailId].length > 0"
                        class="material-icons">
                    how_to_reg
                  </span>
                </button>
              </div>
            </div>
            <div [ngClass]="{'col': messagePanel === 'small', 'col-12': messagePanel === 'full'}">
              <!-- AFTN -->
              <div class="channel card mb-2 p-2"
                  [ngStyle]="{'display': messagePanel=='small' && activeChannel !== channelIds.aftnId ? 'none' : 'block'}"
                  [ngClass]="{'disabled': sendersForChannels[channelIds.aftnId].length == 0}">
                <div class="row">
                  <div class="col-auto card-channel-name pl-4" *ngIf="messagePanel === 'full'">
                    <span *ngIf="message.recipentObjectList[channelIds.aftnId].length > 0"
                          class="material-icons">
                      how_to_reg
                    </span>
                    AFTN
                  </div>
                  <div class="col">
                    <div class="card-channel-info row">
                      <div class="col-12 mb-2">
                        <div *ngIf="errorRecipientAddress[channelIds.aftnId]"
                              class="invalid-feedback d-block float-left">
                          {{errorRecipientAddress[channelIds.aftnId]}}
                        </div>
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - recipient@@cmn.modalMessage.recipient">
                                Recipient
                              </span>
                          </div>
                          <ng-select [items]="contactsForChannels[channelIds.aftnId]"
                                     *ngIf="!recipientLoadAnimation; else recipientLoad"
                                     class="w-100 custom-sm"
                                     bindLabel="name"
                                     bindValue="id"
                                     name="recipientListAftn"
                                     id="recipientListAftn"
                                     groupBy="addresses"
                                     [addTag]="addTagContactRefernaceAftn"
                                     (blur)="addTagContactFromArray(selectEnteredData.aftn, channelIds.aftnId)"
                                     (clear)="onClearFilterInput('aftn')"
                                     (remove)="onClearTagFilterInput($event, 'aftn')"
                                     (search)="onSearchFilterInput($event, 'aftn')"
                                     [multiple]="true"
                                     [hideSelected]="true"
                                     [closeOnSelect]="false"
                                     [clearSearchOnAdd]="true"
                                     [selectableGroup]="true"
                                     [selectableGroupAsModel]="false"
                                     [(ngModel)]="message.recipentObjectList[channelIds.aftnId]"
                                     (change)="changeRecipientList(channelIds.aftnId)"
                                     (close)="recipientListClose()"
                                     (add)="onAddContactRefernace($event)"
                                     [required]="message.recipentObjectList[channelIds.sitatexId].length < 1 && message.recipentObjectList[channelIds.emailId].length < 1"
                                     [disabled]="sendersForChannels[channelIds.aftnId].length == 0">
                            <ng-template ng-optgroup-tmp let-item="item">
                              {{item.name || 'Other addresses'}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                              {{item.name}}
                            </ng-template>
                            <ng-template ng-tag-tmp let-search="searchTerm">
                              <b>
                                <span i18n="cmn|Modal message detail - add user aftn address@@cmn.modalMessage.addUserAftnAddress">Add user address:</span>
                              </b>&nbsp;{{search}}
                            </ng-template>
                          </ng-select>
                          <ng-template #recipientLoad>
                            <div class="d-flex align-items-center py-0 pl-2 bg-transperante rounded-right w-100 border border-left-0">
                              <div class="spinner-border spinner-border-sm float-left" role="status" aria-hidden="true"></div>
                              <div class="float-left pl-2"
                                   i18n="cmn|Modal message detail - recipient pasing message@@cmn.modalMessage.recipientParsingMessage">
                                Parsing an address string...
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                      <div class="col-2 pr-0">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <div class="dropdown">
                              <button class="btn btn-xs dropdown-toggle btn-light-gray"
                                      type="button" id="numberMessage"
                                      data-toggle="dropdown"
                                      [disabled]="!checkFolderRuleIsFull() ||
                                            !isNewMessage && message.direction == 0 ||
                                            !isNewMessage && message.direction == 1 && !message.errored ||
                                            sendersForChannels[channelIds.aftnId].length == 0">
                                <span i18n="cmn|Modal message detail - number@@cmn.modalMessage.number"
                                      class="font-small" style="line-height: 16px;">Number</span>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownFlagsDetail">
                                <button class="dropdown-item font-small" (click)="autoNumber = true">
                                  <span class="material-icons font-small" *ngIf="autoNumber === true">check</span>
                                  <span i18n="cmn|Modal message detail - auto number@@cmn.modalMessage.autoNumber"
                                    [ngClass]="{'pl-3': autoNumber !== true}">Auto - current sending time</span>
                                </button>
                                <button class="dropdown-item font-small" (click)="autoNumber = false">
                                  <span class="material-icons font-small" *ngIf="autoNumber === false">check</span>
                                  <span i18n="cmn|Modal message detail - manual input@@cmn.modalMessage.manualInput"
                                    [ngClass]="{'pl-3': autoNumber !== false}">Manual input</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <input id="at"
                                name="at"
                                type="text"
                                class="w-100 custom-sm form-control form-control-md text-uppercase"
                                [ngModel]="autoNumber ? 'Auto' : message.dtMessage | date: 'ddHHmm' : 'UTC+0'"
                                (blur)="setDateMessage($event)"
                                pattern="^(0[1-9]|[12][0-9]|3[01])([01][0-9]|[2][0-3])[0-5][0-9]?$"
                                [required]="message.recipentObjectList[channelIds.aftnId].length > 0"
                                minlength=""
                                [disabled]="!checkFolderRuleIsFull() ||
                                            !isNewMessage && message.direction == 0 ||
                                            !isNewMessage && message.direction == 1 && !message.errored ||
                                            sendersForChannels[channelIds.aftnId].length == 0 ||
                                            autoNumber === true">
                        </div>
                      </div>
                      <!-- <div class="pr-0" [ngClass]="{'col-3': !isNewMessage, 'col-4': isNewMessage}"> -->
                      <div class="pr-0 col-4">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - sender@@cmn.modalMessage.sender">
                                Sender
                              </span>
                          </div>
                          <ng-select *ngIf="selectedTelegramLanguage.label=='RU'; else senderRuAftn"
                                     [items]="sendersForChannels[channelIds.aftnId]"
                                     class="w-100 custom-sm"
                                     bindLabel="addressLocal"
                                     bindValue="addressLocal"
                                     name="senderAftn"
                                     id="senderAftn"
                                     [(ngModel)]="message.senders[channelIds.aftnId]"
                                     [required]="message.recipentObjectList[channelIds.aftnId].length > 0"
                                     [disabled]="sendersForChannels[channelIds.aftnId].length == 0">
                          </ng-select>
                          <ng-template #senderRuAftn>
                            <ng-select [items]="sendersForChannels[channelIds.aftnId]"
                                      class="w-100 custom-sm"
                                      bindLabel="addressEnglish"
                                      bindValue="addressEnglish"
                                      name="senderAftn"
                                      id="senderAftn"
                                      [(ngModel)]="message.senders[channelIds.aftnId]"
                                      [required]="message.recipentObjectList[channelIds.aftnId].length > 0"
                                      [disabled]="sendersForChannels[channelIds.aftnId].length == 0">
                            </ng-select>
                          </ng-template>
                        </div>
                      </div>
                      <!-- <div class="pr-0" [ngClass]="{'col-3': !isNewMessage, 'col-4': isNewMessage}"> -->
                      <div class="pr-0 col-3">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - category@@cmn.modalMessage.category">
                                Category
                              </span>
                          </div>
                          <ng-select *ngIf="selectedTelegramLanguage.label=='RU'; else channelRuAftn"
                                    class="w-100 custom-sm"
                                    [items]="urgenciesForChannel[channelIds.aftnId]"
                                    bindLabel="nameLocal"
                                    bindValue="id"
                                    name="urgencyAftn"
                                    id="urgencyAftn"
                                    [(ngModel)]="message.urgencyIds[channelIds.aftnId]"
                                    [required]="message.recipentObjectList[channelIds.aftnId].length > 0"
                                    [disabled]="!checkFolderRuleIsFull()
                                                || urgenciesForChannel[channelIds.aftnId].length == null
                                                || !isNewMessage && message.direction == 0
                                                || !isNewMessage && message.direction == 1 && !message.errored
                                                || sendersForChannels[channelIds.aftnId].length == 0">
                          </ng-select>
                          <ng-template #channelRuAftn>
                            <ng-select [items]="urgenciesForChannel[channelIds.aftnId]"
                                      class="w-100 custom-sm"
                                      bindLabel="nameEnglish"
                                      bindValue="id"
                                      name="urgencyAftn"
                                      id="urgencyAftn"
                                      [(ngModel)]="message.urgencyIds[channelIds.aftnId]"
                                      [required]="message.recipentObjectList[channelIds.aftnId].length > 0"
                                      [disabled]="!checkFolderRuleIsFull()
                                                  || urgenciesForChannel[channelIds.aftnId].length == null
                                                  || !isNewMessage && message.direction == 0
                                                  || !isNewMessage && message.direction == 1 && !message.errored
                                                  || sendersForChannels[channelIds.aftnId].length == 0">
                            </ng-select>
                          </ng-template>
                        </div>
                      </div>
                      <!-- <div [ngClass]="{'col-3': !isNewMessage, 'col-4': isNewMessage}"> -->
                        <div class="col-3">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - telegram language@@cmn.modalMessage.buttons.telegramLanguage">
                                Telegram language
                              </span>
                          </div>
                          <ng-select class="w-100 custom-sm"
                                    [items]="defaultTelegramLanguageBindingsList"
                                    [clearable]="false" [clearOnBackspace]="false" [searchable]="false"
                                    [(ngModel)]="selectedTelegramLanguage"
                                    name="selectedTelegramLanguage" id="selectedTelegramLanguage"
                                    (close)="onChangeTelegramLanguage($event)"
                                    [disabled]="sendersForChannels[channelIds.aftnId].length == 0">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--SITA-->
              <div class="channel card mb-2 p-2"
                  [ngStyle]="{'display': messagePanel=='small' && activeChannel !== channelIds.sitatexId ? 'none' : 'block'}"
                  [ngClass]="{'disabled': !sendersForChannels[channelIds.sitatexId] || sendersForChannels[channelIds.sitatexId].length == 0}">
                <div class="row">
                  <div class="col-auto card-channel-name pl-4" *ngIf="messagePanel === 'full'">
                    <span *ngIf="message.recipentObjectList[channelIds.sitatexId].length > 0"
                          class="material-icons">
                      how_to_reg
                    </span>
                    SITA
                  </div>
                  <div class="col">
                    <div class="card-channel-info row">
                      <div class="col-12 mb-2">
                        <div *ngIf="errorRecipientAddress[channelIds.sitatexId]"
                              class="invalid-feedback d-block float-left">
                          {{errorRecipientAddress[channelIds.sitatexId]}}
                        </div>
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - recipient@@cmn.modalMessage.recipient">
                                Recipient
                              </span>
                          </div>
                          <ng-select [items]="contactsForChannels[channelIds.sitatexId]"
                                     *ngIf="!recipientLoadAnimation; else recipientLoad"
                                     class="w-100 custom-sm"
                                     bindLabel="name"
                                     bindValue="id"
                                     name="recipientListSitatex"
                                     id="recipientListSitatex"
                                     groupBy="addresses"
                                     [addTag]="addTagContactRefernaceSitatex"
                                     (blur)="addTagContactFromArray(selectEnteredData.sitatex, channelIds.sitatexId)"
                                     (clear)="onClearFilterInput('sitatex')"
                                     (remove)="onClearTagFilterInput($event, 'sitatex')"
                                     (search)="onSearchFilterInput($event, 'sitatex')"
                                     [multiple]="true"
                                     [hideSelected]="true"
                                     [closeOnSelect]="false"
                                     [clearSearchOnAdd]="true"
                                     [selectableGroup]="true"
                                     [selectableGroupAsModel]="false"
                                     [(ngModel)]="message.recipentObjectList[channelIds.sitatexId]"
                                     (close)="recipientListClose()"
                                     (add)="onAddContactRefernace($event)"
                                     [required]="message.recipentObjectList[channelIds.aftnId].length < 1 && message.recipentObjectList[channelIds.emailId].length < 1"
                                     [disabled]="sendersForChannels[channelIds.sitatexId].length == 0">
                            <ng-template ng-optgroup-tmp let-item="item">
                              {{item.name || 'Other addresses'}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                              {{item.name}}
                            </ng-template>
                            <ng-template ng-tag-tmp let-search="searchTerm">
                              <b>
                                <span i18n="cmn|Modal message detail - add user sita address@@cmn.modalMessage.addUserSitaAddress">Add user address:</span>
                              </b>&nbsp;{{search}}
                            </ng-template>
                          </ng-select>
                          <ng-template #recipientLoad>
                            <div class="d-flex align-items-center py-0 pl-2 bg-transperante rounded-right w-100 border border-left-0">
                              <div class="spinner-border spinner-border-sm float-left" role="status" aria-hidden="true"></div>
                              <div class="float-left pl-2"
                                   i18n="cmn|Modal message detail - recipient pasing message@@cmn.modalMessage.recipientParsingMessage">
                                Parsing an address string...
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                      <div class="col-4 pr-0" *ngIf="!isNewMessage">
                        <div class="input-group">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - at date@@cmn.modalMessage.atDate">
                                Date
                              </span>
                          </div>
                          <input type="time"
                                class="custom-sm form-control"
                                [ngModel]="message.dtMessage | date: 'HH:mm' : 'UTC+0'"
                                (input)="message.dtMessage = parseDateTime(start_datedtMessage.value, $event.target.value)"
                                name="start_timedtMessage"
                                [disabled]="!checkFolderRuleIsFull()
                                            || (!isNewMessage && !message.errored)
                                            || sendersForChannels[channelIds.sitatexId].length == 0"
                                #start_timedtMessage>
                          <input type="date"
                                class="custom-sm form-control"
                                [ngModel]="message.dtMessage | date: 'yyyy-MM-dd' : 'UTC+0'"
                                (input)="message.dtMessage = parseDateTime($event.target.value, start_timedtMessage.value)"
                                name="start_datedtMessage"
                                [disabled]="!checkFolderRuleIsFull()
                                            || (!isNewMessage && !message.errored)
                                            || sendersForChannels[channelIds.sitatexId].length == 0"
                                #start_datedtMessage>
                        </div>
                      </div>
                      <div class="pr-0" [ngClass]="{'col-4': !isNewMessage, 'col-6': isNewMessage}">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - sender@@cmn.modalMessage.sender">
                                Sender
                              </span>
                          </div>
                          <ng-select class="w-100 custom-sm"
                                     [items]="sendersForChannels[channelIds.sitatexId]"
                                     bindLabel="addressEnglish"
                                     bindValue="addressEnglish"
                                     name="senderSitatex"
                                     id="senderSitatex"
                                     [(ngModel)]="message.senders[channelIds.sitatexId]"
                                     [required]="message.recipentObjectList[channelIds.sitatexId].length > 0"
                                     [disabled]="sendersForChannels[channelIds.sitatexId].length == 0">
                          </ng-select>
                        </div>
                      </div>
                      <div [ngClass]="{'col-4': !isNewMessage, 'col-6': isNewMessage}">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - category@@cmn.modalMessage.category">
                                Category
                              </span>
                          </div>
                          <ng-select class="w-100 custom-sm"
                                    [items]="urgenciesForChannel[channelIds.sitatexId]"
                                    bindLabel="nameEnglish"
                                    bindValue="id"
                                    name="urgencySitatex"
                                    id="urgencySitatex"
                                    [(ngModel)]="message.urgencyIds[channelIds.sitatexId]"
                                    [required]="message.recipentObjectList[channelIds.sitatexId].length > 0"
                                    [disabled]="!checkFolderRuleIsFull()
                                                || urgenciesForChannel[channelIds.sitatexId].length == null
                                                || !isNewMessage && message.direction == 0
                                                || !isNewMessage && message.direction == 1 && !message.errored
                                                || sendersForChannels[channelIds.sitatexId].length == 0">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Email -->
              <div class="channel card mb-2 p-2"
                  [ngStyle]="{'display': messagePanel=='small' && activeChannel !== channelIds.emailId ? 'none' : 'block'}"
                  [ngClass]="{'disabled': sendersForChannels[channelIds.emailId].length == 0}">
                <div class="row">
                  <div class="col-auto card-channel-name pl-4" *ngIf="messagePanel === 'full'">
                    <span *ngIf="message.recipentObjectList[channelIds.emailId].length > 0"
                          class="material-icons">
                      how_to_reg
                    </span>
                    Email
                  </div>
                  <div class="col">
                    <div class="card-channel-info row">
                      <div class="col-12 mb-2">
                        <div *ngIf="errorRecipientAddress[channelIds.emailId]"
                              class="invalid-feedback d-block float-left">
                          {{errorRecipientAddress[channelIds.emailId]}}
                        </div>
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - recipient@@cmn.modalMessage.recipient">
                                Recipient
                              </span>
                          </div>
                          <ng-select [items]="contactsForChannels[channelIds.emailId]"
                                     *ngIf="!recipientLoadAnimation; else recipientLoad"
                                     class="w-100 custom-sm"
                                     bindLabel="name"
                                     bindValue="id"
                                     name="recipientListEmail"
                                     id="recipientListEmail"
                                     groupBy="addresses"
                                     [addTag]="addTagContactRefernaceEmail"
                                     (blur)="addTagContactFromArray(selectEnteredData.email, channelIds.emailId)"
                                     (clear)="onClearFilterInput('email')"
                                     (remove)="onClearTagFilterInput($event, 'email')"
                                     (search)="onSearchFilterInput($event, 'email')"
                                     [multiple]="true"
                                     [hideSelected]="true"
                                     [closeOnSelect]="false"
                                     [clearSearchOnAdd]="true"
                                     [selectableGroup]="true"
                                     [selectableGroupAsModel]="false"
                                     [(ngModel)]="message.recipentObjectList[channelIds.emailId]"
                                     (close)="recipientListClose()"
                                     (add)="onAddContactRefernace($event)"
                                     [required]="message.recipentObjectList[channelIds.aftnId].length < 1 && message.recipentObjectList[channelIds.sitatexId].length < 1"
                                     [disabled]="sendersForChannels[channelIds.emailId].length == 0">
                            <ng-template ng-optgroup-tmp let-item="item">
                              {{item.name || 'Other addresses'}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                              {{item.name}}
                            </ng-template>
                            <ng-template ng-tag-tmp let-search="searchTerm">
                              <b>
                                <span i18n="cmn|Modal message detail - add user email address@@cmn.modalMessage.addUserEmailAddress">Add user address:</span>
                              </b>&nbsp;{{search}}
                            </ng-template>
                          </ng-select>
                          <ng-template #recipientLoad>
                            <div class="d-flex align-items-center py-0 pl-2 bg-transperante rounded-right w-100 border border-left-0">
                              <div class="spinner-border spinner-border-sm float-left" role="status" aria-hidden="true"></div>
                              <div class="float-left pl-2"
                                   i18n="cmn|Modal message detail - recipient pasing message@@cmn.modalMessage.recipientParsingMessage">
                                Parsing an address string...
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                      <div class="col-4 pr-0" *ngIf="!isNewMessage">
                        <div class="input-group">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - at date@@cmn.modalMessage.atDate">
                                Date
                              </span>
                          </div>
                          <input type="time"
                                class="custom-sm form-control"
                                [ngModel]="message.dtMessage | date: 'HH:mm' : 'UTC+0'"
                                (input)="message.dtMessage = parseDateTime(start_datedtMessage.value, $event.target.value)"
                                name="start_timedtMessage"
                                [disabled]="!checkFolderRuleIsFull()
                                            || (!isNewMessage && !message.errored)
                                            || sendersForChannels[channelIds.emailId].length == 0"
                                #start_timedtMessage>
                          <input type="date"
                                class="custom-sm form-control"
                                [ngModel]="message.dtMessage | date: 'yyyy-MM-dd' : 'UTC+0'"
                                (input)="message.dtMessage = parseDateTime($event.target.value, start_timedtMessage.value)"
                                name="start_datedtMessage"
                                [disabled]="!checkFolderRuleIsFull()
                                            || (!isNewMessage && !message.errored)
                                            || sendersForChannels[channelIds.emailId].length == 0"
                                #start_datedtMessage>
                        </div>
                      </div>
                      <div class="col-6 pr-0">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - sender@@cmn.modalMessage.sender">
                                Sender
                              </span>
                          </div>
                          <ng-select class="w-100 custom-sm"
                                     [items]="sendersForChannels[channelIds.emailId]"
                                     bindLabel="addressEnglish"
                                     bindValue="addressEnglish"
                                     name="emailSender"
                                     id="emailSender"
                                     [(ngModel)]="message.senders[channelIds.emailId]"
                                     [required]="message.recipentObjectList[channelIds.emailId].length > 0"
                                     [disabled]="sendersForChannels[channelIds.emailId].length == 0">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - subject@@cmn.modalMessage.subject">
                                Subject
                              </span>
                          </div>
                          <input id="subject"
                              name="subject"
                              type="text"
                              class="w-100 form-control custom-sm"
                              [(ngModel)]="message.subject"
                              value="{{message.subject}}"
                              [disabled]="!checkFolderRuleIsFull()
                                          || (!isNewMessage && !message.errored)
                                          || sendersForChannels[channelIds.emailId].length == 0">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #notNewMessage>
            <div class="card mb-2 p-2"
                  [ngStyle]="{'display': messagePanel=='small' && activeChannel !== channelIds.aftnId ? 'none' : 'block'}">
                <div class="row">
                  <div class="col-auto card-channel-name pl-4" *ngIf="messagePanel === 'full'">
                    {{ message.channelName }}
                  </div>
                  <div class="col">
                    <div class="card-channel-info row">
                      <div class="col-12 mb-2">
                        <div *ngIf="errorRecipientAddress[message.channelId]"
                              class="invalid-feedback d-block float-left">
                          {{errorRecipientAddress[message.channelId]}}
                        </div>
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - recipient@@cmn.modalMessage.recipient">
                                Recipient
                              </span>
                          </div>
                          <ng-select [items]="contactsForChannels[message.channelId]"
                                    *ngIf="!recipientLoadAnimation; else recipientLoad"
                                    class="w-100 custom-sm"
                                    bindLabel="name"
                                    bindValue="id"
                                    name="recipientList1"
                                    groupBy="addresses"
                                    [addTag]="addTagContactRefernace"
                                    [multiple]="true"
                                    [hideSelected]="true"
                                    [closeOnSelect]="false"
                                    [clearSearchOnAdd]="true"
                                    [selectableGroup]="true"
                                    [selectableGroupAsModel]="false"
                                    [(ngModel)]="message.recipentObjectList[message.channelId]"
                                    [disabled]="!checkFolderRuleIsFull()
                                                || message.channelId == null
                                                || !isNewMessage && !message.errored"
                                    (change)="changeRecipientList(message.channelId)"
                                    (close)="recipientListClose()"
                                    (add)="onAddContactRefernace($event)"
                                    required>
                            <ng-template ng-optgroup-tmp let-item="item">
                              {{item.name || 'Other addresses'}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                              {{item.name}}
                            </ng-template>
                            <ng-template ng-tag-tmp let-search="searchTerm">
                              <b>
                                <span i18n="cmn|Modal message detail - add user recipient address@@cmn.modalMessage.addUserRecipientAddress">Add user address:</span>
                              </b>&nbsp;{{search}}
                            </ng-template>
                          </ng-select>
                          <ng-template #recipientLoad>
                            <div class="d-flex align-items-center py-1 pl-2 bg-white rounded">
                              <div class="spinner-border spinner-border-sm float-left" role="status" aria-hidden="true"></div>
                              <div class="float-left pl-2"
                                   i18n="cmn|Modal message detail - recipient pasing message@@cmn.modalMessage.recipientParsingMessage">
                                Parsing an address string...
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                      <div class="col-3 pr-0" *ngIf="message.channelId === channelIds.aftnId">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - number@@cmn.modalMessage.number">
                                Number
                              </span>
                          </div>
                          <input id="at"
                                name="at"
                                type="text"
                                class="w-100 custom-sm form-control form-control-md text-uppercase"
                                [ngModel]="message.dtMessage | date: 'ddHHmm' : 'UTC+0'"
                                (blur)="setDateMessage($event.target.value)"
                                [disabled]="!checkFolderRuleIsFull() ||
                                            urgenciesForChannel.length == null ||
                                            !isNewMessage && message.direction == 0 ||
                                            !isNewMessage && message.direction == 1 && !message.errored">
                        </div>
                      </div>
                      <div class="col-4 pr-0" *ngIf="message.channelId !== channelIds.aftnId">
                        <div class="input-group">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - at date@@cmn.modalMessage.atDate">
                                Date
                              </span>
                          </div>
                          <input type="time"
                                class="custom-sm form-control"
                                [ngModel]="message.dtMessage | date: 'HH:mm' : 'UTC+0'"
                                (input)="message.dtMessage = parseDateTime(start_datedtMessage.value, $event.target.value)"
                                name="start_timedtMessage"
                                #start_timedtMessage
                                disabled>
                          <input type="date"
                                class="custom-sm form-control"
                                [ngModel]="message.dtMessage | date: 'yyyy-MM-dd' : 'UTC+0'"
                                (input)="message.dtMessage = parseDateTime($event.target.value, start_timedtMessage.value)"
                                name="start_datedtMessage"
                                #start_datedtMessage
                                disabled>
                        </div>
                      </div>
                      <div class="pr-0" [ngClass]="{'col-3': message.channelId !== channelIds.emailId, 'col-4': message.channelId === channelIds.emailId}">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - sender@@cmn.modalMessage.sender">
                                Sender
                              </span>
                          </div>
                          <ng-container *ngIf="isNewMessage || message.direction == 1 && message.errored; else: senderText">
                            <ng-select *ngIf="message.channelId == channelIds.aftnId && selectedTelegramLanguage.label=='RU'; else senderNotAFTN"
                                       class="w-100 custom-sm"
                                       [items]="senders"
                                       bindLabel="addressLocal"
                                       bindValue="addressLocal"
                                       name="sender"
                                       id="sender"
                                       [(ngModel)]="message.sender"
                                       [disabled]="!checkFolderRuleIsFull() || (!folderSetting.senderId && message.channelId == undefined)"
                                       required>
                            </ng-select>
                            <ng-template #senderNotAFTN>
                              <ng-select class="w-100 custom-sm"
                                         [items]="senders"
                                         bindLabel="addressEnglish"
                                         bindValue="addressEnglish"
                                         name="sender"
                                         id="sender"
                                         [(ngModel)]="message.sender"
                                         [disabled]="!checkFolderRuleIsFull() || (!folderSetting.senderId && (message.channelId == 0 || message.channelId == undefined))"
                                         required>
                              </ng-select>
                            </ng-template>
                          </ng-container>
                          <ng-template #senderText>
                            <input type="text" id="sender" name="sender" class="form-control w-100 custom-sm"
                              [(ngModel)]="message.sender" disabled />
                          </ng-template>
                        </div>
                      </div>
                      <div class=" col-3 pr-0" *ngIf="message.channelId != channelIds.emailId">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - category@@cmn.modalMessage.category">
                                Category
                              </span>
                          </div>
                          <ng-select *ngIf="message.channelId == channelIds.aftnId && selectedTelegramLanguage.label == 'RU'; else: categoryNotAFTN"
                                     class="w-100 custom-sm"
                                     [items]="urgenciesForChannel[message.channelId]"
                                     bindLabel="nameLocal"
                                     bindValue="id"
                                     name="category"
                                     id="category"
                                     [(ngModel)]="message.urgencyId"
                                     [disabled]="!checkFolderRuleIsFull()
                                                || urgenciesForChannel.length == null
                                                || !isNewMessage && message.direction == 0
                                                || !isNewMessage && message.direction == 1 && !message.errored">
                          </ng-select>
                          <ng-template #categoryNotAFTN>
                            <ng-select class="w-100 custom-sm"
                                       [items]="urgenciesForChannel[message.channelId]"
                                       bindLabel="nameEnglish"
                                       bindValue="id"
                                       name="category"
                                       id="category"
                                       [(ngModel)]="message.urgencyId"
                                       [disabled]="!checkFolderRuleIsFull()
                                                  || urgenciesForChannel.length == null
                                                  || !isNewMessage && message.direction == 0
                                                  || !isNewMessage && message.direction == 1 && !message.errored">
                            </ng-select>
                          </ng-template>
                        </div>
                      </div>
                      <div class="col-4" *ngIf="message.channelId == channelIds.emailId">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - subject@@cmn.modalMessage.subject">
                                    Subject
                              </span>
                          </div>
                          <input id="subject"
                                 name="subject"
                                 type="text"
                                 class="form-control w-100 custom-sm"
                                 [(ngModel)]="message.subject"
                                 value="{{message.subject}}"
                                 [disabled]="!checkFolderRuleIsFull() || (!isNewMessage && !message.errored)">
                        </div>
                      </div>
                      <div class="col-3" *ngIf="message.channelId == channelIds.aftnId">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0"
                                    i18n="cmn|Modal message detail - telegram language@@cmn.modalMessage.buttons.telegramLanguage">
                                Telegram language
                              </span>
                          </div>
                          <ng-select *ngIf="message.channelId == channelIds.aftnId"
                                    class="w-100 custom-sm"
                                    [items]="defaultTelegramLanguageBindingsList"
                                    [clearable]="false" [clearOnBackspace]="false" [searchable]="false"
                                    [(ngModel)]="selectedTelegramLanguage"
                                    name="selectedTelegramLanguage" id="selectedTelegramLanguage"
                                    [disabled]="!checkFolderRuleIsFull() || (!isNewMessage && !message.errored)"
                                    (close)="onChangeTelegramLanguage($event)">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </ng-template>
          
          <div class="row">
            <div class="col-10 mt-1 font-small">
              <ng-container *ngIf="message.readConfirmations">
                <div>
                  <span i18n="cmn|Modal message detail - processed@@cmn.modalMessage.processed">Processed:</span>&nbsp;<span
                        *ngFor="let reader of message.readConfirmations; let i = index"
                        [ngClass]="{'font-weight-bold': !checkRead(reader.time)}">{{reader.folder}}<span
                          *ngIf="checkRead(reader.time)">&nbsp;<span
                            i18n="cmn|Modal message detail - processed at@@cmn.modalMessage.processedAt">at</span>&nbsp;
                      <ng-container *ngIf="checkToday(reader.time)">{{reader.time | date: 'HH:mm' : 'UTC+0'}}</ng-container>
                      <ng-container *ngIf="!checkToday(reader.time)">{{reader.time | date: 'HH:mm dd.MM.yyyy' : 'UTC+0'}}</ng-container>
                    </span><span *ngIf="message.readConfirmations.length!=i+1">; </span>
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="message.errored">
                <div class="text-red d-flex">
                  <i class="material-icons text-red mr-2">warning</i>
                  <span>
                    <span i18n="cmn|Modal message detail - not sent@@cmn.modalMessage.notSent">Not sent:</span>
                    {{message.errorString}}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="isNewMessage">
                <div class="d-flex text-dark-gray">
                  <!-- Только для АФТН есть разница между РУС/ЛАТ сообщением в 2 символа
                       по этому, если есть получители АФТН, передаем текущий язык
                       сообщения, в остальных случаях считаем длину нормально
                  -->
                  <span i18n="cmn|Modal message detail - message length@@cmn.modalMessage.messageLength">Message length:</span>&nbsp;<span
                        [ngClass]="(message.textLength(message.recipentObjectList[channelIds.aftnId].length > 0 ? true : false) > globalSettings.aftnMaxMessageLength && message.recipentObjectList[channelIds.aftnId].length > 0) ? 'font-weight-bold text-red' : 'text-dark-gray'">{{message.textLength(message.recipentObjectList[channelIds.aftnId].length > 0 ? true : false)}}</span>
                  <ng-container *ngIf="message.recipentObjectList[channelIds.aftnId].length > 0">&nbsp;<span
                          i18n="cmn|Modal message detail - message length out of@@cmn.modalMessage.messageLength.outOf">out
                      of</span>&nbsp;{{globalSettings.aftnMaxMessageLength}}&nbsp;<span
                          i18n="cmn|Modal message detail - message length characters@@cmn.modalMessage.messageLength.characters">characters</span>
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <div class="col-2 text-right">
              <!--<ng-container *ngIf="messagePanel=='small'">
                <button type="button"
                        class="btn btn-xs btn-blue mr-2"
                        ngbTooltip="Load template"
                        (click)="loadTemplates()"
                        [disabled]="!checkFolderRuleIsFull() || (!isNewMessage && !message.errored)"
                        i18n-ngbTooltip="cmn|Modal message detail - small panel load template@@cmn.modalMessage.smallPanel.loadTemplate">
                  <i class="material-icons">library_books</i>
                </button>

                <button type="button"
                        class="btn btn-xs btn-blue mr-2"
                        ngbTooltip="Save template"
                        (click)="userAction = template.id ? 'updateTemplate' : 'saveTemplate'"
                        [disabled]="!checkFolderRuleIsFull()"
                        i18n-ngbTooltip="cmn|Modal message detail - small panel save template@@cmn.modalMessage.smallPanel.saveTemplate">
                  <i class="material-icons">save</i>
                </button>

                <div class="btn-group btn-group-toggle select-message-view mr-2" data-toggle="buttons">
                  <label class="btn btn-xs font-small active bg-green text-white"
                         ngbTooltip="Text"
                         i18n-ngbTooltip="cmn|Modal message detail - small panel text view@@cmn.modalMessage.smallPanel.textView">
                    <input type="radio"
                           name="options"
                           id="text"
                           checked>
                    <i class="material-icons">create</i>
                  </label>
                  <label class="btn btn-xs font-small btn-light-gray border disabled"
                         ngbTooltip="Form"
                         i18n-ngbTooltip="cmn|Modal message detail - small panel form view@@cmn.modalMessage.smallPanel.formView">
                    <input type="radio"
                           name="options"
                           id="form"
                           disabled>
                    <i class="material-icons">assignment</i>
                  </label>
                </div>
              </ng-container>-->
              <button type="button" *ngIf="isNewMessage"
                      class="btn btn-xs btn-ico btn-medium-gray float-right"
                      (click)="changeMessageView()"
                      ngbTooltip="Header show/hide"
                      i18n-ngbTooltip="cmn|Modal message detail - small panel show hide header@@cmn.modalMessage.smallPanel.showHideHeader">
                <i class="material-icons"> {{messagePanel=='small' ? 'arrow_drop_down' : 'arrow_drop_up'}}</i>
              </button>
            </div>
          </div>
        </div>

        <div class="modal-body bg-white border-bottom-2px p-0">
          <div class="validation-textarea">
            <!--<textarea *ngIf="message.recipentObjectList[channelIds.aftnId].length === 0"
                      class="form-control h-100 border-0 p-4"
                      id="validationTextarea"
                      name="validationTextarea"
                      placeholder="Enter message text..."
                      rows={{getRowsForTextArea()}}
                      required
                      [(ngModel)]="message.message"
                      [disabled]="!checkFolderRuleIsFull() || (!isNewMessage && !message.errored)"
                      i18n-placeholder="cmn|Modal message detail - field text@@cmn.modalMessage.fields.textPlaceholder"
                      [ngStyle]="{'font-size.px': textSize}">
              {{message.message}}
            </textarea>-->
            <div *ngIf="getRowsForTextArea() == 11" style="width: 100%; height: 9px;"></div>
            <textarea class="form-control h-100 text-uppercase border-0 p-4 text-monospace"
                      id="validationTextarea"
                      name="validationTextarea"
                      placeholder="Enter message text..."
                      rows={{getRowsForTextArea()}}
                      required
                      (input)="message.message = splitIntoLines($event.target.value, $event.target)"
                      [(ngModel)]="message.message"
                      [disabled]="!checkFolderRuleIsFull() || (!isNewMessage && !message.errored)"
                      i18n-placeholder="cmn|Modal message detail - field text@@cmn.modalMessage.fields.textPlaceholder"
                      style="resize: none;"
                      [ngStyle]="{'font-size.px': textSize}">
              {{message.message}}
            </textarea>
            <div class="check-error">
              <div>
                <div *ngFor="let line of verifiedMessage" class="check-error-line">
                  <div class="has-error text-center">
                    <i *ngIf="line[0]" placement="top" ngbTooltip="{{line[2]}}"
                      class="material-icons text-red">error</i>
                  </div>
                  <div class="text-line text-uppercase">{{line[1]}}</div>
                </div>
              </div>
            </div>
          </div>
          <!--<ng-template #messageTemplate>
            <div class="message-template-wrap">
              <div class="row">
                <div class="col-8 message-template">
                  <div class="input-group">
                    <div *ngFor="let part of template.parts let i = index">
                      <input *ngIf="part.type=='static'"
                             type="text"
                             disabled
                             name="part_{{i}}"
                             id="part_{{i}}"
                             [(ngModel)]="textMessageTemplate[i]"
                             [attr.size]="part.value.length*2">
                      <input *ngIf="part.type=='dynamic' && part.format == 'string'"
                             type="text"
                             name="part_{{i}}"
                             id="part_{{i}}"
                             [attr.size]="part.maxLength*2"
                             minlength={{part.minLength}}
                             maxlength={{part.maxLength}}
                             [required]="part.required==true"
                             [(ngModel)]="textMessageTemplate[i]"
                             (focus)="selectTemplateInput(i)"
                             (focusout)="selectTemplateInputNone()">
                      <input *ngIf="part.type=='dynamic' && part.format == 'number'"
                             type="text"
                             name="part_{{i}}"
                             id="part_{{i}}"
                             pattern="^[0-9]{0,}$"
                             [attr.size]="part.maxLength*2"
                             minlength={{+part.minLength}}
                             maxlength={{+part.maxLength}}
                             [required]="part.required==true"
                             [(ngModel)]="textMessageTemplate[i]"
                             (focus)="selectTemplateInput(i)"
                             (focusout)="selectTemplateInputNone()">
                      <input *ngIf="part.type=='dynamic' && part.format == 'date'"
                             type="text"
                             name="part_{{i}}"
                             id="part_{{i}}"
                             pattern={{patternDate(part.dateFormat)}}
                             [attr.size]="part.dateFormat.length*2"
                             minlength={{part.dateFormat.length}}
                             maxlength={{part.dateFormat.length}}
                             [required]="part.required==true"
                             [(ngModel)]="textMessageTemplate[i]"
                             (focus)="selectTemplateInput(i)"
                             (focusout)="selectTemplateInputNone()">
                    </div>
                  </div>
                </div>
                <div class="col-4 border-left" style="min-height: 195px;">
                  <div *ngIf="selectedTemplateInput == null; else tooltipFields">
                    {{template.description}}
                  </div>
                  <ng-template #tooltipFields>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <span class="input-group-text font-small">Format</span>
                      </div>
                      <input type="text"
                             id="tooltipFormat"
                             name="tooltipFormat"
                             class="form-control"
                             value="{{selectedTemplateInput.format}}">
                    </div>
                    <div class="input-group mb-2"
                      *ngIf="selectedTemplateInput.minLength > 0 || selectedTemplateInput.maxLength > 0">
                      <div class="input-group-prepend">
                        <span class="input-group-text font-small">Min Length</span>
                      </div>
                      <input type="text"
                             id="tooltipMinLen"
                             name="tooltipMinLen"
                             class="form-control"
                             value="{{selectedTemplateInput.minLength}}">
                      <div class="input-group-prepend ml-2">
                        <span class="input-group-text font-small">Max Length</span>
                      </div>
                      <input type="text"
                             id="tooltipMaxLen"
                             name="tooltipMaxLen"
                             class="form-control"
                             value="{{selectedTemplateInput.maxLength}}">
                    </div>
                    <div class="input-group mb-2" *ngIf="selectedTemplateInput.dateFormat">
                      <div class="input-group-prepend">
                        <span class="input-group-text font-small">Date format</span>
                      </div>
                      <input type="text"
                             id="tooltipDateFormat"
                             name="tooltipDateFormat"
                             class="form-control"
                             value="{{selectedTemplateInput.dateFormat}}">
                    </div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <span class="input-group-text font-small">Hint</span>
                      </div>
                      <input type="text"
                             id="tooltipDateFormat"
                             name="tooltipDateFormat"
                             class="form-control"
                             value="{{selectedTemplateInput.hint}}">
                    </div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <span class="input-group-text font-small">Required</span>
                      </div>
                      <input type="text"
                             id="tooltipRequiredt"
                             name="tooltipRequiredt"
                             class="form-control"
                             value="{{selectedTemplateInput.required}}">
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-template>-->
        </div>
        <div class="bg-light-gray p-3">
          <div class="row">
            <div class="col-auto">
              <div class="input-group input-group-sm">
                <button type="button"
                        class="btn btn-xs btn-ico btn-light-gray mr-2 border"
                        [ngClass]="{'active': textSize==availableTextSizes.Small}"
                        style="font-size: 10px;"
                        (click)="setTextSize(availableTextSizes.Small)">
                  S
                </button>
                <button type="button"
                        class="btn btn-xs btn-ico btn-light-gray mr-2 border"
                        [ngClass]="{'active': textSize==availableTextSizes.Medium}"
                        style="font-size: 14px;"
                        (click)="setTextSize(availableTextSizes.Medium)">
                  M
                </button>
                <button type="button"
                        class="btn btn-xs btn-ico btn-light-gray mr-2 border"
                        [ngClass]="{'active': textSize==availableTextSizes.Large}"
                        style="font-size: 18px;"
                        (click)="setTextSize(availableTextSizes.Large)">
                  L
                </button>

                <!--<div *ngIf="message.channelId == channelIds.aftnId" class="input-group-prepend">
                  <label class="input-group-text"
                    i18n="cmn|Modal message detail - telegram language@@cmn.modalMessage.buttons.telegramLanguage">
                    Telegram language
                  </label>
                </div>
                <ng-select *ngIf="message.channelId == channelIds.aftnId" [items]="defaultTelegramLanguageBindingsList"
                  [clearable]="false" [clearOnBackspace]="false" [searchable]="false"
                  [(ngModel)]="selectedTelegramLanguage" name="selectedTelegramLanguage" id="selectedTelegramLanguage"
                  (close)="onChangeTelegramLanguage($event)">
                </ng-select>-->

                <!--<div class="ml-2">
                  <button *ngIf="!isNewMessage && message.direction !== 1 && selectedFolder != 0 && message.direction !== 2"
                          type="button"
                          class="btn btn-xs btn-blue float-left"
                          (click)="reparseMessage(modal.dismiss)"
                          [disabled]="!checkFolderRuleIsFull()">
                    <i class="material-icons">how_to_vote</i>
                    <span
                      i18n="cmn|Modal message detail - button save and reprocess@@cmn.modalMessage.buttons.saveReprocess">
                      Save and reprocess
                    </span>
                  </button>
                </div>-->
                
                <button type="button"
                        class="btn btn-xs btn-blue mr-2"
                        (click)="selectTemplate()"
                        [disabled]="!isNewMessage || selectedFolder == -1">
                  <i class="material-icons">library_books</i>
                  <span
                    i18n="cmn|Modal message detail - button load template@@cmn.modalMessage.buttons.loadTemplate">
                    Load template
                  </span>
                </button>
                
                <button type="button"
                        class="btn btn-xs btn-blue"
                        (click)="modalSaveTemplate();"
                        [disabled]="!checkFolderRuleIsFull() || selectedFolder == -1">
                  <i class="material-icons">save</i>
                  <span
                    i18n="cmn|Modal message detail - button save template@@cmn.modalMessage.buttons.saveTemplate">
                    Save template
                  </span>
                </button>
                
                <!--<div class="btn-group btn-group-toggle select-message-view w-100" data-toggle="buttons">
                    <label class="btn font-small active bg-green text-white">
                      <input type="radio" name="options" id="text" checked>
                      <i class="material-icons">create</i>
                      <span i18n="cmn|Modal message detail - button text@@cmn.modalMessage.buttons.text">Text</span>
                    </label>
                    <label class="btn font-small btn-light-gray border disabled">
                      <input type="radio" name="options" id="form" disabled>
                      <i class="material-icons">assignment</i>
                      <span i18n="cmn|Modal message detail - button form@@cmn.modalMessage.buttons.form">Form</span>
                    </label>
                  </div>-->
                  
              </div>
            </div>
            <div class="col text-right">
              <button *ngIf="!isNewMessage && message.direction==1 && message.errored" type="button"
                class="btn btn-xs btn-blue px-3 mr-2" (click)="reSendMessage(modal.dismiss, modalMessage)">
                <i class="material-icons">send</i>
                <span
                  i18n="cmn|Modal message detail - button send@@cmn.modalMessage.buttons.send">
                  Send
                </span>
              </button>
              <!-- TODO отображение этой кнопки закрыто предупреждением что неьля отсылать из общей папки, проверит, удалить -->
              <button *ngIf="selectedFolder == -1" type="button" class="btn btn-xs btn-blue px-3 mr-2"
                (click)="sendMessage(modal.dismiss, modalMessage, message.folderId)">
                <i class="material-icons">send</i>
                <span i18n="cmn|Modal message detail - button send@@cmn.modalMessage.buttons.send">
                  Send
                </span>
              </button>
              <button *ngIf="selectedFolder == -1" type="button" class="btn btn-xs btn-red px-3 mr-2"
                (click)="userAction = 'deleteDraft'">
                <i class="material-icons">delete</i>
                <span i18n="cmn|Modal message detail - button delete@@cmn.modalMessage.buttons.delete">
                  Delete
                </span>
              </button>
              <button type="button"
                      *ngIf="isNewMessage && selectedFolder != -1 &&
                             (sendersForChannels[channelIds.aftnId].length != 0 ||
                             sendersForChannels[channelIds.sitatexId].length != 0 ||
                             sendersForChannels[channelIds.emailId].length != 0)"
                      class="btn btn-xs btn-blue px-3 mr-2"
                      (click)="sendMessage(modal.dismiss, modalMessage)">
                <i class="material-icons">send</i>
                <span i18n="cmn|Modal message detail - button send@@cmn.modalMessage.buttons.send">
                  Send
                </span>
              </button>
              <button type="button" class="btn btn-xs btn-gray-blue-dark px-4" (click)="closeModalMessage(modal.dismiss)">
                <span i18n="cmn|Modal message detail - button close@@cmn.modalMessage.buttons.close">
                  Close
                </span>
              </button>
            </div>
          </div>
        </div>
        
        <div *ngIf="userAction === 'deleteDraft'" class="ms-modal-message">
          <div class="modal-content">
            <div class="modal-header bg-warning">
              <h5 class="modal-title"
                i18n="cmn|Modal message error - header watning@@cmd.main.modalMessage.errMes.header.warn">
                Warning
              </h5>
              <button type="button" class="close" (click)="userAction = ''" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body"
              i18n="cmn|Modal message error - body delete draft@@cmd.main.modalMessage.errMes.body.deleteDraft">
              Delete draft?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-blue"
                (click)="deleteDraft(message.id, message.folderId, modal.dismiss)"
                i18n="cmn|Modal confirm message - button yes@@cmn.modalConfirmMessage.buttons.yes">
                Yes
              </button>
              <button type="button" class="btn btn-sm btn-secondary" (click)="userAction = ''"
                i18n="cmn|Modal confirm message - button no@@cmn.modalConfirmMessage.buttons.no">
                No
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="userAction == 'invalidDate'" class="ms-modal-message">
          <div class="modal-content">
            <div class="modal-header bg-warning">
              <h5 class="modal-title"
                i18n="cmn|Modal message error - header watning@@cmd.main.modalMessage.errMes.header.warn">
                Warning
              </h5>
              <button type="button" class="close" (click)="userAction = ''" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body"
              i18n="cmn|Modal message error - body delete draft@@cmn.app.message.invalidDate">
              The message was created more than 1 hour ago, check the relevance of the data sent
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-blue"
                (click)="setDataAndSent(modal.dismiss, modalMessage)"
                i18n="cmn|Modal confirm message - button send@@cmn.modalConfirmMessage.buttons.send">
                Send
              </button>
              <button type="button" class="btn btn-sm btn-gray-blue-dark" (click)="userAction = ''"
                i18n="cmn|Modal confirm message - button edit@@cmn.modalConfirmMessage.buttons.edit">
                Edit
              </button>
            </div>
          </div>
        </div>

        
        
        <div *ngIf="errorMessage !== ''" class="ms-modal-message zindex-tooltip">
          <div class="modal-content w-50">
            <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                          'bg-warning': errorType == 'warning',
                                          'bg-danger': errorType == 'error'}">
              <h5 *ngIf="errorType == 'info'" class="modal-title"
                i18n="cmn|Modal message error - header information@@cmd.main.modalMessage.errMes.header.inf">
                Information
              </h5>
              <h5 *ngIf="errorType == 'warning'" class="modal-title"
                i18n="cmn|Modal message error - header watning@@cmd.main.modalMessage.errMes.header.warn">
                Warning
              </h5>
              <h5 *ngIf="errorType == 'error'" class="modal-title"
                i18n="cmn|Modal message error - header error@@cmd.main.modalMessage.errMes.header.err">
                Error
              </h5>
              <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <span style="white-space: pre-line">{{errorMessage}}</span>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-blue" *ngIf="userAction==='close'"
                (click)="saveDraft(modal.dismiss)"
                i18n="cmn|Modal message error - button yes@@cmd.main.modalMessage.errMes.button.yes">
                Yes
              </button>
              <button type="button" class="btn btn-sm btn-red" *ngIf="userAction==='close'"
                (click)="closeWithoutSaving(modal.dismiss)"
                i18n="cmn|Modal message error - button no@@cmd.main.modalMessage.errMes.button.no">
                No
              </button>
              <button type="button" class="btn btn-sm btn-gray-blue-dark" *ngIf="userAction==='close'"
                (click)="clearErrorMess()"
                i18n="cmn|Modal message error - button cancel@@cmd.main.modalMessage.errMes.button.cancel">
                Cancel
              </button>

              <button type="button" class="btn btn-sm btn-blue" *ngIf="userAction==='noValid'"
                (click)="clearErrorMess()"
                i18n="cmn|Modal message error - button edit@@cmd.main.modalMessage.errMes.button.edit">
                Edit
              </button>

              <button type="button" class="btn btn-sm btn-gray-blue-dark" *ngIf="userAction==='translate'"
                (click)="clearErrorMess()"
                i18n="cmn|Modal message error - button close@@cmd.main.modalMessage.errMes.button.close">
                Close
              </button>

              <button type="button" class="btn btn-sm btn-gray-blue-dark" *ngIf="userAction==='replaced'"
                (click)="clearErrorMess()"
                i18n="cmn|Modal message error - button ok@@cmd.main.modalMessage.errMes.button.ok">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

    <!-- Модалка сохранения фильтра -->
    <div *ngIf="userAction == 'saveFilter' || userAction == 'updateFilter'" class="ms-modal-message">
      <div class="modal-content w-25">
        <div class="modal-header">
          <h5 class="modal-title"
              i18n="cmn|Modal filter - header save filter@@cmn.modalFilter.header.saveFilter">
            Save filter
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="userAction = ''">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p *ngIf="userAction == 'updateFilter'">
            <span i18n="cmn|Modal filter - header save filter as@@cmn.modalFilter.header.saveFilterAs">Save the filter as a</span>&nbsp;{{selectedFilter.name}}?
          </p>
          <form *ngIf="userAction == 'saveFilter'">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text font-small" i18n="cmn|Modal filter - search@@cmn.modalFilter.search">
                  Name
                </span>
              </div>
              <input type="text"
                     class="form-control"
                     name="search"
                     id="search"
                     placeholder="Enter filter name"
                     [(ngModel)]="selectedFilter.name"
                     required
                     i18n-placeholder="cmn|Modal filter - search placeholder@@cmn.modalFilter.searchPlaceholder">
            </div>
          </form>
        </div>
        <div class="modal-footer text-right bg-light-gray">
          <button type="button"
                  *ngIf="userAction == 'saveFilter'"
                  class="btn btn-blue btn-sm"
                  (click)="saveFilter()"
            i18n="cmn|Modal filter - button save@@cmn.modalFilter.buttons.save">
            Save
          </button>
          <button type="button"
                  *ngIf="userAction == 'updateFilter'"
                  class="btn btn-yellow btn-sm float-left"
            (click)="saveFilterAsNew()"
            i18n="cmn|Modal filter - button save as new@@cmn.modalFilter.buttons.saveAsNew">
            Save as new
          </button>
          <button type="button"
                  *ngIf="userAction == 'updateFilter'"
                  class="btn btn-blue btn-sm"
                  (click)="updateFilter(selectedFilter.id)"
                  i18n="cmn|Modal filter - button save@@cmn.modalFilter.buttons.save">
            Save
          </button>
          <button type="button" class="btn btn-gray-blue-dark btn-sm" (click)="userAction=''"
            i18n="cmn|Modal filter - button save@@cmn.modalFilter.buttons.cancel">
            Close
          </button>
        </div>
      </div>
    </div>

  <!-- Модалка сохранения шаблона -->
  <div *ngIf="userAction == 'saveTemplate' || userAction == 'updateTemplate'" class="ms-modal-message">
    <div class="modal-content w-25">
      <div class="modal-header">
        <h5 class="modal-title"
            i18n="cmn|Modal template - header save template@@cmn.modalTemplate.header.saveTemplate">
          Save template
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="userAction = ''">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="userAction == 'updateTemplate'">
          <ng-container>
            <span i18n="cmn|Modal template - body save template as@@cmn.modalTemplate.body.saveTemplateAs">Save the template as a</span>&nbsp;{{template.name}}?
          </ng-container>
        </p>
        <form *ngIf="userAction == 'saveTemplate'">
          <span class="invalid-feedback d-block float-left"
            *ngIf="template.name!=='' && !validTemplateName"
            i18n="cmn|Modal template - body save template exists@@cmn.modalTemplate.body.saveTemplateExists">
            A template with the same name already exists.
          </span>
          <input type="text"
                 class="form-control"
                 name="search"
                 id="search"
                 placeholder="Enter template name"
                 [(ngModel)]="template.name"
                 (input)="inputTemplateName($event.target.value)"
                 required
                 i18n-placeholder="cmn|Modal template - search placeholder@@cmn.modalTemplate.searchPlaceholder">
        </form>
      </div>
      <div class="modal-footer text-right bg-light-gray">
        <button type="button" *ngIf="userAction == 'saveTemplate'"
          class="btn btn-blue btn-sm" (click)="saveTemplate()"
          [disabled]="!validTemplateName"
          i18n="cmn|Modal template - button save@@cmn.modalTemplate.buttons.save">
          Save
        </button>
        <button type="button" *ngIf="userAction == 'updateTemplate'" class="btn btn-yellow btn-sm float-left"
          (click)="userAction = 'saveTemplate'"
          i18n="cmn|Modal template - button save as new@@cmn.modalTemplate.buttons.saveAsNew">
          Save as new
        </button>
        <button type="button" *ngIf="userAction == 'updateTemplate'" class="btn btn-blue btn-sm"
          (click)="updateTemplate(template.id)" i18n="cmn|Modal template - button save@@cmn.modalTemplate.buttons.save">
          Save
        </button>
        <button type="button" class="btn btn-gray-blue-dark btn-sm" (click)="userAction = ''"
          i18n="cmn|Modal template - button save@@cmn.modalTemplate.buttons.cancel">
          Close
        </button>
      </div>
    </div>
  </div>

  <!-- Модалка выбора шаблона -->
  <div *ngIf="showModal == 'template'" class="ms-modal-message">
    <div class="modal-content w-75 h-75">
      <div class="modal-header">
        <h5 class="modal-title"
          i18n="cmn|Modal template - header select template@@cmn.modalTemplate.header.selectTemplate">
          Select template
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="showModal = null">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="px-4 py-2">
        <div class="row">
          <div class="col-4"></div>
          <div class="col-8">
            <div class="input-group referance-filter mb-2" *ngIf="!replaceMode.on">
              <div class="input-group-prepend">
                <span class="input-group-text font-small text-center"
                      i18n="cmn|Modal template - search@@cmn.modalTemplate.search">
                  Search
                </span>
              </div>
              <div class="position-relative">
                <input type="text" aria-label="Name"
                      class="form-control rounded-0 border-right-0"
                      placeholder="Name"
                      name="searchTemplateName"
                      id="searchTemplateName"
                      [(ngModel)]="searchTemplate.name"
                      i18n-placeholder="cmn|Modal template - field search name@@cmn.modalTemplate.fieldSearchName">
                  <span class="clear-filter" (click)="searchTemplate.name=''">&times;</span>
              </div>
              <div class="position-relative">
                <input type="text" aria-label="Recipient"
                      class="form-control rounded-0 border-right-0"
                      placeholder="Recipient"
                      name="searchTemplateRecipient"
                      [(ngModel)]="searchTemplate.recipient"
                      i18n-placeholder="cmn|Modal template - field search recipient@@cmn.modalTemplate.fieldSearchRecipient">
                <span class="clear-filter" (click)="searchTemplate.recipient=''">&times;</span>
              </div>
              <div class="position-relative">
                <input type="text" aria-label="Text"
                      class="form-control rounded-0"
                      placeholder="Text"
                      name="searchTemplateText"
                      [(ngModel)]="searchTemplate.text"
                      i18n-placeholder="cmn|Modal template - field search text@@cmn.modalTemplate.fieldSearchText">
                <span class="clear-filter" (click)="searchTemplate.text=''">&times;</span>
              </div>
            </div>
          </div>
          <!---<input #templateSearchInput
                 type="text"
                 class="form-control"
                 name="searchTemplate"
                 id="searchTemplate"
                 placeholder="Enter template name"
                 [(ngModel)]="searchInput"
                 i18n-placeholder="cmn|Modal template - search placeholder@@cmn.modalTemplate.searchPlaceholder">--->
        </div>
      </div>
      <div class="modal-body">
        <div class="border" style="min-height: 200px;">
          <table class="w-100" *ngIf="templates.length > 0">
            <thead>
              <tr class="bg-blue color-white">
                <th class="form-check p-3" width="20px">
                  <input *ngIf="replaceMode.on"
                        name="checkAll"
                        type="checkbox"
                        id="checkAll"
                        class="form-check-input m-auto"
                        [checked]="replaceMode.replacingId.length === templates.length"
                        (click)="setReplacingAllTemplates()">
                </th>
                <th class="p-2" width="20%" i18n="cmn|Modal template - table column name@@cmn.modalTemplate.table.col.name">
                  Name
                </th>
                <th class="p-2" width="40%" i18n="cmn|Modal template - table column recipient@@cmn.modalTemplate.table.col.recipient">
                  Recipient
                </th>
                <th class="p-2" width="20%" i18n="cmn|Modal template - table column text@@cmn.modalTemplate.table.col.text">
                  Text
                </th>
                <th class="p-2" width="20%" i18n="cmn|Modal template - table column update@@cmn.modalTemplate.table.col.update">
                  Update
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let template of templates; let i = index" class="border-bottom">
                <td class="form-check p-3">
                  <input *ngIf="replaceMode.on"
                        name="check_{{i}}"
                        type="checkbox"
                        id="check_{{i}}"
                        class="form-check-input m-auto"
                        [value]="template.id"
                        [checked]="replaceMode.replacingId.includes(template.id)"
                        (click)="toggleId(replaceMode.replacingId, +template.id)">
                </td>
                <td class="p-2" valign="top">
                  {{  template.name }}
                </td>
                <td class="p-2" valign="top">
                  {{ sliseText(template.recipient, 600) }}
                </td>
                <td class="p-2">
                  {{ sliseText(template.message, 20) }}
                </td>
                <td class="p-2 d-flex align-items-center">
                  {{template.lastupdate | date: 'dd.MM.yyyy' : 'UTC+0'}}
                  <button type="button"
                          *ngIf="!replaceMode.on"
                          class="btn btn-blue btn-xs ml-2 px-4"
                          (click)="loadTemplate(template.id)">
                    <span i18n="cmn|Modal template - button open@@cmn.modalTemplate.button.open">Open</span>
                  </button>
                  <!--<button type="button"
                          *ngIf="!replaceMode.on"
                          class="btn btn-light-gray btn-xs ml-2 btn-ico">
                    <i class="material-icons">info</i>
                  </button>-->
                  <button type="button"
                          *ngIf="!replaceMode.on"
                          class="btn btn-red btn-xs btn-ico ml-2"
                          (click)="deleteTemplate(template.id)">
                    <i class="material-icons text-white">delete</i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <p *ngIf="templates.length == 0" class="text-center"
            i18n="cmn|Modal template - searche template not found@@cmn.modalTemplate.search.notFound">
            Not found
          </p>
        </div>
      </div>
      <div class="modal-footer bg-light-gray justify-content-between">
        <div class="w-100 border-bottom" *ngIf="replaceMode.on">
          <p class="font-weight-bold text-blue"
              i18n="cmn|Modal template - replace mode title@@cmn.modalTemplate.replaceModeTitle">
            Select row in tables to replace data
          </p>
          <div class="row">
            <div class="col-4">
              <p i18n="cmn|Modal template - replace mode options@@cmn.modalTemplate.replaceModeOptions">The options</p>
              <label class="d-block">
                <input type="radio"
                      class="mr-2"
                      (click)="replaceMode.option = 'recipient'"
                      name="replaceModeRecipient"
                      id="replaceModeRecipient"
                      value="recipient"
                      [checked]="replaceMode.option === 'recipient'">
                  <span i18n="cmn|Modal template - replacing recipient addresses@@cmn.modalTemplate.replacingRecipientAddresses">Replacing recipient addresses</span>
              </label>
              <label class="d-block">
                <input type="radio"
                      class="mr-2"
                      (click)="replaceMode.option = 'name'"
                      name="replaceModeName"
                      id="replaceModeName"
                      value="name"
                      [checked]="replaceMode.option === 'name'">
                  <span i18n="cmn|Modal template - replacing name@@cmn.modalTemplate.replacingName">Replacing the name</span>
                </label>
            </div>
            <div class="col-8">
              <label i18n="cmn|Modal template - replace text@@cmn.modalTemplate.replaceText">Replace text</label>
              <div class="row">
                <div class="col-6">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text font-small" i18n="cmn|Modal template - text from@@cmn.modalTemplate.textFrom">
                        From
                      </span>
                    </div>
                    <input type="text"
                          class="form-control"
                          name="textFrom"
                          id="textFrom"
                          [(ngModel)]="replaceMode.textFrom"
                          (input)="replaceMode.replacingId=[]">
                  </div>
                </div>
                <div class="col-6">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text font-small" i18n="cmn|Modal template - text to@@cmn.modalTemplate.textTo">
                        To
                      </span>
                    </div>
                    <input type="text"
                          class="form-control"
                          name="textTo"
                          id="textTo"
                          [(ngModel)]="replaceMode.textTo">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="input-group-prepend float-left">
          <div class="custom-control custom-switch d-flex align-items-center" style="min-width: fit-content;">
            <input type="checkbox"
                  class="custom-control-input"
                  id="replaceModeOn"
                  name="replaceModeOn"
                  [checked]="replaceMode.on"
                  (click)="checkReplaceMode(this.replaceMode.on)">
            <label class="custom-control-label" for="replaceModeOn" i18n="cmn|Modal template - replace mode@@cmn.modalTemplate.replaceMode">
              Replace mode
            </label>
          </div>
        </div>
        <div>
        <button type="button" *ngIf="replaceMode.on"
                class="btn btn-blue btn-sm mr-2"
                (click)="replaceInTemplates()"
                [disabled]="replaceMode.replacingId.length === 0 || replaceMode.textTo === ''"
                i18n="cmn|Modal template - button replace@@cmn.modalTemplate.buttons.replace">
          Replace
        </button>
        <button type="button" class="btn btn-gray-blue-dark btn-sm" (click)="showModal = null"
          i18n="cmn|Modal template - button close@@cmn.modalTemplate.buttons.close">
          Close
        </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Модалка привязки авиакомпании -->
  <div *ngIf="showModal == 'airline'" class="ms-modal-message">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"
          i18n="cmn|Modal airline - header select airlines@@cmn.modalAirline.header.selectAirline">
          Select airline
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="showModal = null">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 mb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text font-small" i18n="cmn|Modal airline - search@@cmn.modalAirline.search">
                  Search
                </span>
              </div>
              <input type="text"
                     class="form-control"
                     name="search"
                     id="search"
                     placeholder="Enter airline name or code"
                     [(ngModel)]="searchInput"
                     i18n-placeholder="cmn|Modal airline - search placeholder@@cmn.modalAirline.searchPlaceholder">
            </div>
          </div>
          <div class="col-12">
            <div class="border" style="min-height: 200px;">
              <table class="w-100" *ngIf="airlines.length > 0">
                <thead>
                  <tr class="border-bottom">
                    <th class="p-2"></th>
                    <th class="p-2" i18n="cmn|Modal airline - table column iata@@cmn.modalAirline.table.col.iata">
                      IATA
                    </th>
                    <th class="p-2" i18n="cmn|Modal airline - table column icao@@cmn.modalAirline.table.col.icao">
                      ICAO
                    </th>
                    <th class="p-2" i18n="cmn|Modal airline - table column zrt@@cmn.modalAirline.table.col.name">
                      NAME
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let airline of getAirlines()" class="border-bottom">
                    <td class="p-2">
                      <input type="checkbox" name="flight_id_{{airline.id}}" value="{{airline.id}}">
                    </td>
                    <td class="p-2">{{airline.iata}}</td>
                    <td class="p-2">{{airline.icao}}</td>
                    <td class="p-2">{{airline.names}}</td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="airlines.length == 0" class="text-center"
                i18n="cmn|Modal airline - searche airline not found@@cmn.modalAirline.search.notFound">
                Not found
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-right bg-light-gray">
        <button type="button" class="btn btn-blue btn-sm"
          i18n="cmn|Modal airline - button save@@cmn.modalAirline.buttons.save">
          Save
        </button>
        <button type="button" class="btn btn-gray-blue-dark btn-sm" (click)="showModal = null"
          i18n="cmn|Modal airline - button save@@cmn.modalAirline.buttons.cancel">
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Модалка выбора папки для копирования-->
  <div *ngIf="selectedAction !== ''" class="ms-modal-message">
    <div class="modal-content w-50">
      <div class="loading" *ngIf="loading">
        <div class="cssload-spin-box"></div>
      </div>
      <div class="modal-header">
        <h5 class="modal-title" i18n="cmn|Modal message copy - header copy@@cmn.modalMessageCope.header.copy">
          Copy message to folder
        </h5>
        <button type="button" class="close" (click)="selectAction('', messageId)" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p i18n="cmn|Modal message copy - select folder@@cmn.modalMessageCope.selectFolder">Select folder:</p>
        <div class="row">
          <div *ngFor="let subfolder of allFolders" class="col-4 mb-3">
            <label *ngIf="subfolder.id !== -1" class="m-0">
              <input type="checkbox" name="toFolder" class="mr-2" [value]="subfolder.id"
                (change)="updateCopyIds(subfolder.id)"
                [checked]="this.copyIds.has(subfolder.id) || foldersForMessage.indexOf(subfolder.id)>-1"
                [disabled]="subfolder.id==selectedFolder || subfolder.id===0 || foldersForMessage.indexOf(subfolder.id)>-1">
              {{subfolder.name}}
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button"
                  class="btn btn-blue btn-sm float-right"
                  (click)="copyToFolder(messageId)"
                  i18n="cmn|Modal message copy - button copy@@cmn.modalMessageCope.buttons.copy"
                  [disabled]="copyIds.size==0">
            Copy
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Модалка привязки рейса -->
  <div *ngIf="showModal == 'flight'" class="ms-modal-message">
    <div class="modal-content w-50">
      <div class="loading" *ngIf="loading">
        <div class="cssload-spin-box"></div>
      </div>
      <div class="modal-header">
        <h5 class="modal-title" i18n="cmn|Modal flight - header select flight@@cmn.modalFlight.header.selectFlight">
          Select flight
        </h5>
        <button type="button" class="close" (click)="showModal = null" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 mb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text font-small" i18n="cmn|Modal flight - search@@cmn.modalFlight.search">
                  Search
                </span>
              </div>
              <input type="text" class="form-control" name="search" id="search"
                placeholder="Enter flight number or code" [(ngModel)]="searchInput"
                i18n-placeholder="cmn|Modal flight - search placeholder@@cmn.modalFlight.searchPlaceholder">
            </div>
          </div>
          <div class="col-12">
            <div class="border" style="min-height: 200px;">
              <table class="w-100" *ngIf="flights.length > 0">
                <thead>
                  <tr class="border-bottom">
                    <th class="p-2"></th>
                    <th class="p-2" i18n="cmn|Modal flight - table column iata@@cmn.modalFlight.table.col.iata">
                      IATA
                    </th>
                    <th class="p-2" i18n="cmn|Modal flight - table column iata@@cmn.modalFlight.table.col.icao">
                      ICAO
                    </th>
                    <th class="p-2" i18n="cmn|Modal flight - table column zrt@@cmn.modalFlight.table.col.zrt">
                      ZRT
                    </th>
                    <th class="p-2" i18n="cmn|Modal flight - table column icao@@cmn.modalFlight.table.col.flight">
                      Flight number
                    </th>
                    <th class="p-2"
                      i18n="cmn|Modal flight - table column flight date@@cmn.modalFlight.table.col.flightDate">
                      Flight date
                    </th>
                    <th class="p-2" i18n="cmn|Modal flight - table column zrt@@cmn.modalFlight.table.col.zrt">
                      Destination
                    </th>
                    <th class="p-2" i18n="cmn|Modal flight - table column iata@@cmn.modalFlight.table.col.type">
                      Type
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let flight of flights" class="border-bottom"
                    [ngClass]="{'bg-light-gray': message.links.includes(flight.id)}">
                    <td class="p-2">
                      <input type="checkbox" name="flight_id_{{flight.id}}" value="{{flight.id}}"
                        [checked]="message.links.includes(flight.id)" (click)="updateFlightsList(flight)">
                    </td>
                    <td class="p-2">{{flight.airlineIata}}</td>
                    <td class="p-2">{{flight.airlineIcao}}</td>
                    <td class="p-2">{{flight.airlineCode}}</td>
                    <td class="p-2">{{flight.flight}}</td>
                    <td class="p-2">
                      <ng-container *ngIf="flight.times">{{flight.times[0] | date: 'dd.MM.yyyy HH:mm' : 'UTC+0'}}</ng-container>
                    </td>
                    <td class="p-2">
                      <ng-container *ngIf="flight.route">{{flight.route ? flight.route.join(', ') : ''}}</ng-container>
                    </td>
                    <td class="p-2">{{flight.arrdep==1 ? 'Departure' : 'Arrival'}}</td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="flights.length == 0" class="text-center"
                i18n="cmn|Modal flight - searche flight not found@@cmn.modalFlight.search.notFound">
                Not found
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-right bg-light-gray">
        <button type="button" class="btn btn-blue btn-sm" (click)="saveMessageFlight(messageFlightsId, message.id)"
          i18n="cmn|Modal flight - button save@@cmn.modalFlight.buttons.save">
          Save
        </button>
        <button type="button" class="btn btn-gray-blue-dark btn-sm" (click)="showModal = null"
          i18n="cmn|Modal flight - button save@@cmn.modalFlight.buttons.cancel">
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!--<div *ngIf="confirmMessage !== ''" class="ms-modal-message">
    <div class="modal-content">
      <div class="modal-header bg-warning">
        <h5 class="modal-title"
          i18n="cmn|Modal message error - header watning@@cmd.main.modalMessage.errMes.header.warn">
          Warning
        </h5>
        <button type="button" class="close" (click)="clearConfirmMessage()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{confirmMessage}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-blue" (click)="okFunc()"
          i18n="cmn|Modal confirm message - button yes@@cmn.modalConfirmMessage.buttons.yes">
          Yes
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="cancelFunc()"
          i18n="cmn|Modal confirm message - button no@@cmn.modalConfirmMessage.buttons.no">
          No
        </button>
      </div>
    </div>
  </div>-->

  <div *ngIf="errorComman" class="ms-modal-message">
    <div class="modal-content w-25">
      <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                    'bg-warning': errorType == 'warning',
                                    'bg-danger': errorType == 'error'}">
        <h5 *ngIf="errorType == 'info'" class="modal-title"
          i18n="cmn|Modal message error - header information@@cmd.main.modalMessage.errMes.header.inf">
          Information
        </h5>
        <h5 *ngIf="errorType == 'warning'" class="modal-title"
          i18n="cmn|Modal message error - header watning@@cmd.main.modalMessage.errMes.header.warn">
          Warning
        </h5>
        <h5 *ngIf="errorType == 'error'" class="modal-title"
          i18n="cmn|Modal message error - header error@@cmd.main.modalMessage.errMes.header.err">
          Error
        </h5>
        <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{errorComman}}
      </div>
      <div class="modal-footer">
        <button type="button" *ngIf="userAction==='setAnswer'"
                class="btn btn-sm btn-blue"
                (click)="userAnswer = true"
                i18n="cmn|Modal message error - button yes@@cmd.main.modalMessage.errMes.button.yes">
          Yes
        </button>
        <button type="button" *ngIf="userAction==='setAnswer'"
                class="btn btn-sm btn-gray-blue-dark"
                (click)="userAnswer = false"
                i18n="cmn|Modal message error - button no@@cmd.main.modalMessage.errMes.button.no">
          No
        </button>
        <button type="button" *ngIf="userAction!=='setAnswer'"
                class="btn btn-sm btn-gray-blue-dark"
                (click)="errorComman = null"
                i18n="cmn|Modal create message error - button close@@cmn.modalCreateMessage.buttons.close">
          Close
        </button>
      </div>
    </div>
  </div>
  