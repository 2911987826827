import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
// Keycloak
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { initializer } from './app.component';
// Routing module for router service
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Forms module
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// HttpClient module for RESTful API
import { HttpClientModule } from '@angular/common/http';
// Print Module
import { NgxPrintModule } from 'ngx-print';
// File saver
import { FileSaverModule } from 'ngx-filesaver';
// Sidebar Module
import { SidebarModule } from 'ng-sidebar';
// Select module
import { NgSelectModule } from '@ng-select/ng-select';
// Google Charts
import { GoogleChartsModule } from 'angular-google-charts';
import {DragDropModule} from '@angular/cdk/drag-drop';
// Глобальные настройки
import { GlobalSettings } from './settings/global-settings';
// Глобальный файл переводов для текста в ts файлах
import { GlobalI18n } from './settings/global-i18n';
// Barcode generator
import { Pdf417BarcodeModule } from 'pdf417-barcode';
// Logger Module
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { WorkflowComponent } from './workflow.component';
import { MobileApronComponent } from './mobile-apron.component';
import { TestComponent } from './test/test.component';
import { CommunicationModuleComponent } from './communication-module/communication-module.component';
import { ScheduleModuleComponent } from './schedule-module/schedule-module.component';
import { ReferanceModuleComponent } from './referance-module/referance-module.component';
import { WorkflowModuleComponent } from './workflow-module/workflow-module.component';
import { SlotCoordinationModuleComponent } from './slot-coordination-module/slot-coordination-module.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdministrationModuleComponent } from './administration-module/administration-module.component';

import { CookieService } from 'ngx-cookie-service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReportsModuleComponent } from './reports-module/reports-module.component';
import { AmCommunicationComponent } from './administration-module/am-communication/am-communication.component';
import { AmInformationalComponent } from './administration-module/am-informational/am-informational.component';
import { AmTechprocessesComponent } from './administration-module/am-techprocesses/am-techprocesses.component';
import { AmBoardingControlComponent } from './administration-module/am-boarding-control/am-boarding-control.component';
import { BoardingControlModuleComponent } from './boarding-control-module/boarding-control-module.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ParkingPlacesComponent } from './parking-places/parking-places.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    WorkflowComponent,
    MobileApronComponent,
    TestComponent,
    CommunicationModuleComponent,
    ScheduleModuleComponent,
    ReferanceModuleComponent,
    WorkflowModuleComponent,
    SlotCoordinationModuleComponent,
    AdministrationModuleComponent,
    ReportsModuleComponent,
    AmCommunicationComponent,
    AmInformationalComponent,
    AmTechprocessesComponent,
    BoardingControlModuleComponent,
    NotificationsComponent,
    AmBoardingControlComponent,
    ParkingPlacesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPrintModule,
    FileSaverModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    KeycloakAngularModule,
    SidebarModule.forRoot(),
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    NgSelectModule,
    GoogleChartsModule,
    DragDropModule,
    Pdf417BarcodeModule,
    ToastrModule.forRoot(),
    LoggerModule.forRoot({
      serverLoggingUrl: '',
      level: NgxLoggerLevel.OFF,
      serverLogLevel: NgxLoggerLevel.OFF,
      disableConsoleLogging: false,
      enableSourceMaps: true
    })
  ],
    providers: [
      CookieService,
      GlobalSettings,
      GlobalI18n,
      {
        provide: APP_INITIALIZER,
        useFactory: initializer,
        multi: true,
        deps: [KeycloakService, GlobalSettings],
      }
  ],
  bootstrap: [AppComponent],
  exports: [
    WorkflowComponent,
    CommunicationModuleComponent,
    MobileApronComponent,
    SlotCoordinationModuleComponent,
    ReportsModuleComponent
  ]
})

export class AppModule { }
