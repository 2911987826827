import {  ModuleName } from './global-settings';

/**
 * Класс Components
 *
 * Описывает компоненты системы: Модули, Элементы (TODO), Атрибуты(TODO)
 * Данный клас является центализованным местом хранения свойств и параметров
 * необходимых для взаимодействия с компонентами системы для их администрирования
 * (отображение, назначение и прочие действия)
 */
export class Components {

  constructor() {
    this.modules.push(
      new Module(
        ModuleName.Test,
        $localize`:@@app.components.test:Test`,
        'aac_test',
        '/test'
      )
    );
    this.modules.push(
      new Module(
        ModuleName.ParkingPlace,
        $localize`:@@app.components.parkingPlace:Parking places`,
        'aac_parking',
        '/parking'
      )
    );
    this.modules.push(
      new Module(
        ModuleName.Administration,
        $localize`:@@app.components.administration:Administration`,
        'aac_admin',
        '/admin'
      )
    );
    this.modules.push(
      new Module(
        ModuleName.BoardingControl,
        $localize`:@@app.components.boardingControl:Boarding control`,
        'aac_boardingcontrol',
        '/boardingcontrol'
      )
    );
    this.modules.push(
      new Module(
        ModuleName.MasterData,
        $localize`:@@app.components.masterData:Master data`,
        'aac_masterdata',
        '/masterdata'
      )
    );
    this.modules.push(
      new Module(
        ModuleName.Reports,
        $localize`:@@app.components.reports:Log&Reports`,
        'aac_reports',
        '/reports'
      )
    );
    this.modules.push(
      new Module(
        ModuleName.MobileApron,
        $localize`:@@app.components.mobileApron:Mobile apron`,
        'aac_mobileapron',
        '/mobileapron'
      )
    );
    this.modules.push(
      new Module(
        ModuleName.Workflow,
        $localize`:@@app.components.workflow:Workflow`,
        'aac_workflow',
        '/workflow'
      )
    );
    this.modules.push(
      new Module(
        ModuleName.SlotCoord,
        $localize`:@@app.components.slotCoord:Season`,
        'aac_slotcoord',
        '/slotcoord'
      )
    );
    this.modules.push(
      new Module(
        ModuleName.Schedule,
        $localize`:@@app.components.schedule:Schedule`,
        'aac_schedule',
        '/schedule'
      )
    );
    this.modules.push(
      new Module(
        ModuleName.ComMan,
        $localize`:@@app.components.comMan:Communication module`,
        'aac_commodule',
        '/commodule'
      )
    );
  };

  modules: Array<Module> = [];

  getAvailableModulesForRoles(roleList: Array<string>): any[] {
    let moduleArray = [];
    roleList.forEach(element => {
      let index = this.modules.find(item => item.group.toUpperCase() == element.toUpperCase());
      if (index != undefined) {
        moduleArray.push(index.id);
      }
    });
    return moduleArray;
  }

  getModulePath(id: ModuleName) {
    return this.modules.find(element => element.id == id).path;
  }
}

/**
 * Класс Module
 *
 * Реализует уникальноеописание модуля с помощью идентификатора, названия
 * на нескольких языках для отображения пользователю в человеко понятном виде,
 * список ролей с помощью которых данный модуль идентифицируется при настройке
 * прав в Keycloak
 */
export class Module {

  constructor(id: ModuleName,
              name: string,
              group: string,
              path: string) {
    this.id = id;
    this.name = name;
    this.group = group;
    this.path = path;
  }

  // Идентифиатор модуля
  id: ModuleName;
  // Человеко-читаемое имя модуля на английском языке языке
  name: string;
  // Группа из Keycloak в которой пользователь должен состоять для доступа
  group: string;
  // Путь для переходу к модуля (в адресной строке)
  path: string;
}
