import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Flight,
  Flights,
  FlightCombined,
  ReferanceStatuses,
  ReferanceDelaySourceOperator,
  ReferanceGeo
} from '../schedule-module-data/flight';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {
  ReferanceAirline,
  ReferanceAirport,
  ReferanceMovement,
  ReferanceDelay,
  ReferanceDelaySource,
  ReferanceAircraft,
  ReferanceTail,
  ReferanceStand,
  ReferanceTerminal
} from '../referance-module-data/referance';
import { GlobalSettings } from '../settings/global-settings';

@Injectable({
  providedIn: 'root'
})
export class ScheduleModuleRestApiService {

  constructor(private http: HttpClient, private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  // HttpClient API get() method => Fetch employees list
  getFlights(filterParams?): Observable<Flights> {
    let url = '/flightsv1';
    if (filterParams) {
      var params = [];
      for (const key in filterParams) {
        if ((key == 'start' || key == 'finish') && filterParams[key]) {
          params.push(key + '=' + filterParams[key].toISOString());
        } else if (filterParams[key]) {
          params.push(key + '=' + filterParams[key]);
        }
      }
      if (params.length > 0) {
        url += '?' + params.join('&');
      }
    }
    return this.http.get<Flights>(this.globalSettings.apiScheduleURL + url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getFlightsCombined(filterParams?): Observable<FlightCombined[]> {
    let url = '/flightsv2';
    if (filterParams) {
      var params = [];
      for (const key in filterParams) {
        if ((key == 'start' || key == 'finish') && filterParams[key]) {
          params.push(key + '=' + filterParams[key].toISOString());
        } else if (filterParams[key]) {
          params.push(key + '=' + filterParams[key]);
        }
      }
      if (params.length > 0) {
        url += '?' + params.join('&');
      }
    }
    return this.http.get<FlightCombined[]>(this.globalSettings.apiScheduleURL + url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API get() method => Fetch employee
  getFlight(id: number): Observable<Flight> {
    return this.http.get<Flight>(this.globalSettings.apiScheduleURL + '/flights/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getMessagesForFlight(id: number): Observable<Array<any>> {
    console.log(this.globalSettings.apiScheduleURL + '/messagesForFlight/' + id);
    return this.http.get<Array<any>>(this.globalSettings.apiScheduleURL + '/messagesForFlight/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addFlight(flight: any): Observable<Flight> {
    return this.http.post<Flight>(this.globalSettings.apiScheduleURL +
                                            '/flights',
                                            JSON.stringify(flight),
                                            this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateFlight(id: number, flight: Flight): Observable<Flight> {
   return this.http.put<Flight>(this.globalSettings.apiScheduleURL +
                                          '/flights/' +
                                          id,
                                          JSON.stringify(flight),
                                          this.httpOptions)
   .pipe(
     retry(1),
     catchError(this.handleError)
   );
  }
  
  addNextFlight(id: number, nextId: number): Observable<any> {
    return this.http.put(this.globalSettings.apiScheduleURL +
                                           '/nextFlight/' + id,
                                           {"id": nextId},
                                           this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Airlines
  getAirlines(): Observable<ReferanceAirline[]> {
    return this.http.get<ReferanceAirline[]>(this.globalSettings.apiReferanceURL +
                                           '/airlines')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Airports
  getAirports(): Observable<ReferanceAirport[]> {
    return this.http.get<ReferanceAirport[]>(this.globalSettings.apiReferanceURL + '/airports')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Movements
  getMovements(): Observable<ReferanceMovement[]> {
    return this.http.get<ReferanceMovement[]>(this.globalSettings.apiReferanceURL +
                                            '/movement_types')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // GEO
  getGeoTypes(): Observable<ReferanceGeo[]> {
    return this.http.get<ReferanceGeo[]>(this.globalSettings.apiReferanceURL +
                                            '/geo_types')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Delays
  getDelays(): Observable<ReferanceDelay[]> {
    return this.http.get<ReferanceDelay[]>(this.globalSettings.apiReferanceURL +
                                            '/delays')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Delay Sources
  getDelaySources(): Observable<ReferanceDelaySource[]> {
    return this.http.get<ReferanceDelaySource[]>(this.globalSettings.apiReferanceURL +
                                             '/delay_sources')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Delay Source Operators
  getDelaySourceOperators(id: number): Observable<ReferanceDelaySourceOperator[]> {
    return this.http.get<ReferanceDelaySourceOperator[]>(this.globalSettings.apiReferanceURL +
                                             '/delay_source_operators_by_delay_source/' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Aircraft Types
  getAircraftTypes(): Observable<ReferanceAircraft[]> {
    return this.http.get<ReferanceAircraft[]>(this.globalSettings.apiReferanceURL +
                                                '/aircraft_types')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Tails
  getTails(): Observable<ReferanceTail[]> {
    return this.http.get<ReferanceTail[]>(this.globalSettings.apiReferanceURL +
                                              '/tails')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Statuses arrival
  getStatusesArrival(): Promise<ReferanceStatuses[]> {
    return this.http.get<ReferanceStatuses[]>(this.globalSettings.apiReferanceURL +
                                              '/statuses/arrival')
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Statuses baggage
  getStatusesBaggage(): Observable<ReferanceStatuses[]> {
    return this.http.get<ReferanceStatuses[]>(this.globalSettings.apiReferanceURL +
                                              '/statuses/baggage')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Statuses checkin
  getStatusesCheckin(): Observable<ReferanceStatuses[]> {
    return this.http.get<ReferanceStatuses[]>(this.globalSettings.apiReferanceURL +
                                              '/statuses/checkin')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Statuses boarding
  getStatusesBoarding(): Observable<ReferanceStatuses[]> {
    return this.http.get<ReferanceStatuses[]>(this.globalSettings.apiReferanceURL +
                                              '/statuses/boarding')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Statuses departure
  getStatusesDeparture(): Observable<ReferanceStatuses[]> {
    return this.http.get<ReferanceStatuses[]>(this.globalSettings.apiReferanceURL +
                                              '/statuses/departure')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Stands
  getStands(): Observable<ReferanceStand[]> {
    return this.http.get<ReferanceStand[]>(this.globalSettings.apiReferanceURL + '/stands')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Terminals
  getTerminals(): Observable<ReferanceTerminal[]> {
    return this.http.get<ReferanceTerminal[]>(this.globalSettings.apiReferanceURL + '/terminals')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}
