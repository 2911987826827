import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AdministrationRestApiService } from '../administration-data/administration-rest-api.service';
import { GlobalSettings } from '../settings/global-settings';
import { KeycloakService } from 'keycloak-angular';


@Component({
  selector: 'app-administration-module',
  templateUrl: './administration-module.component.html',
  styleUrls: ['./administration-module.component.less']
})
export class AdministrationModuleComponent implements OnInit {

  baseURL =  this.globalSettings.apiInformationalURL;

  activeModule = 'module-communication';
  constructor(
    public restApi: AdministrationRestApiService,
    private keycloakService: KeycloakService,
    private globalSettings: GlobalSettings
  ) {
    globalSettings.loadDefaultConfig();
  }

  async ngOnInit() {
    // Ожидание авторизации пользователя, для получения доступа к группам прав
    await this.keycloakService.isLoggedIn();
  }

  changeModule(event: { target: { id: string; }; }) {
    this.activeModule = event.target.id;
  }

  get isComModule() {
    return this.globalSettings.getUserDetail().isComModule;
  }

  get isInforming() {
    return this.globalSettings.getUserDetail().isInforming;
  }

  get isWorkflow() {
    return this.globalSettings.getUserDetail().isWorkflow;
  }
}
