export class AdministrationFilter {
  constructor() {
     this.id = undefined;
     this.name = '';
     this.active = true;
     this.conditionsOr = [];
     this.conditionsAnd = [];
     this.foldersRules = [];
     this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
   }

  id: number;
  name: string;
  active: boolean;
  conditionsOr: Array <AdministrationFilterField>;
  conditionsAnd: Array <AdministrationFilterField>;
  foldersRules: Array<AdministrationFilterFolderRule>;
  dtRange: Array<Date>;
  lastupdate: Date;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = new Date(value);
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }
}

export class AdministrationFilterField {
  constructor() {
    this.fieldId = 0;
    this.operationId = 0;
    this.value = '';
  }

  fieldId: number;
  operationId: number;
  value: string;
}

export class AdministrationFilterFolderRule {
  constructor() {
    this.folderId = undefined;
    this.folderName = '';
    this.always = false;
    this.range = [new Date(), new Date(2099, 1, 1, 23, 59)];
    this.daysOfWeek = [];
    this.hours = [];
  }

  id: number;
  folderId: number;
  folderName: string;
  always: boolean;
  range: Array<Date>;
  daysOfWeek: Array<number>;
  hours: Array<string>;

  get dtRangeStart(): Date {
    if ((this.range) && (this.range.length >= 1) && this.range[0]!==null) {
      const date = new Date(this.range[0]);
      if (date.getFullYear() > 2000 ) {
        return date;
      } else return null;
    } else return null;
  }

  set dtRangeStart(value: Date) {
    this.range[0] = new Date(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate(), value.getUTCHours(), value.getUTCMinutes(), value.getUTCSeconds());
    //return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  get dtRangeFinish(): Date {
    if ((this.range) && (this.range.length === 2) && this.range[1]!==null) {
      const date = new Date(this.range[1]);
      if (date.getFullYear() > 2000 ) {
        return date;
      } else return null;
    } else return null;
  }
  
  set dtRangeFinish(value: Date) {
    this.range[1] = new Date(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate(), value.getUTCHours(), value.getUTCMinutes(), value.getUTCSeconds());
  }

  get timeStart(): string {
    if ((this.hours) && (this.hours.length >= 1) && this.hours[0]!==null) {
      return this.hours[0];
    } else {
      return null;
    }
  }
  
  set timeStart(value: string) {
    this.hours[0] = value;
  }
  
  get timeFinish(): string {
    if ((this.hours) && (this.hours.length === 2) && this.hours[1]!==null) {
      return this.hours[1];
    } else {
      return null;
    }
  }
  
  set timeFinish(value: string) {
    this.hours[1] = value;
  }
}

export class AdministrationTemplate {
  constructor() {
    this.id = undefined;
    this.name = '';
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
  }

  id: number;
  name:string;
  sender: string;
  recipient: string;
  subject: string;
  typeId: number;
  typeName: Array<string>;
  channelId: number;
  channelName: string;
  urgencyId: number;
  urgencyName: Array<string>;
  message: string;
  folderId: number;
  folderName: string;
  dtRange: Array<Date>;
  lastupdate: Date;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = new Date(value);
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = new Date(value);
  }

}

/*
export class AdministrationTemplate {
  constructor() {
    this.id = undefined;
    this.name = '';
    this.parts = [];
    this.dtRange = [new Date(), new Date('2099-01-01')];
  }
 
  id: number;
  name: string;
  description: string;
  parts: Array <AdministrationTemplateParts>; 
  dtRange: Array<Date>;
  lastupdate: string;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = new Date(value);
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = new Date(value);
  }

}

export class AdministrationTemplateParts {
  constructor() {
    this.type = '';
    this.value = '';
    this.minLength = null;
    this.maxLength = null;
    this.format = '';
    this.dateFormat = '';
    this.hint = '';
    this.required = false;
  }

  type: string;
  value: string;
  minLength: number;
  maxLength: number;
  format: string;
  dateFormat: string;
  hint: string;
  required: boolean;
}
*/

export class AdministrationContact {
  constructor() {
    this.id = undefined;
    this.name = '';
    this.channelId = undefined;
    this.addresses = [''];
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
  }

  id: number;
  name: string;
  channelId: number;
  channel: string;
  addresses: Array <string>;
  dtRange: Array<Date>;
  lastupdate: string;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = new Date(value);
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = new Date(value);
  }
  
  get address(): string {
    if (this.addresses) {
      return this.addresses.join(', ');
    } else {
      return '';
    }
  }
}

export class AdministrationArchive {
  constructor() {
    this.messageTypeId = undefined;
    this.folderId = undefined;
    this.times = undefined;
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
  }

  id: number;
  messageType: string;
  messageTypeId: number;
  folder: string;
  folderId: number;
  times: number;
  dtRange: Array<Date>;
  lastupdate: string;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = new Date(value);
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = new Date(value);
  }

}

export class AdministrationAddress {
  constructor() {
    this.airportId = undefined;
    this.channelId = undefined;
    this.addresses = [''];
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
  }

  id: number;
  airport: string;
  airportId: number;
  channel: string;
  channelId: number;
  addresses: Array<string>;
  dtRange: Array<Date>;
  lastupdate: string;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = new Date(value);
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = new Date(value);
  }

  get address(): string {
    if (this.addresses) {
      return this.addresses.join(', ');
    } else {
      return '';
    }
  }
}

export class AdministrationSender {
  constructor() {
    this.address = ['', ''];
    this.baseApId = undefined;
    this.channelId = undefined;
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
  }

  id: number;
  address: Array<string>;
  baseApId: number;
  baseAp: string;
  channelId: number;
  channelName: string;
  folderId: number;
  folderName: string;
  dtRange: Array<Date>;
  lastupdate: string;

  get addressEnglish(): string {
    if ((this.address) && (this.address.length >= 1)) {
      return this.address[0];
    } else {
      return null;
    }
  }

  set addressEnglish(value) {
    this.address[0] = value;
  }

  get addressLocal(): string {
    if ((this.address) && (this.address.length === 2)) {
      return this.address[1];
    } else {
      return null;
    }
  }

  set addressLocal(value) {
    this.address[1] = value;
  }

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = new Date(value);
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = new Date(value);
  }

}

export class AdministrationGroup {
  constructor() {
    this.name = '';
    this.baseAps = [];
    this.folders = [];
  }

  id: string;
  name: string;
  baseAps: Array<string>;
  folders: Array<{
    id: number;
    mode: string;
    hideid: number;
  }>;

}

export class AdministrationUser {
  constructor() {
    this.username = '';
    this.firstName = '';
    this.lastName = '';
    this.enabled = false;
    this.groups = [];
    this.groupsArray = [];
  }

  id: string;
  username: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  groups: Array<AdministrationGroup>;
  groupsArray: Array<string>;
}

export class ReferanceOrder {
  constructor() {
    this.orderBy = '';
    this.orderField = '';
  }

  orderBy: string;
  orderField: string;
}
