import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Message, MessageDetail, MessageTemplate } from './message';
import { Folder, FolderSetting } from './folder';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { Channel, Urgency, Type, Flight } from './reference';
import { AdministrationContact, AdministrationSender } from '../administration-data/com-module';
import { ReferanceAirline, ReferanceAirport } from '../referance-module-data/referance';
import { GlobalSettings, ModuleName } from '../settings/global-settings';
import { NGXLogger } from 'ngx-logger';
import { ECSEvent, EventProvider } from 'src/app/settings/logger-monitor';
import { isCyrillic, transliterationEnRu, transliterationRuEn, mtk2Ru, mtk2Lat } from '../core/transliteration';

@Injectable({
  providedIn: 'root'
})

export class CommunicationModuleRestApiService {

  // Транслитерация адресов
  mtk2Ru = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ь', 'Ы', 'Я'];
  mtk2Lat = ['A', 'B', 'W', 'G', 'D', 'E', 'V', 'Z', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'T', 'U', 'F', 'H', 'C', 'X', 'Y', 'Q'];

  constructor(private http: HttpClient,
              private globalSettings: GlobalSettings,
              private logger: NGXLogger) {
    globalSettings.loadDefaultConfig();
  }

  setDefaultHttpHeader(requestId?): Object {
    // Формирование заголовков для отслеживания запросов
    // X-Correlation-ID идентификатор пользовательской сессии
    // X-Request-ID идентификатор события / запроса
    let httpOptions = {};
    httpOptions['headers'] = { 'Content-Type' : 'application/json',
                               'X-Correlation-ID' : this.globalSettings.userSessionUUID,
                               'X-Request-ID' : (requestId === undefined) ? this.globalSettings.randomUuid : requestId };
    return httpOptions;
  }

  getMessages(getParams, timestamp, link, xRequestId?): Observable<any[]> {
    let params = this.createParams(getParams);
    var type;
    if (getParams.all != null) {
      type = 'all';
    } else {
      type = getParams.depth;
    }

    const paramsArray = params.keys().map(x => (`${x}=${params.get(x)}`));
    this.logger.debug(this.globalSettings.apiCommunicationURL + `${link}/${type}/messages/${getParams.limit}/${getParams.page}`);
    this.logger.debug(paramsArray.join('&'));

    this.logger.trace('getMessages',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getMessages',
                                  'get',
                                  getParams,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `${link}/${type}/messages/${getParams.limit}/${getParams.page}`));

    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['params'] = params;
    return this.http.get<Message[]>(this.globalSettings.apiCommunicationURL +
                                    `${link}/${type}/messages/${getParams.limit}/${getParams.page}`,
                                    httpOptions)
      .pipe(
        map(item => [item, timestamp]),
        retry(1),
        catchError(this.handleError)
      );
  }

  getOneMessage(id: number, folderId: number, xRequestId?): Observable<MessageDetail> {
    this.logger.trace('getOneMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getOneMessage',
                                  'get',
                                  'id: ' + id + ', folderId: ' + folderId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/folders/${folderId}`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<MessageDetail>(this.globalSettings.apiCommunicationURL +
                                        `/messages/${id}/folders/${folderId}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getChainMessage(id: number, folderId: number, xRequestId?): Observable<MessageDetail[]> {
    this.logger.trace('getMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getMessage',
                                  'get',
                                  'id: ' + id + ', folderId: ' + folderId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/folders/${folderId}/chain`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<MessageDetail[]>(this.globalSettings.apiCommunicationURL +
                                          `/messages/${id}/folders/${folderId}/chain`,
                                          httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getMessageFlights(id: number, xRequestId?): Observable<Array<number>> {
    this.logger.trace('getMessageFlights',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getMessageFlights',
                                  'get',
                                  id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/flights`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Array<number>>(this.globalSettings.apiCommunicationURL +
                                        `/messages/${id}/flights`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createMesssage(message: any, folderId, xRequestId?): Promise<HttpResponse<any>> {
    this.logger.trace('createMesssage',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.createMesssage',
                                  'post',
                                  'message: ' + message + ', folderId: ' + folderId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${folderId}`));

    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiCommunicationURL +
                                             `/messages/${folderId}`,
                                             JSON.stringify(message),
                                             httpOptions)
      .pipe(
        map(resp => {
          return resp;
        }),
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  loadDraft(id: number, folderId: number, xRequestId?): Observable<any> {
    this.logger.trace('getDraft',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getDraft',
                                  'get',
                                  'id: ' + id + ', folderId: ' + folderId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/folders/${folderId}/drafts/${id}`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any>(this.globalSettings.apiCommunicationURL +
                                        `/folders/${folderId}/drafts/${id}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteDraft(id: number, folderId: number, xRequestId?): Observable<any> {
    this.logger.trace('deleteDraft',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.deleteDraft',
                                  'delete',
                                  'id: ' + id + ', folderId: ' + folderId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/folders/${folderId}/drafts/${id}`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.delete<any>(this.globalSettings.apiCommunicationURL +
                                        `/folders/${folderId}/drafts/${id}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  saveDraft(message: any, folderId: number, xRequestId?): Observable<HttpResponse<any>> {
    this.logger.trace('saveDraft',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.saveDraft',
                                  'post',
                                  'message: ' + message + ', folderId: ' + folderId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${folderId}/draft`));

    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiCommunicationURL +
                                             `/folders/${folderId}/drafts`,
                                             JSON.stringify(message),
                                             { observe: 'response' })
      .pipe(
        map(resp => {
          return resp;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  updateDraft(message: any, id: number, folderId: number, xRequestId?): Observable<HttpResponse<any>> {
    this.logger.trace('updateDraft',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.updateDraft',
                                  'post',
                                  'message: ' + message + ', id: ' + id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/folders/${folderId}/drafts/${id}`));

    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.put<HttpResponse<any>>(this.globalSettings.apiCommunicationURL +
                                            `/folders/${folderId}/drafts/${id}`,
                                            JSON.stringify(message),
                                            { observe: 'response' })
      .pipe(
        map(resp => {
          return resp;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  updateMessage(id: number, message: any, xRequestId?): Observable<MessageDetail> {
    this.logger.trace('updateMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.updateMessage',
                                  'post',
                                  'message: ' + message + ', id: ' + id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  '/messages/' + id));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<MessageDetail>(this.globalSettings.apiCommunicationURL +
                                        '/messages/' +
                                        id,
                                        JSON.stringify(message),
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  readMessage(id: number, folderId: number, xRequestId?): Promise<MessageDetail> {
    this.logger.trace('readMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.readMessage',
                                  'put',
                                  'folderId: ' + folderId + ', id: ' + id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/folders/${folderId}/read`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<MessageDetail>(this.globalSettings.apiCommunicationURL +
                                        `/messages/${id}/folders/${folderId}/read`,
                                        {},
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  unreadMessage(id: number, folderId: number, xRequestId?): Promise<MessageDetail> {
    this.logger.trace('unreadMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.unreadMessage',
                                  'put',
                                  'folderId: ' + folderId + ', id: ' + id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/folders/${folderId}/unread`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<MessageDetail>(this.globalSettings.apiCommunicationURL +
                                        `/messages/${id}/folders/${folderId}/unread`,
                                        {},
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // TODO Удалить
  // Эта ф-ция не используется
  deleteAirport(id: number, folder: string, xRequestId) {
    this.logger.trace('deleteAirport',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.deleteAirport',
                                  'delete',
                                  'folderId: ' + folder + ', id: ' + id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiReferanceURL +
                                  '/messages/' + id + '/' + folder));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.delete<MessageDetail>(this.globalSettings.apiReferanceURL +
                                           '/messages/' +
                                           id +
                                           '/' +
                                           folder,
                                           httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getFolders(xRequestId?): Observable<Folder[]> {
    this.logger.trace('getFolders',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getFolders',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                   '/folders'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Folder[]>(this.globalSettings.apiCommunicationURL +
                                   '/folders',
                                   httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCountMessages(getParams, link, xRequestId?): Observable<number> {
    let params = this.createParams(getParams);
    let type;
    if (getParams.all != null) {
      type = 'all';
    } else {
      type = getParams.depth;
    }

    this.logger.trace('getCountMessages',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getCountMessages',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                 `${link}/${type}/count`));

    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['params'] = params;
    return this.http.get<number>(this.globalSettings.apiCommunicationURL +
                                 `${link}/${type}/count`,
                                 httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCountFilteredMessages(getParams, id: number, xRequestId?): Observable<number> {
    this.logger.trace('getCountFilteredMessages',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getCountFilteredMessages',
                                  'get',
                                  'id: ' + id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                 `/folders/${id}/unread`));
    let params = this.createParams(getParams);
    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['params'] = params;
    return this.http.get<number>(this.globalSettings.apiCommunicationURL +
                                 `/folders/${id}/unread`,
                                 httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getAllFolders(xRequestId?): Observable<Folder[]> {
    this.logger.trace('getAllFolders',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getAllFolders',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                   '/admin/folders?current=true'));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Folder[]>(this.globalSettings.apiCommunicationURL +
                                   '/admin/folders?current=true',
                                   httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getFolder(id: number, xRequestId?): Observable<FolderSetting> {
    this.logger.trace('getFolder',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getFolder',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  '/admin/folders/' + id));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<FolderSetting>(this.globalSettings.apiCommunicationURL +
                                        '/admin/folders/' + id,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Не используемая фунция, вызовов не найдено
  addFolder(foder: any, xRequestId?): Observable<Folder> {
    this.logger.trace('addFolder',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.addFolder',
                                  'post',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  '/folders/current'));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post<Folder>(this.globalSettings.apiCommunicationURL +
                                  '/folders/current',
                                  JSON.stringify(foder),
                                  httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getChannels(xRequestId?): Promise<Channel> {
    this.logger.trace('getChannels',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getChannels',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  '/master_data/channels'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Channel>(this.globalSettings.apiCommunicationURL +
                                  '/master_data/channels',
                                  httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getSenders(channel, folder, xRequestId?): Promise<AdministrationSender[]> {
    this.logger.trace('getSenders',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getSenders',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  '/admin/senders_by_channel_and_folder/' +
                                  channel + '/' + folder));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<AdministrationSender[]>(this.globalSettings.apiCommunicationURL +
                                                 '/admin/senders_by_channel_and_folder/' +
                                                 channel + '/' + folder,
                                                 httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getContacts(xRequestId?): Promise<AdministrationContact[]> {
    this.logger.trace('getContacts',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getContacts',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  '/admin/contacts?current=true'));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<AdministrationContact[]>(this.globalSettings.apiCommunicationURL +
                                                  '/admin/contacts?current=true',
                                                  httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getUrgencies(channel?, xRequestId?): Promise<Urgency[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    if (channel == null) {
      this.logger.trace('getUrgencies',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getUrgencies',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  '/master_data/urgencies'));

      return this.http.get<Urgency[]>(this.globalSettings.apiCommunicationURL +
                                      '/master_data/urgencies',
                                      httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        ).toPromise();
    } else {
      this.logger.trace('getUrgencies',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getUrgencies',
                                  'get',
                                  channel,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  '/master_data/urgencies_by_channel/' +
                                  channel));

      return this.http.get<Urgency[]>(this.globalSettings.apiCommunicationURL +
                                      '/master_data/urgencies_by_channel/' +
                                      channel,
                                      httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        ).toPromise();
    }
  }

  getTypes(xRequestId?): Promise<Type[]> {
    this.logger.trace('getTypes',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getTypes',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                 '/master_data/message_types'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Type[]>(this.globalSettings.apiCommunicationURL +
                                 '/master_data/message_types',
                                 httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Airlines
  getAirlines(xRequestId?): Promise<ReferanceAirline[]> {
    this.logger.trace('getAirlines',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getAirlines',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  '/master_data/airlines'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<ReferanceAirline[]>(this.globalSettings.apiCommunicationURL +
                                             '/master_data/airlines',
                                             httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Airports
  getAirports(xRequestId?): Promise<ReferanceAirport[]> {
    this.logger.trace('getAirports',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getAirports',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  '/master_data/airports'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<ReferanceAirport[]>(this.globalSettings.apiCommunicationURL +
                                             '/master_data/airports',
                                             httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getFligtsList(xRequestId?): Observable<Flight[]> {
    this.logger.trace('getFligtsList',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getFligtsList',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                   '/flights'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Flight[]>(this.globalSettings.apiCommunicationURL +
                                   '/flights',
                                   httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  saveMessageFligts(messageId: number, ids: Array<number>, xRequestId?) {
    this.logger.trace('saveMessageFligts',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.saveMessageFligts',
                                  'post',
                                  'messageId: ' + messageId + ', ids: ' + ids,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${messageId}/links`));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post(this.globalSettings.apiCommunicationURL +
                          `/messages/${messageId}/links`,
                          ids,
                          httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  copyToFolder(id: number, folderId: number, folderIds: string, xRequestId?) {
    this.logger.trace('copyToFolder',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.copyToFolder',
                                  'put',
                                  'id: ' + id + ', folderIf: ' + folderId +
                                  ', folderIds: ' + folderIds,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/folders/${folderId}/copyTo/${folderIds}`));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put(this.globalSettings.apiCommunicationURL +
                         `/messages/${id}/folders/${folderId}/copyTo/${folderIds}`,
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getFoldersForMessage(id: number, xRequestId?) {
    this.logger.trace('getFoldersForMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getFoldersForMessage',
                                  'get',
                                  'id: ' + id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/folders`));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any>(this.globalSettings.apiCommunicationURL +
                              `/messages/${id}/folders`,
                              httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  sendToArchive(id: number, folderId: number, xRequestId?) {
    this.logger.trace('sendToArchive',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.sendToArchive',
                                  'put',
                                  'id: ' + id + ', folderId: ' + folderId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/folders/${folderId}/archive`));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put(this.globalSettings.apiCommunicationURL +
                         `/messages/${id}/folders/${folderId}/archive`,
                         {},
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  notSendToArchive(id: number, folderId: number, xRequestId?) {
    this.logger.trace('notSendToArchive',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.notSendToArchive',
                                  'put',
                                  'id: ' + id + ', folderId: ' + folderId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/folders/${folderId}/denyAutoArchive`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put(this.globalSettings.apiCommunicationURL +
                         `/messages/${id}/folders/${folderId}/denyAutoArchive`,
                         {},
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  restoreFromArchive(id: number, folderId: number, xRequestId?) {
    this.logger.trace('restoreFromArchive',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.restoreFromArchive',
                                  'put',
                                  'id: ' + id + ', folderId: ' + folderId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/folders/${folderId}/restore`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put(this.globalSettings.apiCommunicationURL +
                         `/messages/${id}/folders/${folderId}/restore`,
                         {},
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  reparseMessage(id: number, folderId: number, message: string, xRequestId?) {
    this.logger.trace('reparseMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.reparseMessage',
                                  'put',
                                  'id: ' + id + ', folderId: ' + folderId +
                                  ', message: ' + message,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/folders/${folderId}/reparse`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put(this.globalSettings.apiCommunicationURL +
                         `/messages/${id}/folders/${folderId}/reparse`,
                         message,
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  reSendMessage(id: number, message: MessageDetail, xRequestId?) {
    this.logger.trace('reSendMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.reSendMessage',
                                  'put',
                                  'id: ' + id + ', message: ' + message,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  '/messages/' + id));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put(this.globalSettings.apiCommunicationURL +
                         '/messages/' + id,
                         JSON.stringify(message),
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Templates
  getTemplates(folderId: number, xRequestId?): Observable<MessageTemplate[]> {
    this.logger.trace('getTemplates',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getTemplates',
                                  'get',
                                  'folderId: ' + folderId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/folders/${folderId}/templates?current=true`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<MessageTemplate[]>(this.globalSettings.apiCommunicationURL +
                                                   `/folders/${folderId}/templates?current=true`,
                                                   httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getTemplate(folderId: number, id: number, xRequestId?): Observable<MessageTemplate> {
    this.logger.trace('getTemplate',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getTemplate',
                                  'get',
                                  'folderId: ' + folderId + ', number: ' + id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/folders/${folderId}/templates/${id}`));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<MessageTemplate>(this.globalSettings.apiCommunicationURL +
                                                 `/folders/${folderId}/templates/${id}`,
                                                 httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  saveTemplate(folderId: number, template: MessageTemplate, xRequestId?) {
    this.logger.trace('saveTemplate',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.saveTemplate',
                                  'post',
                                  'folderId: ' + folderId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/folders/${folderId}/templates`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post(this.globalSettings.apiCommunicationURL +
                          `/folders/${folderId}/templates`,
                          JSON.stringify(template),
                          httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateTemplate(folderId: number, id: number, template: MessageTemplate, xRequestId?): Promise<any> {
    this.logger.trace('updateTemplate',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.updateTemplate',
                                  'put',
                                  'folderId: ' + folderId + ', number: ' + id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/folders/${folderId}/templates`));

    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put(this.globalSettings.apiCommunicationURL +
                         `/folders/${folderId}/templates/` + id,
                         JSON.stringify(template),
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Marks
  getMarks(xRequestId?): Observable<any> {
    this.logger.trace('getMarks',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.getMarks',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  '/marks'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any>(this.globalSettings.apiCommunicationURL +
                              '/marks',
                              httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  setMark(id: number, folderId: number, mark: string, xRequestId?) {
    this.logger.trace('setMark',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.setMark',
                                  'put',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/folders/${folderId}/mark/${mark}`));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put(this.globalSettings.apiCommunicationURL +
                         `/messages/${id}/folders/${folderId}/mark/${mark}`,
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  unsetMark(id: number, folderId: number, xRequestId?) {
    this.logger.trace('unsetMark',
                     new ECSEvent(ModuleName.ComMan,
                                  'rest.unsetMark',
                                  'put',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiCommunicationURL +
                                  `/messages/${id}/folders/${folderId}/unmark`));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put(this.globalSettings.apiCommunicationURL +
                         `/messages/${id}/folders/${folderId}/unmark`,
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  translationAddress(recipients) {
    const result = [];
    const ru = new RegExp('[' + mtk2Ru.join() + ']', 'i');
    const lat = /[a-zA-Z]/;
    const pattern = /[^@]+@[^@]+\.[a-zA-Z]{2,6}/;
    recipients.forEach(address => {
      address = address.toUpperCase();
      if (address.length <= 8 && (lat.test(address) || ru.test(address))) {
        if (isCyrillic(address)) {
          result.push(transliterationRuEn(address));
        } else {
          result.push(transliterationEnRu(address));
        }
      }
      result.push(address);
    });
    console.log([...new Set(result)]);
    return [...new Set(result)].join(',');
  }

  createParams(getParams) {
    let params = new HttpParams();
    if (getParams.airlineValue.length > 0) {
      params = params.append('airline', getParams.airlineFlag + getParams.airlineValue);
    }
    if (getParams.typeValue.length > 0) {
      params = params.append('messageType', getParams.typeFlag + getParams.typeValue);
    }
    if (getParams.routeValue.length > 0) {
      params = params.append('destination', getParams.routeFlag + getParams.routeValue);
    }
    if (getParams.channelValue.length > 0) {
      params = params.append('channel', getParams.channelFlag + getParams.channelValue);
    }
    if (getParams.flight.length > 0) {
      params = params.append('flight', getParams.flight);
    }
    if (getParams.recipient != '') {
      params = params.append('recipient', getParams.recipientFlag + this.translationAddress(getParams.recipient));
    }
    if (getParams.sender != '') {
      params = params.append('sender', getParams.senderFlag + this.translationAddress(getParams.sender));
    }
    if (getParams.urgencyValue.length > 0) {
      params = params.append('urgency', getParams.urgencyFlag + getParams.urgencyValue);
    }
    if (getParams.subject != '') {
      params = params.append('subject', getParams.subject);
    }
    if (getParams.textValue.length > 0) {
      params = params.append('message', getParams.textFlag + getParams.textValue.join('||||'));
    }
    if (getParams.aftnNumber.length > 0) {
      params = params.append('aftnNumber', getParams.aftnNumber);
    }
    if (getParams.userValue != '') {
      params = params.append('username', getParams.userFlag + getParams.userValue);
    }
    if (getParams.unread == true) {
      params = params.append('unread', getParams.unread.toString());
    }
    if (getParams.noFlight == true) {
      params = params.append('noFlight', getParams.noFlight.toString());
    }
    if (getParams.direction != null && getParams.direction != -1) {
      params = params.append('direction', getParams.direction);
    }
    if (getParams.start != null) {
      params = params.append('start', getParams.start);
    }
    if (getParams.finish != null) {
      params = params.append('finish', getParams.finish);
    }
    if (getParams.mark != null) {
      params = params.append('mark', getParams.mark);
    }
    return params;
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      errorDetail.status = error.status;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}
