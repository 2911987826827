import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Old version
// import { SlotCoordinationComponent } from "./slot-coordination.component";
// import { WorkflowComponent } from "./workflow.component";
import { MobileApronComponent } from './mobile-apron.component';

// New version
import { CommunicationModuleComponent } from './communication-module/communication-module.component';
import { ScheduleModuleComponent } from './schedule-module/schedule-module.component';
import { ReferanceModuleComponent } from './referance-module/referance-module.component';
import { WorkflowModuleComponent } from './workflow-module/workflow-module.component';
import { SlotCoordinationModuleComponent } from './slot-coordination-module/slot-coordination-module.component';
import { AdministrationModuleComponent } from './administration-module/administration-module.component';
import { TestComponent } from './test/test.component';
import { BoardingControlModuleComponent } from './boarding-control-module/boarding-control-module.component';

// Keycloak auth guard
import { AppAuthGuard } from './app.authguard';
import { ReportsModuleComponent } from './reports-module/reports-module.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ParkingPlacesComponent } from './parking-places/parking-places.component';

const routes: Routes = [
  {
    path: 'slotcoord',
    component: SlotCoordinationModuleComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_slotcoord']
    }
  }, {
    path: 'workflow',
    component: WorkflowModuleComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_workflow']
    }
  }, {
    path: 'schedule',
    component: ScheduleModuleComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_schedule']
    }
  }, {
    path: 'commodule/:homeFolder',
    component: CommunicationModuleComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_commodule']
    }
  }, {
    path: 'mobileapron',
    component: MobileApronComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_mobileapron']
    }
  }, {
    path: 'reports',
    component: ReportsModuleComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_reports']
    }
  }, {
    path: 'masterdata',
    component: ReferanceModuleComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_masterdata']
    }
  }, {
    path: 'boardingcontrol',
    component: BoardingControlModuleComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_boardingcontrol']
    }
  }, {
    path: 'admin',
    component: AdministrationModuleComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_admin']
    }
  }, {
    path: 'parking',
    component: ParkingPlacesComponent,
    canActivate: [AppAuthGuard],
    data: {
      roles: ['aac_parking']
    }
  }, {
    path: 'test',
    component: TestComponent,
    data: {
      roles: ['aac_test']
    }
  }, {
    path: 'notifications',
    component: NotificationsComponent,
    data: {
      roles: ['aac_notific']
    }
  }, {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AppAuthGuard]
})
export class AppRoutingModule { }
